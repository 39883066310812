<template>
  <div :class="['group list-input', { empty: !voipServices.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{
        voipServices.length ? "VOIP SERVICES" : "ADD VOIP SERVICES"
      }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new service'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- VoIP Services -->
    <div v-for="(voipService, i) in voipServices" :key="i" class="list-input-item">
      <b-form-group label="Count">
        <b-form-input v-model="voipService.count" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Minutes">
        <div class="label-toggles">
          <b-form-checkbox
            v-model="voipService.unlimitedMinutes"
            class="label-toggle"
            button
            @change="voipService.minutes = null"
          >
            <font-awesome-icon :icon="['fas', 'infinity']" v-b-tooltip.hover.top="'Unlimited'" />
          </b-form-checkbox>
        </div>
        <b-form-input
          v-model="voipService.minutes"
          :disabled="voipService.unlimitedMinutes"
          type="number" min="0"
          :placeholder="voipService.unlimitedMinutes ? 'unlimited' : ''"
        />
      </b-form-group>

      <b-form-group label="Minutes Int'l">
        <div class="label-toggles">
          <b-form-checkbox
            v-model="voipService.unlimitedMinutesInternational"
            class="label-toggle"
            button
            @change="voipService.minutesInternational = null"
          >
            <font-awesome-icon :icon="['fas', 'infinity']" v-b-tooltip.hover.top="'Unlimited'" />
          </b-form-checkbox>
        </div>
        <b-form-input
          v-model="voipService.minutesInternational"
          :disabled="voipService.unlimitedMinutesInternational"
          type="number" min="0"
          :placeholder="voipService.unlimitedMinutesInternational ? 'unlimited' : ''"
        />
      </b-form-group>

      <b-form-group label="Charge">
        <b-form-input v-model="voipService.charge" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Description">
        <b-form-input v-model="voipService.description" />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(voipService)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != voipServices.length - 1" class="divider" />
    </div>
  </div>
</template>

<script>
export default {
  name: "VoipServicesInput",
  model: {
    prop: "voipServices",
    event: "change"
  },
  props: {
    voipServices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    onChange() {
      this.$emit("change", this.voipServices);
    },

    add(voipService) {
      if (!voipService) {
        voipService = {
          description: null,
          charge: null,
          count: null,
          minutes: null,
          minutesInternational: null,
          unlimitedMinutes: false,
          unlimitedMinutesInternational: false
        };
      } else {
        // Create a new object, we don't want a reference
        voipService = Object.assign({}, voipService);
      }
      this.voipServices.push(voipService);
      this.onChange();
    },

    remove(index) {
      this.$delete(this.voipServices, index);
      this.onChange();
    },

    toggleUnlimited(service, international) {
      if (international) service.minutesInternational = null;
      else service.minutes = null;
    }
  }
};
</script>
