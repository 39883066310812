<template>
  <div :class="['group list-input', { empty: !broadbandServices.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{
        broadbandServices.length ? "BROADBAND SERVICES" : "ADD BROADBAND SERVICES"
      }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new service'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- Broadband Services -->
    <div v-for="(broadbandService, i) in broadbandServices" :key="i" class="list-input-item">
      <b-form-group label="Count">
        <b-form-input v-model="broadbandService.count" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Type">
        <b-form-select v-model="broadbandService.type" :options="broadbandTypeOptions" />
      </b-form-group>

      <b-form-group label="Download Speed">
        <b-form-input v-model="broadbandService.downloadSpeed" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Upload Speed">
        <b-form-input v-model="broadbandService.uploadSpeed" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Charge">
        <b-form-input v-model="broadbandService.charge" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Description">
        <b-form-input v-model="broadbandService.description" />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(broadbandService)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != broadbandServices.length - 1" class="divider" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BroadbandServicesInput",
  model: {
    prop: "broadbandServices",
    event: "change"
  },
  props: {
    broadbandServices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      broadbandTypeOptions: ["ADSL", "FIBRE"]
    };
  },
  methods: {
    onChange() {
      this.$emit("change", this.broadbandServices);
    },

    add(broadbandService) {
      if (!broadbandService) {
        broadbandService = {
          description: null,
          charge: null,
          count: null,
          downloadSpeed: null,
          uploadSpeed: null,
          type: null
        };
      } else {
        // Create a new object, we don't want a reference
        broadbandService = Object.assign({}, broadbandService);
      }
      this.broadbandServices.push(broadbandService);
      this.onChange();
    },

    remove(index) {
      this.$delete(this.broadbandServices, index);
      this.onChange();
    }
  }
};
</script>