import DealFormToolbar from "@/components/savingseditor/dealform/parts/DealFormToolbar.vue";
import Static from "@/components/savingseditor/helpers/statichelper";
import IDHelper from "@/helper/idhelper";
import DateInput from "@/components/savingseditor/dealform/parts/inputs/DateInput.vue";
import ContractTermInput from "@/components/savingseditor/dealform/parts/inputs/calculated/ContractTermInput.vue";

// Load deal form style
require("@/components/savingseditor/styles/dealform.scss");

export const dealFormMixin = {
  components: {
    DealFormToolbar,
    DateInput,
    ContractTermInput
  },
  model: {
    prop: "deal",
    event: "change"
  },
  props: {
    deal: {
      type: Object,
      required: true
    },
    supply: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      CURRENT: false,
      FUTURE: false,
      LEGACY: false,

      showFields: {
        estimated: false,
        projected: false,
        standingCommission: false,
        combinedLineRentalVoip: false
      },

      supplierOptions: [],
      supplyContractIds: {},

      projLabelOptionsEnergy: [
        { value: 0, text: "Renewal" },
        { value: 1, text: "Out of contract" }
      ]
    };
  },
  created() {
    this.loadSupplyContractIds();

    this[this.deal.state] = true;
    this.supplierOptions = Static.supplierOptionsMap[this.deal.type];
  },
  computed: {
    dealFormClass() {
      return [
        "deal-form",
        { unpublished: !this.deal.published, placeholder: this.deal.placeholder }
      ];
    },
    supplierLogo() {
      let logo = null;
      if (this.deal.supplierId) {
        let supplier = this.supplierOptions.find(s => this.deal.supplierId == s.value);
        if (supplier) logo = supplier.logo;
      }
      return logo;
    },
    dealSavings() {
      if (this.deal.state == "FUTURE" && !this.supply.noQuotes && this.deal.saving != undefined) {
        return this.deal.saving.toFixed();
      } else {
        return null;
      }
    }
  },
  methods: {
    loadSupplyContractIds() {
      let contractIds = Object.assign({}, Static.contractIds);

      if (contractIds) {
        this.supplyContractIds = Object.assign({}, contractIds[this.supply.supplyId]);
      }
    },

    assignDraftId() {
      if (!this.deal.draftId) {
        this.$set(this.deal, "draftId", IDHelper.generateUuid());
      }
    },

    assignSupplier(supplierId) {
      this.$set(this.deal, "supplierId", supplierId);
      this.deal.supplierName = this.supplierOptions.find(s => s.value == supplierId).text;
    },

    focusOnSaveBtn(el) {
      el.getElementsByClassName("save-btn")[0].focus();
    },

    // Used when capturing custom change events,
    // which are ignored by form event listener
    set(field, value) {
      if (!this.deal.draftId) this.assignDraftId();
      this.$set(this.deal, field, value);
    }
  }
};
