<template>
  <b-modal v-model="showModal" title="Handset Editor" hide-footer>
    <div id="handsetmodal">
      <p class="py-1 px-2 error-span" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="py-1 px-2 success-span" v-if="successMessage">{{ successMessage }}</p>

      <b-tabs fill>
        <b-tab
          title="New Handset"
          @click="
            mode = 'create';
            resetFields();
          "
          :active="mode == 'create'"
        ></b-tab>
        <b-tab
          title="Edit Handset"
          @click="
            mode = 'edit';
            resetFields();
          "
          :active="mode == 'edit'"
        ></b-tab>
        <b-tab
          title="Number handset"
          class="float-right"
          :active="mode == 'select'"
          @click="
            mode = 'select';
            handset ? handsetSelected(handset) : resetFields();
          "
        ></b-tab>
      </b-tabs>

      <b-card class="w-100" bg-variant="light">
        <!-- SELECTION / EDIT MODE -->
        <div v-if="mode == 'select' || mode == 'edit'">
          <h5 v-if="mode == 'select'" class="d-inline-block">Select</h5>
          <h5 v-else class="d-inline-block">Edit</h5>

          <div class="d-inline-block float-right">
            <b-dropdown
              v-if="handsets"
              :text="selectedHandsetBrand"
              class="mr-2 scrollable-dropdown"
              size="sm"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="(elem, brandName) in handsets"
                :key="brandName"
                @click="
                  resetFields();
                  selectedHandsetBrand = brandName;
                  selectedHandsetModel = 'Select a model';
                "
                >{{ brandName }}</b-dropdown-item
              >
            </b-dropdown>

            <b-dropdown
              v-if="handsets"
              :disabled="selectedHandsetBrand == 'Select a brand'"
              :text="selectedHandsetModel"
              :variant="
                selectedHandsetBrand == 'Select a brand' ? 'outline-secondary' : 'outline-primary'
              "
              class="scrollable-dropdown"
              size="sm"
            >
              <b-dropdown-item
                v-for="handsetElem in handsets[selectedHandsetBrand]"
                :key="handsetElem.model"
                @click="handsetSelected(handsetElem)"
                >{{ handsetElem.model }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>

        <b-input-group prepend="Brand *" class="my-2">
          <b-form-input type="text" v-model="numberHandset.brand"></b-form-input>
        </b-input-group>

        <b-input-group prepend="Model *" class="mb-2">
          <b-form-input type="text" v-model="numberHandset.model"></b-form-input>
        </b-input-group>

        <b-input-group prepend="Image Name" class="mb-2">
          <template v-if="mode == 'select'" #append>
            <b-button variant="outline-secondary" disabled>
              <font-awesome-icon
                :icon="['fas', 'info-circle']"
                v-b-popover.hover.top="
                  'Image is associated to selected brand model. If you want it changed, can be configured directly by development team or by creating a new/editing the existing handset.'
                "
              />
            </b-button>
          </template>
          <b-form-input
            type="text"
            v-model="numberHandset.imageName"
            :disabled="mode == 'select'"
          ></b-form-input>
        </b-input-group>

        <!-- LOCK/UNLOCK FOR SELECT MODE -->
        <b-input-group
          v-if="mode == 'select'"
          class="mb-2"
          :prepend="numberHandset.isLocked ? 'Locked price' : 'Unlocked price'"
        >
          <template #append>
            <b-button variant="outline-primary" @click="toggleLocked()">
              <font-awesome-icon v-if="numberHandset.isLocked" :icon="['fas', 'lock-alt']" />
              <font-awesome-icon v-else :icon="['fas', 'lock-open-alt']" />
            </b-button>
          </template>
          <b-form-input
            type="number" min="0"
            v-if="numberHandset.isLocked"
            v-model="numberHandset.lockedPrice"
          ></b-form-input>
          <b-form-input type="number" min="0" v-else v-model="numberHandset.unlockedPrice"></b-form-input>
        </b-input-group>

        <!-- LOCK/UNLOCK FOR CREATE/EDIT MODE -->
        <div v-else>
          <b-input-group prepend="Locked Price" class="mb-2">
            <b-form-input type="number" min="0" v-model="numberHandset.lockedPrice"></b-form-input>
          </b-input-group>

          <b-input-group prepend="Unlocked Price" class="mb-2">
            <b-form-input type="number" min="0" v-model="numberHandset.unlockedPrice"></b-form-input>
          </b-input-group>
        </div>

        <div class="text-center">
          <b-img
            class="handset-image"
            v-if="numberHandset && numberHandset.s3ImageUrl"
            :src="numberHandset.s3ImageUrl"
          ></b-img>
        </div>
      </b-card>

      <!-- BUTTONS -->
      <div class="mt-2 d-inline-block">
        <b-button variant="outline-secondary" @click="hide()">Close</b-button>
        <b-button
          variant="outline-danger"
          class="ml-2"
          v-if="mode == 'select'"
          @click="
            $emit('save-handset', null);
            resetFields();
            hide();
          "
          >Remove</b-button
        >
      </div>

      <div class="mt-2 float-right">
        <b-button variant="primary" v-if="okBusy">
          <Busy light :size="1.5" :margin="0" />
        </b-button>
        <b-button
          variant="primary"
          v-else-if="mode == 'select'"
          :disabled="!numberHandset.brand || !numberHandset.model"
          @click="$emit('save-handset', numberHandset)"
          >Add handset to number</b-button
        >
        <b-button
          variant="primary"
          v-else
          :disabled="
            (mode == 'edit' && !numberHandset.id) || !numberHandset.brand || !numberHandset.model
          "
          @click="saveHandset()"
          >Save</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#handsetmodal {
  .success-span {
    background-color: mediumspringgreen;
    border-radius: 10px;
    color: white;
  }

  .error-span {
    background-color: lightcoral;
    border-radius: 10px;
    color: white;
  }

  .scrollable-dropdown .dropdown-menu {
    max-height: 180px !important;
    overflow-y: auto !important;
  }

  .handset-image {
    width: 170px;
    height: 200px;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import Static from "@/components/savingseditor/helpers/statichelper";

export default {
  name: "HandsetModal",
  props: {
    handset: Object
  },
  components: { Busy },
  data() {
    return {
      showModal: false,
      mode: "select",
      errorMessage: null,
      successMessage: null,
      okBusy: false,

      handsets: [],
      selectedHandsetBrand: "Select a brand",
      selectedHandsetModel: "Select a model",

      numberHandset: {
        brand: null,
        model: null,
        isLocked: true,
        lockedPrice: null,
        unlockedPrice: null,
        imageName: null,
        s3ImageUrl: null,
        id: null
      }
    };
  },
  created() {
    this.handsets = Static.handsetOptions;
  },
  methods: {
    toggleLocked() {
      if (
        typeof this.numberHandset.isLocked == "undefined" ||
        this.numberHandset.isLocked == null
      ) {
        this.numberHandset.isLocked = false;
      }

      this.numberHandset.isLocked = !this.numberHandset.isLocked;
    },

    handsetSelected(elem) {
      if (typeof elem !== "undefined" && elem && Object.keys(elem).length) {
        this.selectedHandsetBrand = elem.brand;
        this.selectedHandsetModel = elem.model;

        this.numberHandset.model = elem.model;
        this.numberHandset.brand = elem.brand;
        this.numberHandset.imageName = elem.imageName;
        this.numberHandset.unlockedPrice = elem.unlockedPrice;
        this.numberHandset.lockedPrice = elem.lockedPrice;
        this.numberHandset.s3ImageUrl = elem.s3ImageUrl;
        this.numberHandset.id = elem.id;
        this.numberHandset.isLocked =
          typeof elem.isLocked !== "undefined" && elem.isLocked != null ? elem.isLocked : true;
      }
    },

    resetFields() {
      this.selectedHandsetBrand = "Select a brand";
      this.selectedHandsetModel = "Select a model";
      this.numberHandset.brand = null;
      this.numberHandset.model = null;
      this.numberHandset.isLocked = true;
      this.numberHandset.lockedPrice = null;
      this.numberHandset.unlockedPrice = null;
      this.numberHandset.imageName = null;
      this.numberHandset.s3ImageUrl = null;
      this.numberHandset.id = null;
      this.errorMessage = null;
      this.successMessage = null;
    },

    show() {
      this.resetFields();
      this.mode = "select";
      this.handsetSelected(this.handset);
      this.showModal = true;
    },

    hide() {
      this.showModal = false;
    },

    async saveHandset() {
      this.errorMessage = null;
      this.successMessage = null;

      if (this.mode == "create" && this.duplicatedInstance()) {
        this.errorMessage = "Handset brand with this model name already exisits.";
      } else {
        this.okBusy = true;
        try {
          let client = await ApiHelper.http();
          var response = await client.post(`${ApiHelper.endPoint()}handsets`, {
            handset: this.numberHandset
          });
          if (response.data.status === "success") {
            if (this.mode == "create") {
              this.mode = "edit";

              if (!this.handsets[response.data.handset.brand]) {
                this.handsets[response.data.handset.brand] = [];
              }

              this.handsets[response.data.handset.brand].push(
                Object.assign({}, response.data.handset)
              );
            } else {
              if (
                typeof this.handsets[response.data.handset.brand] != "undefined" &&
                this.handsets[response.data.handset.brand]
              ) {
                const elementsIndex = this.handsets[response.data.handset.brand].findIndex(
                  (element) => element.id == response.data.handset.id
                );

                if (elementsIndex > -1) {
                  let handsetArray = [...this.handsets[response.data.handset.brand]];

                  handsetArray[elementsIndex] = Object.assign({}, response.data.handset);
                  this.handsets[response.data.handset.brand] = [...handsetArray];
                }
                this.successMessage = "Handset has been updated successfuly";
              }

              this.successMessage = "Updated successfuly. Brandname has changes, please refresh.";
            }
            this.handsetSelected(response.data.handset);
          } else {
            console.error("Response from Handset API was not a success", response);
            this.errorMessage = "There has been an error updating handsets.";
          }
        } catch (err) {
          console.error("Error loading handsets data", err);
          this.errorMessage = "There has been an error updating handsets.";
        } finally {
          this.okBusy = false;
        }
      }
    },

    duplicatedInstance() {
      if (this.handsets && this.handsets[this.numberHandset.brand]) {
        const elemIndex = this.handsets[this.numberHandset.brand].findIndex(
          (element) => element.model.toLowerCase() == this.numberHandset.model.toLowerCase()
        );
        if (elemIndex > -1) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>
