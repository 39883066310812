<template>
  <div id="savings-editor">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <div v-if="busy" class="text-center">
      <Busy primary :size="3" />
      <p class="mt-4 mb-2 text-primary">Conjuring savings</p>
      <span class="m-0" style="font-size: 30px">🦄</span>
    </div>

    <Navbar
      v-if="!busy"
      :supply-type.sync="supplyType"
      :savings="computedSavings.savings"
      :supplies="supplies"
      :organisation="organisation"
      @preview-savings="previewReport"
    />

    <div v-if="!busy">
      <b-container fluid>
        <h1>Savings Editor</h1>
        <h4 class="pb-2">{{ organisation.name }}</h4>

        <div>
          <!-- Credit score input -->
          <div class="credit-score position-relative">
            <b-input
              v-model.number="organisation.creditScore"
              @change="saveCreditScore"
              number
              min="0"
              max="100"
              size="sm"
              placeholder="Enter credit score"
            />
            <CreditScoreBadge :score="organisation.creditScore" class="position-absolute" />
            <Busy v-if="busyCreditScore" class="position-absolute" :size="1" primary />
          </div>

          <div class="my-3 flex">
            <!-- Create New Supply Button -->
            <b-button @click="createNewSupply()" variant="outline-secondary" class="new-supply-btn">
              <SavingsIcon
                :supplyType="supplyType"
                class="mr-2"
                style="color: inherit"
                disabled
              />Create new supply
            </b-button>

            <!-- No Savings Dropdown -->
            <div>
              <Busy v-if="busyNoSavings" :size="1.5" primary class="d-inline mr-2" />
              <b-dropdown
                id="noSavingBtn"
                dropleft
                text="No Savings"
                variant="outline-secondary"
                class="mr-2"
                :disabled="busyNoSavings"
              >
                <b-dropdown-item
                  v-for="state in states"
                  :key="state.value"
                  @click="noSavings(state.value)"
                >
                  {{ state.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Preview Savings Button -->
              <b-button
                variant="outline-secondary"
                class="generate-report-btn"
                @click="previewReport"
              >
                <font-awesome-icon :icon="['fas', 'unicorn']" class="mr-1" />Preview Savings
              </b-button>
            </div>
          </div>

          <div v-if="supplies">
            <b-list-group v-for="(supply, i) in supplies[supplyType]" :key="supply.supplyId">
              <b-list-group-item @click="toggleSupplyCollapse(supply.supplyId)" href="#">
                <SupplyHeader
                  :supply="supply"
                  :id="supply.supplyId"
                  :index="i"
                  :savings="computedSavings.savings"
                  :type="supplyType"
                  :supplyContractStates="supplyContractStates"
                />
              </b-list-group-item>
              <b-collapse :id="supply.supplyId">
                <b-overlay :show="busySupplies.includes(supply.supplyId)">
                  <SupplyForm
                    :supply="supplies[supplyType][i]"
                    :deals="deals[supply.supplyId]"
                    :draft-id="supplies[supplyType][i].draftId"
                  />
                  <DealList
                    v-if="loadedSupplies.includes(supply.supplyId)"
                    v-model="deals[supply.supplyId]"
                    :ref="'dealList' + supply.supplyId"
                    :supply="supply"
                    :savings="computedSavings.savings.DEALSAVINGS"
                  />
                  <template v-slot:overlay>
                    <MagicSpinner />
                  </template>
                </b-overlay>
              </b-collapse>
            </b-list-group>
          </div>
          <div v-else-if="!busy">
            <p>Please create a new meter</p>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import SavingsIcon from "@/components/SavingsIcon";
import Busy from "@/components/Busy";
import MagicSpinner from "@/components/MagicSpinner";
import Console from "@/console";
import SupplyTypes from "@/helper/supplytypehelper";
import IDHelper from "@/helper/idhelper";
import DealList from "@/components/savingseditor/DealList";
import Savings from "@/helper/savings";
import Addresses from "@/components/savingseditor/helpers/sharedaddresses";
import Static from "@/components/savingseditor/helpers/statichelper";
import SupplyForm from "@/components/savingseditor/supply/SupplyForm";
import Navbar from "@/components/savingseditor/SavingsEditorNavbar";
import SupplyHeader from "@/components/savingseditor/supply/SupplyHeader";
import FormatHelper from "@/helper/formathelper";
import GlobalEvents from "@/helper/globalevents";
import CreditScoreBadge from "@/components/CreditScoreBadge";
import { docusign } from "@/config/reducerproperties";

export default {
  name: "SavingsEditor",
  components: {
    Busy,
    SupplyForm,
    SavingsIcon,
    MagicSpinner,
    DealList,
    Navbar,
    SupplyHeader,
    CreditScoreBadge
  },
  data() {
    return {
      organisation: {},
      supplyType: "ELECTRICITY",
      supplyTypes: [],

      deals: {},
      supplies: {},
      savingsObject: null,

      busy: false,
      loadedSupplies: [],
      busySupplies: [],
      busyNoSavings: false,
      busyCreditScore: false,
      supplyContractStates: {},
      contracts: {},

      states: [
        { value: "noneFoundInsufficientData", text: "Insufficient data" },
        { value: "noneFoundNoMatches", text: "No matches" },
        { value: "noneFound", text: "Other" }
      ]
    };
  },
  computed: {
    computedSavings() {
      if (!this.busy && this.savingsObject) {
        this.savingsObject.updateCategoryDeals(this.supplies, this.deals, true, [this.supplyType]);
      }
      return this.savingsObject;
    },
    title() {
      return "SE 🦄 - " + (this.organisation.name ? this.organisation.name : "Loading...");
    }
  },
  created() {
    this.organisation.organisationId = this.$route.params.organisationId;

    if (this.$route.params.savingProp) {
      this.savingsObject = this.$route.params.savingProp;
      this.supplies = this.savingsObject.supplies;
      this.deals = this.savingsObject.deals;
      this.organisation.name = this.savingsObject.orgName;
      this.supplyTypes = this.savingsObject.supplyTypes;
    } else {
      this.supplyTypes = SupplyTypes.supplyTypes();
      this.savingsObject = new Savings(this.supplyTypes, this);
      this.fetchData();
      this.getDocusignTemplates();
    }

    if (this.$route.params.latestSupply) {
      this.openLatestSupply();
    }
  },
  mounted() {
    this.registerEvt("savingsEditorSaveSupplyDeals", this.saveSupplyDeals);
    this.registerEvt("savingsEditorUndoDeal", this.undoDeal);
    this.registerEvt("savingsEditorDeleteDeals", this.deleteDeals);
    this.registerEvt("savingsEditorDeleteSupply", this.deleteSupply);
    this.registerEvt("savingsEditorUpdateSavings", this.updateSavings);
    this.registerEvt("savingsEditorUpdateContractStatus", this.updateSupplyContractStates);
  },
  methods: {
    updateSupplyContractStates() {
      this.supplyContractStates = Object.assign({}, Static.supplyContractStates);
    },

    async fetchData() {
      this.busy = true;
      // Fetch data, make sure all calls are done before setting it
      try {
        let response = await Promise.all([
          this.getSuppliers(),
          this.getOrganisationSupplies(),
          this.getHandsets(),
          this.getContractIds()
        ]);
        const suppliersResponse = response[0];
        const suppliesResponse = response[1];
        const handsetsResponse = response[2];
        const contractIdsResponse = response[3];

        this.supplies = suppliesResponse.supplies;
        this.deals = suppliesResponse.deals;
        this.organisation = suppliesResponse.organisation;
        this.organisation.name = FormatHelper.orgShortName(this.organisation.name);

        Static.setSupplierOptions(suppliersResponse.suppliers);
        Static.setCustomerOptions(suppliesResponse.organisation.sourceUsers);
        Static.setOrganisation(suppliesResponse.organisation);
        Static.setHandsetOptions(handsetsResponse.handsets);
        Static.setDealIds(await this.extractDealIds());
        Static.setContractIds(contractIdsResponse.contracts);
        this.updateSupplyContractStates();

        Addresses.setAddressesFromSupplies(this.supplies, this.organisation.addresses);
      } catch (err) {
        console.error(err);
      } finally {
        this.updateSavings();
        this.busy = false;
      }
    },

    /* INITIAL API CALLS */

    async getSuppliers() {
      var summarised = true;
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}suppliers?summarised=${summarised}`)
        .then(response => {
          Console.log("Suppliers response :", response);
          return response.data;
        })
        .catch(e => {
          this.showAlert("There was a problem getting supplier data", "warning");
          Console.error(e);
        });
    },

    async getOrganisationSupplies() {
      const client = await ApiHelper.http();
      return client
        .get(
          `${ApiHelper.endPoint()}supplies/${this.organisation.organisationId}?includeDeals=true`
        )
        .then(response => {
          Console.log("Organisation supplies response :", response);
          return response.data;
        })
        .catch(e => {
          this.showAlert("There was a problem getting supply data", "warning");
          Console.error(e);
        });
    },

    async getHandsets() {
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}handsets`)
        .then(response => {
          Console.log("Handsets response :", response);
          return response.data;
        })
        .catch(e => {
          this.showAlert("There was a problem getting mobile handset data", "warning");
          Console.error(e);
        });
    },

    async getDocusignTemplates() {
      if (docusign && docusign.baseUrl) {
        Static.setBusyDocusignTemplates(true);
        const client = await ApiHelper.http();
        return client
          .get(`${ApiHelper.endPoint()}contracts/docusign/templates`)
          .then(response => {
            Static.setDocusignTemplates(response.data.docusignTemplates);
          })
          .catch(e => {
            this.showAlert("There was a problem getting the docusign templates", "warning");
            Console.error(e);
          })
          .finally(() => {
            Static.setBusyDocusignTemplates(false);
          });
      } else {
        console.warn("Docusign is not configured. Not templates were retrieved.");
      }
    },

    async extractDealIds() {
      let dealIds = [];

      if (this.deals && Object.keys(this.deals).length > 0) {
        Object.values(this.deals).forEach(supplyDeals => {
          if (supplyDeals && supplyDeals.length > 0) {
            supplyDeals.forEach(deal => {
              dealIds.push(deal.dealId);
            });
          }
        });
      }

      return dealIds;
    },

    async getContractIds() {
      Static.setBusyContractIds(true);
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}contracts/organisations/${this.organisation.organisationId}`)
        .then(response => {
          this.contracts = response.data.contracts;
          return response.data;
        })
        .catch(e => {
          this.showAlert(
            "There was a problem getting the contracts for this organisation",
            "warning"
          );
          Console.error(e);
        })
        .finally(() => {
          Static.setBusyContractIds(false);
        });
    },

    /* SAVE/DELETE/UNDO API CALLS */
    async saveSupplyDeals({ supplyId }) {
      if (!this.hasBeenEdited(supplyId) || this.busySupplies.includes(supplyId)) return;
      this.supplyBusy(supplyId);

      const client = await ApiHelper.http();
      return client
        .post(`${ApiHelper.endPoint()}supplies/${this.organisation.organisationId}/${supplyId}`, {
          deals: this.getEditedDeals(supplyId),
          supply: this.getEditedSupply(supplyId)
        })
        .then(async response => {
          Console.log("Save supply response: ", response.data);
          this.showAlert("Data saved successfully", "success");

          this.handleSupplyResponse(supplyId, response.data.supply);
          await this.$nextTick(); // wait for supply changes to render
          this.handleDealResponse(supplyId, response.data.deals);
        })
        .catch(e => {
          this.showAlert("There was a problem saving data", "warning");
          Console.error(e);
        })
        .finally(() => {
          this.supplyNotBusy(supplyId);
        });
    },

    async undoDeal({ supplyId, dealId, index }) {
      this.supplyBusy(supplyId);

      const client = await ApiHelper.http();
      client
        .get(
          `${ApiHelper.endPoint()}deals/${
            this.organisation.organisationId
          }/?supplyId=${supplyId}&dealIds=${[dealId]}`
        )
        .then(response => {
          Console.log("Get deals response :", response);
          this.$set(this.deals[supplyId], index, response.data.deals[supplyId][0]);
        })
        .catch(e => {
          this.showAlert("There was a problem fetching deal", "warning");
          Console.error(e);
        })
        .finally(() => this.supplyNotBusy(supplyId));
    },

    async deleteDeals({ supplyId, hideMessage = false }, deals) {
      deals.forEach(async deal => {
        let error = false;

        if (deal.dealId) {
          const client = await ApiHelper.http();
          client
            .delete(
              `${ApiHelper.endPoint()}deals/${deal.organisationId}/${encodeURIComponent(
                deal.sortKey
              )}`
            )
            .catch(e => {
              error = true;
              this.showAlert("There was a problem deleting deal", "warning");
              Console.error(e);
            });
        }

        if (!error) {
          this.deals[supplyId] = this.deals[supplyId].filter(d => d != deal);
        }

        if (
          this.supplyContractStates &&
          Object.keys(this.supplyContractStates).length > 0 &&
          this.supplyContractStates[supplyId] &&
          Object.keys(this.supplyContractStates[supplyId]).length > 0 &&
          this.supplyContractStates[supplyId].dealId == deal.dealId
        ) {
          this.supplyContractStates[supplyId].dealExists = false;
        }
      });

      this.updateSavings();
      Static.setDealIds(await this.extractDealIds());
      Static.setContractIds(this.contracts);

      if (!hideMessage) {
        this.showAlert("Deal(-s) deleted successfully", "success");
      }
    },

    async deleteSupply(supply) {
      this.supplyBusy(supply.supplyId);

      const client = await ApiHelper.http();
      client
        .delete(`${ApiHelper.endPoint()}supplies/${supply.organisationId}/${supply.supplyId}`)
        .then(() => {
          delete this.deals[supply.supplyId];
          this.supplies[supply.type] = this.supplies[supply.type].filter(
            s => s.supplyId != supply.supplyId
          );
          this.showAlert("Supply deleted successfully", "success");
        })
        .catch(e => {
          this.showAlert("There was a problem deleting supply", "warning");
          Console.error(e);
        })
        .finally(() => {
          this.updateSavings();
          this.supplyNotBusy(supply.supplyId);
        });
    },

    async saveCreditScore(creditScore) {
      this.busyCreditScore = true;

      if (!creditScore) creditScore = null;
      this.organisation.creditScore = parseInt(creditScore);

      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}organisations`, {
          organisation: this.organisation
        })
        .then(response => {
          this.organisation = response.data.organisation;
          this.organisation.name = FormatHelper.orgShortName(this.organisation.name);
          this.showAlert("Credit score saved", "success");
        })
        .catch(e => {
          this.showAlert("There was a problem saving credit score", "warning");
          Console.error(e);
        })
        .finally(() => {
          this.busyCreditScore = false;
        });
    },

    /* DATA UTIL FUNCTIONS */

    async createNewSupply() {
      let supply = {
        name: "",
        supplyId: IDHelper.generateUuid(),
        draftId: IDHelper.generateUuid(),
        type: this.supplyType,
        "@type": this.supplyType,
        published: true,
        paysClimateLevy: false,
        noQuotes: null
      };
      this.supplies[supply.type].push(supply);
      await this.$nextTick(); // wait for DOM to render
      this.toggleSupplyCollapse(supply.supplyId);
      this.updateSavings();
    },

    hasBeenEdited(supplyId) {
      return this.getEditedDeals(supplyId).length || this.getEditedSupply(supplyId) != undefined;
    },

    getEditedDeals(supplyId) {
      return this.deals[supplyId].filter(d => d.draftId);
    },

    getBlankDeals(supplyId) {
      return this.deals[supplyId].filter(d => !d.draftId && !d.dealId);
    },

    getEditedSupply(supplyId) {
      return this.supplies[this.supplyType].find(s => s.supplyId == supplyId && s.draftId);
    },

    handleSupplyResponse(supplyId, response) {
      let supplyIndex = this.supplies[this.supplyType].findIndex(s => s.supplyId == supplyId);

      if (response) {
        this.supplies[this.supplyType][supplyIndex] = response;
      } else {
        this.supplies[this.supplyType][supplyIndex].draftId = null;
      }
    },

    async handleDealResponse(supplyId, response) {
      if (response) {
        this.$set(this.deals, supplyId, response.concat(this.getBlankDeals(supplyId)));
      } else {
        this.getEditedDeals(supplyId).forEach(d => this.$set(d, "draftId", null));
      }

      await this.$nextTick(); // wait for DealList changes to render
      this.$refs["dealList" + supplyId][0].sortDeals();

      Static.setDealIds(await this.extractDealIds());
    },

    hasUnsavedData() {
      let unsavedDeals = [].concat
        .apply([], Object.values(this.deals))
        .find(d => d.draftId != null);
      let unsavedSupplies = [].concat
        .apply([], Object.values(this.supplies))
        .find(s => s.draftId != null);
      if (unsavedDeals || unsavedSupplies) {
        return true;
      } else {
        return false;
      }
    },

    /* SUPPLY COLLAPSE/OVERLAY HANDLERS */

    supplyBusy(supplyId) {
      this.busySupplies.push(supplyId);
    },
    supplyNotBusy(supplyId) {
      this.busySupplies = this.busySupplies.filter(x => x != supplyId);
    },
    toggleSupplyCollapse(supplyId) {
      if (supplyId) {
        let i = this.loadedSupplies.findIndex(s => s.supplyId == supplyId);
        if (i == -1) {
          this.loadedSupplies.push(supplyId);
        }
        this.$root.$emit("bv::toggle::collapse", supplyId);
      }
    },
    async openLatestSupply() {
      if (this.$route.params.latestSupply[0]) {
        this.supplyType = this.$route.params.latestSupply[0];
      }
      await this.$nextTick();
      if (this.$route.params.latestSupply[1]) {
        this.toggleSupplyCollapse(this.$route.params.latestSupply[1]);
      }
    },

    /* SAVINGS HANDLERS */

    updateSavings() {
      if (this.savingsObject) {
        this.savingsObject.updateDeals(
          Object.assign({}, this.supplies),
          Object.assign({}, this.deals),
          true
        );
      }
    },

    async noSavings(state) {
      this.busyNoSavings = true;
      try {
        var saveSavingRequest = {};
        saveSavingRequest.state = state;
        saveSavingRequest.totalSavings = this.savingsObject.savings.TOTAL;
        saveSavingRequest.totalCommission = this.savingsObject.savings.COMMISSION;
        saveSavingRequest.switches = this.savingsObject.savings.SWITCHES;
        saveSavingRequest.supplyTypeSavings = this.savingsObject.savings.TYPESAVINGS;
        saveSavingRequest.supplyTypeNoQuotes = this.savingsObject.savings.TYPENOQUOTES;
        saveSavingRequest.supplyTypeCommission = this.savingsObject.savings.TYPECOMMISION;
        saveSavingRequest.supplySavings = this.savingsObject.savings.SUPPLYSAVINGS;
        saveSavingRequest.supplyNoQuotes = this.savingsObject.savings.SUPPLYNOQUOTES;
        saveSavingRequest.supplyCommission = this.savingsObject.savings.SUPPLYCOMMISSION;

        let client = await ApiHelper.http();
        var response = await client.post(
          `${ApiHelper.endPoint()}savings/${this.organisation.organisationId}`,
          { saving: saveSavingRequest, type: "publish" }
        );
        Console.log("Publish report :", response);
        if (response.data.status === "success") {
          this.showAlert("The organisation status has been updated.", "success");
        } else {
          this.showAlert("There was a problem updating the organisation status.", "warning");
        }
      } catch (e) {
        Console.error(e);
        this.showAlert("There was a problem updating the organisation status.", "warning");
      } finally {
        this.busyNoSavings = false;
      }
    },

    async previewReport() {
      this.updateSavings();
      this.savingsObject.orgName = this.organisation.name;
      this.$router.push({
        name: "savings",
        params: {
          savingProp: this.savingsObject,
          demo: false,
          previewOrganisation: this.organisation,
          // Latest supply will be opened when we go back to SE from Savings
          latestSupply: [
            this.supplyType,
            this.loadedSupplies.length ? this.loadedSupplies[this.loadedSupplies.length - 1] : null
          ]
        }
      });
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", {
        message: message,
        variant: variant
      });
    },

    registerEvt(name, callback) {
      GlobalEvents.off(name); // prevent duplicate callbacks
      GlobalEvents.on(name, callback);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedData()) {
      if (to.name == "savings") {
        to.params.hasUnsavedData = true;
        next();
      } else {
        EventBus.$emit("show-unsaved-data-modal", () => next());
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    EventBus.$off();
  }
};
</script>

<style lang="scss">
#savings-editor {
  @import "@/styles/common.scss";
  padding: 0 0.5rem 0 11rem;
  .generate-report-btn:hover {
    background-color: $color-pink-main;
    border-color: $color-pink-main;
  }
  .pink {
    background-color: $color-pink-main;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    span {
      font-size: 2.1rem !important;
    }
    .btn {
      height: min-content;
    }
  }
  .new-supply-btn,
  .credit-score {
    width: 13rem;
  }
  .credit-score {
    .position-absolute {
      &.credit-score-badge {
        right: 2px;
        top: 2px;
      }
      &.busy {
        top: -5px;
        right: -20px;
      }
    }
  }
}
</style>
