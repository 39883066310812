<template>
  <div v-if="deal" class="deal-form-toolbar d-flex justify-content-between pb-2">
    <!-- Menu buttons, checkboxes -->
    <div class="d-flex">
      <!-- Menu buttons -->
      <div class="toolbar-buttons left">
        <!-- Save button -->
        <a
          class="toolbar-item save-btn"
          @click.prevent="evt('savingsEditorSaveSupplyDeals')"
          @keyup.enter.prevent="evt('savingsEditorSaveSupplyDeals')"
          @blur="supplySpecificEvt('savingsEditorFocusOnNextForm')"
          :state="state(deal.draftId)"
          v-b-tooltip.focus.hover.bottom.noninteractive="'Save all'"
          href
          tabindex="-1"
        >
          <font-awesome-icon :icon="['fal', 'save']" />
        </a>

        <!-- Undo button -->
        <div
          class="toolbar-item"
          v-if="deal.dealId"
          @click="undoDeal()"
          v-b-tooltip.hover.bottom.noninteractive="'Undo'"
        >
          <font-awesome-icon :icon="['fal', 'undo']" />
        </div>

        <!-- Copy deal button -->
        <div class="toolbar-item">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
            boundary="viewport"
            lazy
            size="sm"
          >
            <template v-slot:button-content>
              <font-awesome-icon
                :icon="['fal', 'copy']"
                v-b-tooltip.hover.bottom.noninteractive="'Copy'"
              />
            </template>
            <b-dropdown-header>Copy to:</b-dropdown-header>
            <b-dropdown-item
              v-if="deal.state != 'CURRENT'"
              @click="supplySpecificEvt('savingsEditorCopyDeal', 'CURRENT')"
              >Current</b-dropdown-item
            >
            <b-dropdown-item
              v-if="!deal.recommended"
              @click="supplySpecificEvt('savingsEditorCopyDeal', 'RECOMMENDED')"
              >Recommended</b-dropdown-item
            >
            <b-dropdown-item @click="supplySpecificEvt('savingsEditorCopyDeal', 'FUTURE')"
              >Offers</b-dropdown-item
            >
            <b-dropdown-item @click="supplySpecificEvt('savingsEditorCopyDeal', 'LEGACY')"
              >Legacy</b-dropdown-item
            >
          </b-dropdown>
        </div>

        <div class="toolbar-item">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
            boundary="viewport"
            lazy
            size="sm"
          >
            <template v-slot:button-content>
              <font-awesome-icon
                :icon="['fal', 'cut']"
                v-b-tooltip.hover.bottom.noninteractive="'Move'"
              />
            </template>
            <b-dropdown-header>Move to:</b-dropdown-header>
            <b-dropdown-item
              v-if="deal.state != 'CURRENT'"
              @click="supplySpecificEvt('savingsEditorMoveDeal', 'CURRENT')"
              >Current</b-dropdown-item
            >
            <b-dropdown-item
              v-if="!deal.recommended"
              @click="supplySpecificEvt('savingsEditorMoveDeal', 'RECOMMENDED')"
              >Recommended</b-dropdown-item
            >
            <b-dropdown-item @click="supplySpecificEvt('savingsEditorMoveDeal', 'FUTURE')"
              >Offers</b-dropdown-item
            >
            <b-dropdown-item @click="supplySpecificEvt('savingsEditorMoveDeal', 'LEGACY')"
              >Legacy</b-dropdown-item
            >
          </b-dropdown>
        </div>

        <!-- Expiring deal filter -->
        <div
          class="toolbar-item filter-btn"
          :state="state(expiringDealFilter && expiringDealFilter.reason)"
        >
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
            boundary="viewport"
            lazy
          >
            <template #button-content>
              <font-awesome-icon
                :icon="['fal', 'filter']"
                v-b-tooltip.hover.bottom.noninteractive="'Expiring deal filter'"
              />
            </template>
            <b-dropdown-header>Add filter:</b-dropdown-header>
            <div class="px-2">
              <b-form-select
                v-model="expiringDealFilter.reason"
                :options="expiringDealFilterOptions"
                @change="set('expiringDealFilter')"
                class="mb-2"
              />
              <b-form-textarea
                v-model="expiringDealFilter.notes"
                @change="set('expiringDealFilter')"
                placeholder="Comment"
                class="mb-1"
              />
            </div>
          </b-dropdown>
        </div>

        <!-- Recommend deal button -->
        <div
          v-if="deal.state == 'FUTURE'"
          class="toolbar-item recommended-btn"
          @click="toggleRecommended"
          v-b-tooltip.hover.bottom.noninteractive="deal.recommended ? 'Unrecommend' : 'Recommend'"
        >
          <font-awesome-icon :icon="['fal', 'star']" />
        </div>

        <!-- Deal risk button -->
        <div v-if="deal.recommended" class="toolbar-item">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
            boundary="viewport"
            lazy
          >
            <template #button-content>
              <font-awesome-icon
                :class="'risk-icon-' + details.riskLevel"
                :icon="['fal', 'exclamation-circle']"
                v-b-tooltip.hover.bottom.noninteractive="'Risk level'"
              />
            </template>
            <b-dropdown-header>Assign risk level:</b-dropdown-header>
            <div class="px-2">
              <b-form-select
                v-model="details.riskLevel"
                :options="riskLevelOptions"
                @change="set('details')"
                class="mb-2"
              />
              <b-form-textarea
                v-model.lazy="details.riskLevelText"
                @change="set('details')"
                placeholder="Comment"
                class="mb-1"
              />
            </div>
          </b-dropdown>
        </div>

        <!-- Green tariff button -->
        <div
          v-if="deal.state == 'FUTURE' && ['ELECTRICITY', 'GAS', 'WASTE'].includes(deal.type)"
          class="toolbar-item green-tariff-btn"
          @click="toggle('greenTariff')"
          :state="state(deal.greenTariff)"
          v-b-tooltip.hover.bottom="'Green tariff'"
        >
          <font-awesome-icon :icon="['fal', 'seedling']" />
        </div>

        <!-- Show prices buton -->
        <div
          v-if="deal.state == 'CURRENT' && (deal.type == 'WASTE' || deal.type == 'TELECOMS')"
          class="toolbar-item"
          @click="viewPrices(deal.type)"
          v-b-tooltip.hover.bottom="'View Price Sheet'"
        >
          <font-awesome-icon :icon="['far', 'eye']" />
        </div>

        <!-- Recommend cheapest offer -->
        <div
          v-if="deal.state == 'CURRENT'"
          class="toolbar-item"
          @click="supplySpecificEvt('savingsEditorRecommendCheapestOffer')"
          v-b-tooltip.hover.bottom="'Recommend cheapest offer'"
        >
          <font-awesome-icon :icon="['fal', 'surprise']" />
        </div>

        <!-- Recommend highest comms offer -->
        <div
          v-if="deal.state == 'CURRENT'"
          class="toolbar-item"
          @click="supplySpecificEvt('savingsEditorRecommendHighestCommsOffer')"
          v-b-tooltip.hover.bottom="'Recommend highest commission offer'"
        >
          <font-awesome-icon :icon="['fal', 'birthday-cake']" />
        </div>

        <!-- Price offers button -->
        <div
          v-if="
            deal.state == 'CURRENT' &&
              ['TELECOMS', 'WASTE', 'ELECTRICITY', 'GAS'].includes(deal.type)
          "
          class="toolbar-item"
          @click="supplySpecificEvt('savingsEditorPriceOffers')"
          v-b-tooltip.hover.bottom="'Price offers'"
        >
          <font-awesome-icon :icon="['fal', 'tag']" />
        </div>
      </div>

      <!-- Checkboxes - checkboxes that are shows for all supply types are defined here -->
      <div
        class="toolbar-checkboxes d-flex align-items-center pl-2"
        @change="$emit('change', deal)"
      >
        <!-- Future deals -->
        <div v-if="deal.state == 'FUTURE'">
          <!-- Published checkbox -->
          <b-form-checkbox
            v-model="deal.published"
            class="published-checkbox mr-2"
            tabindex="-1"
            @change="evt('savingsEditorUpdateSavings')"
          >
            Published
          </b-form-checkbox>
          <b-form-checkbox
            v-if="deal.type == 'ELECTRICITY' || deal.type == 'GAS'"
            :checked="showFields.standingCommission || deal.standingCommission"
            @change="toggleShowFields('standingCommission', $event)"
            tabindex="-1"
            class="mr-2"
            >Standing Commission</b-form-checkbox
          >
        </div>

        <!-- Current deals -->
        <div v-if="deal.state == 'CURRENT'">
          <!--Out of contract checkbox -->
          <b-form-checkbox v-model="deal.outOfContract" class="mr-2" tabindex="-1"
            >Out Of Contract</b-form-checkbox
          >
          <!-- Projected costs checkbox -->
          <b-form-checkbox
            :checked="showFields.projected"
            @change="toggleShowFields('projected', $event)"
            tabindex="-1"
            class="mr-2"
            >Projected Costs</b-form-checkbox
          >
          <!-- Estimated annual costs checkbox -->
          <b-form-checkbox
            v-if="supply.noQuotes != null"
            :checked="showFields.estimated || deal.estimatedAnnualCost"
            @change="toggleShowFields('estimated', $event)"
            tabindex="-1"
            class="mr-2"
            >Estimated Costs</b-form-checkbox
          >
        </div>

        <!-- Telecoms deals -->
        <div v-if="deal.type == 'TELECOMS'">
          <!-- Combined line rental and VoIP charges checkbox -->
          <b-form-checkbox
            v-model="showFields.combinedLineRentalVoip"
            @change="toggleShowFields('combinedLineRentalVoip', $event)"
            tabindex="-1"
            class="mr-2"
            >Combined Line Rental and VoIP</b-form-checkbox
          >
        </div>
        <div>
          <!-- New deal badge -->
          <b-badge v-if="deal.new" class="mr-2" v-b-tooltip.hover.bottom="'This deal is not saved'"
            >New</b-badge
          >
          <b-badge
            v-if="deal.placeholder"
            v-b-tooltip.hover.bottom="'Auto-generated placeholder deal'"
            variant="primary"
            >Placeholder</b-badge
          >
        </div>
      </div>
    </div>

    <div class="d-flex">
      <!-- Filter reason badge -->
      <b-badge
        v-if="deal.state == 'CURRENT' && deal.expiringDealFilter && deal.expiringDealFilter.reason"
        class="mr-3 align-self-center"
        >{{ formatCoboldCase(deal.expiringDealFilter.reason) }}</b-badge
      >

      <div class="toolbar-buttons right">
        <!-- Generate DS Contract -->
        <div v-if="deal.state == 'FUTURE' && busyFetchingDsTemplates" class="toolbar-item">
          <Busy :size="1" :margin="0" />
        </div>
        <div
          v-else-if="deal.state == 'FUTURE'"
          :class="docusignTemplate ? 'toolbar-item' : 'toolbar-item disabled-toolbar-item'"
          v-b-tooltip.hover.bottom="
            docusignTemplate
              ? 'Generate DocuSign contract'
              : 'There is no Docusign template for this supplier'
          "
          @click="openDocusignForm()"
        >
          <font-awesome-icon :icon="['fal', 'file-signature']" />
        </div>

        <!-- Save Contract -->
        <div
          v-if="deal.state != 'CURRENT'"
          class="toolbar-item"
          :class="deal.dealId ? 'toolbar-item' : 'toolbar-item disabled-toolbar-item'"
          v-b-tooltip.hover.bottom="
            deal.dealId == null
              ? 'Deal needs to be filled and saved'
              : dealContractId
              ? 'Edit contract'
              : 'New contract'
          "
          @click="openContractForm(dealContractId)"
        >
          <font-awesome-icon
            v-if="dealContractId"
            :icon="['fal', 'file-check']"
            class="contract-found"
          />

          <font-awesome-icon v-else :icon="['fal', 'file-medical']" />
        </div>
        <div
          v-else-if="deal.state == 'CURRENT' && deal.dealId && dealContractId"
          class="toolbar-item"
          :class="deal.dealId ? 'toolbar-item' : 'toolbar-item disabled-toolbar-item'"
          v-b-tooltip.hover.bottom="'Edit contract'"
          @click="openContractForm(dealContractId)"
        >
          <font-awesome-icon
            v-if="dealContractId"
            :icon="['fal', 'file-check']"
            class="contract-found"
          />

          <font-awesome-icon v-else :icon="['fal', 'file-medical']" />
        </div>

        <div
          v-if="dealContractId"
          class="toolbar-item"
          :class="toolbar - item"
          v-b-tooltip.hover.bottom="'View contract payments'"
          @click="viewContractPayments(dealContractId)"
        >
          <font-awesome-icon :icon="['fal', 'dollar-sign']" />
        </div>

        <!-- Delete button -->
        <div class="toolbar-item" @click="deleteDeal()" v-b-tooltip.hover.bottom="'Delete deal'">
          <font-awesome-icon :icon="['fal', 'trash-alt']" />
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      v-model="showModal.deleteDeal"
      hide-header
      @ok="evt('savingsEditorDeleteDeals', [deal])"
    >
      Are you sure you want to delete this deal?
    </b-modal>
    <b-modal v-model="showModal.undoDeal" hide-header @ok="evt('savingsEditorUndoDeal')">
      Are you sure you want to undo this deal?
    </b-modal>
    <CreateDocuSignModal
      ref="docuSignModal"
      :deal="deal"
      :supply="supply"
      :templateId="docusignTemplate"
      :contractId="dealContractId"
      @redirectToContractEditor="redirectToContractEditor"
    />
    <ContractEditor
      ref="contractModal"
      :deal="deal"
      :supply="supply"
      :contractId="dealContractId"
      @contractCreated="contractCreated"
      @contractUpdated="contractCreated"
    />
    <b-modal
      v-model="pricingData.showPricing"
      size="lg"
      id="pricing-data"
      :title="pricingData.title"
      ok-only
      ok-title="Close"
    >
      <div>
        <pre>{{ pricingData.pricesToShow }}</pre>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import FormatHelper from "@/helper/formathelper";
import CreateDocuSignModal from "@/components/docusign/CreateDocuSignModal";
import ContractEditor from "@/components/contracts/ContractEditor";
import telecomspricing from "@/components/savingseditor/helpers/telecomspricing.js";
import wastepricing from "@/components/savingseditor/helpers/wastepricing.js";
import GlobalEvents from "@/helper/globalevents";
import Static from "@/components/savingseditor/helpers/statichelper";
import Busy from "@/components/Busy";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";

export default {
  name: "DealFormToolbar",
  components: { CreateDocuSignModal, Busy, ContractEditor },
  model: {
    prop: "deal",
    event: "change"
  },
  props: {
    deal: Object,
    supply: Object,
    showFields: Object,
    supplyContractIds: Object
  },
  data() {
    return {
      showModal: {
        deleteDeal: false,
        undoDeal: false,
        contractEditor: false
      },
      details: {
        riskLevel: 0,
        riskLevelText: null
      },
      riskLevelOptions: [
        { value: 0, text: "No risk level" },
        { value: 1, text: "Green" },
        { value: 2, text: "Yellow" },
        { value: 3, text: "Red" }
      ],
      expiringDealFilter: {
        reason: null,
        notes: null
      },
      expiringDealFilterOptions: [
        { value: null, text: "No filter" },
        { value: "NOT_SHARED", text: "Not shared" },
        { value: "ACCOUNTANT", text: "Accountant" },
        { value: "RESIGNED", text: "Resigned" },
        { value: "NOT_INTERESTED", text: "Not interested" },
        { value: "CANNOT_CONTACT", text: "Cannot contact" }
      ],
      pricingData: {
        showPricing: false,
        pricesToShow: null,
        title: null
      }
    };
  },
  created() {
    // Set filters and details
    if (this.deal.details) {
      this.details = this.deal.details;
    }
    if (this.deal.expiringDealFilter) {
      this.expiringDealFilter = this.deal.expiringDealFilter;
    }
    // Update checkbox states
    if (this.deal.projectedAnnualCost) {
      this.toggleShowFields("projected", true);
    }
    if (this.deal.standingCommission) {
      this.toggleShowFields("standingCommission", true);
    }
    if (this.deal.estimatedAnnualCost) {
      this.toggleShowFields("estimated", true);
    }
    if (this.deal.combinedLineRentalVoip) {
      this.toggleShowFields("combinedLineRentalVoip", true);
    }
  },
  computed: {
    dealContractId() {
      if (this.supplyContractIds) {
        return this.supplyContractIds[this.deal.dealId];
      } else {
        return null;
      }
    },

    docusignTemplate() {
      let dsTemplateName = (this.deal.supplierName + this.supply.type)
        .toLowerCase()
        .replace(/\W/gi, "");
      let dsTemplateObj = Static.docusignTemplates
        ? Static.docusignTemplates[dsTemplateName]
        : null;
      if (typeof dsTemplateObj != "undefined" && dsTemplateObj) {
        return dsTemplateObj;
      } else {
        return null;
      }
    },

    busyFetchingDsTemplates() {
      return Static.busyDocusignTemplates;
    }
  },
  mounted() {
    this.setTabindex();
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase,

    async contractCreated() {
      await this.getContractIds();
      this.$emit("loadSupplyContractIds");
      this.evt("savingsEditorUpdateContractStatus");
    },

    async getContractIds() {
      Static.setBusyContractIds(true);
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}contracts/organisations/${this.supply.organisationId}`)
        .then(response => {
          Static.setContractIds(response.data.contracts);
        })
        .catch(e => {
          this.showAlert(
            "There was a problem getting the contracts for this organisation",
            "warning"
          );
          Console.error(e);
        })
        .finally(() => {
          Static.setBusyContractIds(false);
        });
    },

    async set(field) {
      await this.$nextTick(); // wait for v-model changes to complete
      this.deal[field] = this[field];
      this.$emit("change", this.deal);
    },

    openDocusignForm() {
      if (this.docusignTemplate) {
        this.$refs.docuSignModal.show();
      }
    },

    openContractForm(id) {
      this.$refs.contractModal.show(id);
    },

    viewContractPayments(contractId) {
      const routeData = this.$router.resolve({
        name: "contract-payments",
        params: { contractId }
      });
      window.open(routeData.href, "_blank");
    },

    redirectToContractEditor(editURL) {
      this.$refs.contractModal.show(null, editURL);
    },

    // Local event target is DealList, which we can have multiples of
    // to prevent interference, append supplyId to evt name
    supplySpecificEvt(name, extraParams) {
      const params = {
        dealId: this.deal.dealId,
        draftId: this.deal.draftId,
        supplyId: this.supply.supplyId,
        index: this.deal.index,
        type: this.deal.type,
        mapIndex: this.deal.mapIndex
      };
      EventBus.$emit(GlobalEvents.getEvent(name) + this.supply.supplyId, params, extraParams);
    },

    // Call this directly for events targeted to SavingsEditor
    evt(name, extraParams) {
      const params = {
        dealId: this.deal.dealId,
        draftId: this.deal.draftId,
        supplyId: this.supply.supplyId,
        index: this.deal.index,
        type: this.deal.type,
        mapIndex: this.deal.mapIndex
      };
      GlobalEvents.emit(name, params, extraParams);
    },

    state(condition) {
      return condition ? "active" : "inactive";
    },

    toggle(field) {
      this.$set(this.deal, field, this.deal[field] ? false : true);
      this.$emit("change", this.deal);
    },

    toggleShowFields(field, value) {
      this.$emit("update:toggle-field", Object.assign(this.showFields, { [field]: value }));

      // Clear values if checkbox is unticked
      if (value == false) {
        if (field == "combinedLineRentalVoip" && this.deal.combinedLineRentalVoip) {
          this.deal.combinedLineRentalVoip = null;
          this.$emit("change", this.deal);
        }
        if (field == "standingCommission" && this.deal.standingCommission) {
          this.deal.standingCommission = null;
          this.$emit("change", this.deal);
        }
        if (field == "estimated" && this.deal.estimatedAnnualCost) {
          this.deal.estimatedAnnualCost = null;
          this.$emit("change", this.deal);
        }
        if (
          field == "projected" &&
          (this.deal.projectedAnnualCost ||
            this.deal.projectedDayUnitRate ||
            this.deal.projectedNightUnitRate ||
            this.deal.projectedEwUnitRate ||
            this.deal.projectedWinterUnitRate ||
            this.deal.projectedStandingCharge)
        ) {
          if (this.deal.projectedAnnualCost) this.deal.projectedAnnualCost = null;
          if (this.deal.projectedDayUnitRate) this.deal.projectedDayUnitRate = null;
          if (this.deal.projectedNightUnitRate) this.deal.projectedNightUnitRate = null;
          if (this.deal.projectedEwUnitRate) this.deal.projectedEwUnitRate = null;
          if (this.deal.projectedWinterUnitRate) this.deal.projectedWinterUnitRate = null;
          if (this.deal.projectedStandingCharge) this.deal.projectedStandingCharge = null;
          this.$emit("change", this.deal);
        }
      }
    },

    toggleRecommended() {
      // Unrecommend existing deal first
      if (!this.deal.recommended) {
        this.supplySpecificEvt("savingsEditorUnrecommendDeal");
      }
      this.toggle("recommended");
    },

    deleteDeal() {
      // Don't show confirmation modal for new deals
      if (this.deal.new) {
        this.evt("savingsEditorDeleteDeals", [this.deal]);
      } else {
        this.showModal.deleteDeal = true;
      }
    },

    undoDeal() {
      // Don't show confirmation modal for new deals
      if (this.deal.new) {
        this.evt("savingsEditorUndoDeal");
      } else {
        this.showModal.undoDeal = true;
      }
    },

    // Set tabindex manually on dropdowns since we can't access
    // the dropdown toggle button in the template
    async setTabindex() {
      await this.$nextTick(); // make sure DOM is rendered
      this.$el
        .getElementsByClassName("dropdown-toggle")
        .forEach(el => el.setAttribute("tabindex", "-1"));
    },

    viewPrices(type) {
      if (type == "TELECOMS" && !this.pricingData.showPricing) {
        this.pricingData.pricesToShow = telecomspricing;
        this.pricingData.title = "Telecoms pricing";
      }
      if (type == "WASTE" && !this.pricingData.showPricing) {
        this.pricingData.pricesToShow = wastepricing;
        this.pricingData.title = "Waste pricing";
      }
      this.pricingData.showPricing = !this.pricingData.showPricing;
    }
  }
};
</script>

<style lang="scss">
.deal-form-toolbar {
  @import "@/styles/common";
  $deal-borders-color: $color-grey-lighter2;
  $icon-color: $color-font-headings;
  $toolbar-size: 1rem;

  // Buttons menu
  .toolbar-buttons {
    padding: 0.1rem;
    height: 2rem;
    display: flex;
    border-bottom: 1px solid $deal-borders-color;

    // Border styling for different sides
    &.left {
      border-right: 1px solid $deal-borders-color;
      border-bottom-right-radius: 10px;
    }
    &.right {
      border-left: 1px solid $deal-borders-color;
      border-bottom-left-radius: 10px;
    }

    // Individual toolbar items - buttons
    .toolbar-item {
      padding: 0.2rem;
      border-radius: 4px;
      cursor: pointer;
      margin: 0 3px 0 3px;

      .contract-found {
        color: $color-green;
      }

      &:not(:last-of-type) {
        margin-right: 0.35rem;
      }

      &:hover,
      &:focus {
        background-color: $color-grey-lighter2;
        outline: none;
      }

      &.disabled-toolbar-item {
        cursor: not-allowed;
        background-color: $color-grey-lighter3;
      }

      // Icons
      svg {
        color: $icon-color;
        width: $toolbar-size !important;
        height: $toolbar-size !important;
      }

      // Active state colors, e.g. unsaved deal
      &[state="active"] {
        &.filter-btn svg {
          color: $color-red-light2 !important;
        }
        &.save-btn svg {
          color: $color-red !important;
        }
        &.green-tariff-btn svg {
          color: $color-bright-green-lighter1 !important;
        }
      }

      // Risk level colors
      .risk-icon-1 {
        color: $color-bright-green-lighter1 !important;
      }
      .risk-icon-2 {
        color: $color-red-light3 !important;
      }
      .risk-icon-3 {
        color: $color-red !important;
      }
    }
  }

  .toolbar-checkboxes {
    & > div {
      display: contents; // Apply flex to div contents
    }
  }

  // Style dropdowns
  .dropdown-toggle {
    margin-top: -3px;
  }
  .dropdown-menu {
    min-width: 8rem;
  }
}
</style>
