<template>
  <div :class="['group list-input', { empty: !displayCnpCharges }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{
        displayCnpCharges ? "CNP CHARGES" : "ADD A NEW CNP CHARGE"
      }}</span>

      <b-dropdown
        variant="link"
        class="m-0"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="window"
        v-b-tooltip.hover.right="'Add a new CNP charge'"
        lazy
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'plus']" />
        </template>

        <!-- New charge form -->
        <div class="inline-inputs p-1 align-items-center" @change.stop>
          <b-form-group label="Unit">
            <b-form-select v-model="newCharge.unit" :options="unitOptions" required />
          </b-form-group>

          <b-form-group label="Card Type">
            <b-form-select v-model="newCharge.type" :options="cardTypeOptions" required />
          </b-form-group>

          <b-form-group label="Charge">
            <b-form-input v-model="newCharge.charge" type="number" min="0" />
          </b-form-group>

          <b-button
            variant="light"
            class="list-input-btn mt-3"
            v-b-tooltip.hover.right="'Add'"
            @click="add()"
            :disabled="!newCharge.unit && !newCharge.type"
          >
            <font-awesome-icon :icon="['fas', 'plus']" />
          </b-button>
        </div>
      </b-dropdown>
    </div>

    <!-- CNP Charges -->
    <div class="inline-inputs">
      <b-form-group v-if="deal.cnpCredit" label="CNP Credit">
        <b-input-group prepend="%">
          <b-form-input v-model="deal.cnpCredit" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpDebit" label="CNP Debit">
        <b-input-group prepend="%">
          <b-form-input v-model="deal.cnpDebit" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpCreditFee" label="CNP Credit Fee">
        <b-input-group :prepend="$currencySymbol">
          <b-form-input v-model="deal.cnpCreditFee" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpDebitFee" label="CNP Debit Fee">
        <b-input-group :prepend="$currencySymbol">
          <b-form-input v-model="deal.cnpDebitFee" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpCreditInternational" label="CNP Credit Int'l">
        <b-input-group prepend="%">
          <b-form-input v-model="deal.cnpCreditInternational" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpDebitInternational" label="CNP Debit Int'l">
        <b-input-group prepend="%">
          <b-form-input v-model="deal.cnpDebitInternational" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpCreditInternationalFee" label="CNP Credit Int'l Fee">
        <b-input-group :prepend="$currencySymbol">
          <b-form-input v-model="deal.cnpCreditInternationalFee" type="number" min="0" />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="deal.cnpDebitInternationalFee" label="CNP Debit Int'l Fee">
        <b-input-group :prepend="$currencySymbol">
          <b-form-input v-model="deal.cnpDebitInternationalFee" type="number" min="0" />
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "CnpChargesInput",
  model: {
    prop: "deal",
    value: "change"
  },
  props: {
    deal: Object
  },
  data() {
    return {
      newCharge: {
        unit: null,
        type: null,
        charge: null
      },
      unitOptions: ["%", this.$currencySymbol],
      cardTypeOptions: ["Credit", "Debit", "Credit International", "Debit International"]
    };
  },
  computed: {
    displayCnpCharges() {
      return (
        this.deal.cnpCredit ||
        this.deal.cnpDebit ||
        this.deal.cnpCreditFee ||
        this.deal.cnpDebitFee ||
        this.deal.cnpCreditInternational ||
        this.deal.cnpDebitInternational ||
        this.deal.cnpCreditInternationalFee ||
        this.deal.cnpDebitInternationalFee
      );
    }
  },
  methods: {
    async add() {
      if (this.newCharge.unit != null && this.newCharge.type != null) {
        let cnpChargeName =
          "cnp" +
          this.newCharge.type.replace(" ", "") +
          (this.newCharge.unit == this.$currencySymbol ? "Fee" : "");
        this.$emit("new-charge", cnpChargeName, this.newCharge.charge);
        await this.$nextTick();
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.list-input.empty {
  max-width: 13.5rem;
}
</style>