<template>
  <div class="deal-form-wrapper">
    <div v-if="deal.supplierId || dealSavings" class="deal-form-tabs">
      <div v-if="deal.supplierId" class="deal-form-tab">
        <b-img-lazy v-if="supplierLogo" :src="supplierLogo" />
        <span class="p-1 small" v-else>{{ deal.supplierName }}</span>
      </div>
      <div v-if="dealSavings" class="deal-form-tab savings-tab">
        <b-badge variant="primary"> Savings: {{ $currencySymbol }}{{ dealSavings }} </b-badge>
      </div>
    </div>

    <div :class="dealFormClass" tabindex="0">
      <deal-form-toolbar
        :deal="deal"
        :supply="supply"
        :show-fields.sync="showFields"
        :draft-id="deal.draftId"
        :supplyContractIds="supplyContractIds"
        @change="assignDraftId"
        @loadSupplyContractIds="loadSupplyContractIds"
      />
      <b-form @change="assignDraftId">
        <div class="inline-inputs">
          <b-form-group label="Supplier">
            <b-form-select
              :value="deal.supplierId"
              :options="supplierOptions"
              @change="assignSupplier"
            />
          </b-form-group>

          <b-form-group label="Standing Charge">
            <b-form-input v-model="deal.standingCharge" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Day Rate">
            <b-form-input v-model="deal.dayUnitRate" type="number" min="0" />
          </b-form-group>

          <b-form-group
            v-if="deal.fitsCharge || deal.supplierId == '57839b69-7101-44d3-94e0-e89bcbcf2765'"
            label="FiTs Charge"
          >
            <b-form-input v-model="deal.fitsCharge" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="supply.nightConsumption" label="Night Rate">
            <b-form-input v-model="deal.nightUnitRate" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="supply.ewConsumption" label="EW Rate">
            <b-form-input v-model.number="deal.ewUnitRate" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="supply.winterConsumption" label="Winter Rate">
            <b-form-input v-model.number="deal.winterUnitRate" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Annual Cost">
            <annual-cost-input :deal="deal" :supply="supply" @change="set('annualCost', $event)" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.estimated" label="Estimated Annual Cost">
            <b-form-input v-model="deal.estimatedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea v-model="deal.description" />
          </b-form-group>

          <b-form-group v-if="CURRENT || FUTURE" label="Contract Start">
            <date-input v-model="deal.contractStartDate" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Contract Term">
            <contract-term-input v-model="deal.contractTerm" />
          </b-form-group>

          <b-form-group v-if="CURRENT || LEGACY" label="Contract End">
            <date-input v-model="deal.contractExpiry" />
          </b-form-group>

          <!-- Commission fields -->
          <div v-if="FUTURE" class="group">
            <b-form-group v-if="showFields.standingCommission" label="Standing Commission">
              <b-form-input v-model="deal.standingCommission" type="number" min="0" />
            </b-form-group>

            <b-form-group label="Commission">
              <b-form-input v-model="deal.commission" type="number" min="0" />
            </b-form-group>

            <b-form-group label="Total Commission">
              <total-commission-input
                :deal="deal"
                :supply="supply"
                @change="set('consumptionCommission', $event)"
              />
            </b-form-group>
          </div>

          <!-- Projected fields -->
          <div v-if="CURRENT && showFields.projected" class="break"></div>
          <div v-if="CURRENT && showFields.projected" class="group">
            <b-form-group label="Proj Costs Label">
              <b-form-select v-model="deal.projectedCostsLabel" :options="projLabelOptionsEnergy" />
            </b-form-group>

            <b-form-group label="Proj Unit Rate %">
              <b-form-input v-model.number="deal.projectedUnitRatePercent" type="number" />
            </b-form-group>

            <b-form-group label="Proj Standing Charge">
              <b-form-input v-model="deal.projectedStandingCharge" type="number" min="0" />
            </b-form-group>

            <b-form-group label="Proj Day Rate">
              <projected-unit-rate-input
                v-if="deal.projectedUnitRatePercent && deal.projectedUnitRatePercent != 0"
                v-model="deal.projectedDayUnitRate"
                :deal="deal"
                type="projectedDayUnitRate"
              />
              <b-form-input v-else v-model="deal.projectedDayUnitRate" type="number" min="0" />
            </b-form-group>

            <b-form-group v-if="supply.nightConsumption" label="Proj Night Rate">
              <projected-unit-rate-input
                v-if="deal.projectedUnitRatePercent && deal.projectedUnitRatePercent != 0"
                v-model="deal.projectedNightUnitRate"
                :deal="deal"
                type="projectedNightUnitRate"
              />
              <b-form-input v-else v-model="deal.projectedNightUnitRate" type="number" min="0" />
            </b-form-group>

            <b-form-group v-if="supply.ewConsumption" label="Proj EW Rate">
              <projected-unit-rate-input
                v-if="deal.projectedUnitRatePercent && deal.projectedUnitRatePercent != 0"
                v-model="deal.projectedEwUnitRate"
                :deal="deal"
                type="projectedEwUnitRate"
              />
              <b-form-input v-else v-model="deal.projectedEwUnitRate" type="number" min="0" />
            </b-form-group>

            <b-form-group v-if="supply.winterConsumption" label="Proj Winter Rate">
              <projected-unit-rate-input
                v-if="deal.projectedUnitRatePercent && deal.projectedUnitRatePercent != 0"
                v-model="deal.projectedWinterUnitRate"
                :deal="deal"
                type="projectedWinterUnitRate"
              />
              <b-form-input v-else v-model="deal.projectedWinterUnitRate" type="number" min="0" />
            </b-form-group>

            <b-form-group label="Proj Annual Cost">
              <projected-annual-cost-input
                :deal="deal"
                :supply="supply"
                @change="set('projectedAnnualCost', $event)"
              />
            </b-form-group>
          </div>

          <!-- For smooth tabbing -->
          <a @focus="focusOnSaveBtn($el)" href />
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { dealFormMixin } from "../mixins/dealFormMixin";
import AnnualCostInput from "./parts/inputs/calculated/AnnualCostInput";
import ProjectedAnnualCostInput from "./parts/inputs/calculated/ProjectedAnnualCostInput";
import TotalCommissionInput from "./parts/inputs/calculated/TotalCommissionInput";
import ProjectedUnitRateInput from "./parts/inputs/calculated/energy/ProjectedUnitRateInput";

export default {
  name: "ElectricityDealForm",
  mixins: [dealFormMixin],
  components: {
    TotalCommissionInput,
    AnnualCostInput,
    ProjectedAnnualCostInput,
    ProjectedUnitRateInput
  }
};
</script>
