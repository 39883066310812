<template>
  <div :class="['group list-input', { empty: !oneOffExtras.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{
        oneOffExtras.length ? "ONE-OFF EXTRAS" : "ADD ONE-OFF EXTRAS"
      }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new one-off extra'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- Additional oneOffExtras -->
    <div v-for="(oneOffExtra, i) in oneOffExtraObjects" :key="i" class="list-input-item">
      <b-form-group label="Description">
        <b-form-textarea
          :value="oneOffExtra.description"
          @change="setDescription(oneOffExtra, i, $event)"
        />
      </b-form-group>

      <b-form-group label="Charge">
        <b-form-input
          :value="oneOffExtra.charge"
          type="number" min="0"
          @input="setCharge(oneOffExtra, i, $event)"
        />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(oneOffExtra)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != oneOffExtras.length - 1" class="divider" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OneOffExtrasInput",
  model: {
    prop: "oneOffExtras",
    event: "change"
  },
  props: {
    oneOffExtras: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // oneOffExtraCosts field is an array of key-value pairs
    // where key is description and value is the oneOffExtra
    // we're converting it into an array of objects
    oneOffExtraObjects() {
      return this.oneOffExtras.map((oneOffExtra) => {
        const entry = Object.entries(oneOffExtra)[0];
        return { description: entry[0], charge: entry[1] };
      });
    }
  },
  methods: {
    onChange() {
      this.$emit("change", this.oneOffExtras);
    },

    add(oneOffExtra) {
      if (!oneOffExtra) {
        oneOffExtra = { "": 0 };
      } else {
        oneOffExtra = { [oneOffExtra.description]: oneOffExtra.charge };
      }
      this.oneOffExtras.push(oneOffExtra);
    },

    remove(index) {
      this.$delete(this.oneOffExtras, index);
      this.onChange();
    },

    setCharge(obj, i, value) {
      this.oneOffExtras[i][obj.description] = value;
      this.onChange();
    },

    setDescription(obj, i, value) {
      this.$delete(this.oneOffExtras[i], obj.description);
      this.oneOffExtras[i][value] = obj.charge;
      this.onChange();
    }
  }
};
</script>