<template>
  <div class="deal-form-wrapper">
    <div v-if="deal.supplierId || dealSavings" class="deal-form-tabs">
      <div v-if="deal.supplierId" class="deal-form-tab">
        <b-img-lazy v-if="supplierLogo" :src="supplierLogo" />
        <span class="p-1 small" v-else>{{ deal.supplierName }}</span>
      </div>
      <div v-if="dealSavings" class="deal-form-tab savings-tab">
        <b-badge variant="primary"> Savings: {{ $currencySymbol }}{{ dealSavings }} </b-badge>
      </div>
    </div>

    <div :class="dealFormClass" tabindex="0">
      <deal-form-toolbar
        :deal="deal"
        :supply="supply"
        :show-fields.sync="showFields"
        :draft-id="deal.draftId"
        :supplyContractIds="supplyContractIds"
        @change="assignDraftId"
        @loadSupplyContractIds="loadSupplyContractIds"
      />
      <b-form @change="assignDraftId">
        <div class="inline-inputs">
          <b-form-group label="Supplier">
            <b-form-select
              :value="deal.supplierId"
              :options="supplierOptions"
              @change="assignSupplier"
            />
          </b-form-group>

          <b-form-group label="Annual Cost">
            <b-form-input v-model="deal.annualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.projected" label="Proj Annual Cost">
            <b-form-input v-model="deal.projectedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.estimated" label="Estimated Annual Cost">
            <b-form-input v-model="deal.estimatedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea v-model="deal.description" />
          </b-form-group>

          <b-form-group v-if="CURRENT || FUTURE" label="Contract Start">
            <date-input v-model="deal.contractStartDate" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Contract Term">
            <contract-term-input v-model="deal.contractTerm" />
          </b-form-group>

          <b-form-group v-if="CURRENT || LEGACY" label="Contract End">
            <date-input v-model="deal.contractExpiry" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Total Commission">
            <b-form-input
              v-model="deal.consumptionCommission"
              type="number"
              min="0"
              @change="set('consumptionComission', $event)"
            />
          </b-form-group>

          <div class="group">
            <b-form-group label="MC Personal Credit">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.mcPersonalCredit" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="MC Personal Debit">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.mcPersonalDebit" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="MC Business Credit">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.mcBusinessCredit" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="MC Business Debit">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.mcBusinessDebit" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="MC Premium">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.mcPremiumCredit" type="number" min="0" />
              </b-input-group>
            </b-form-group>
          </div>

          <div class="group">
            <b-form-group label="Debit Personal">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.debitPersonal" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Debit Business">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.debitBusiness" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Debit International">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.debitInternational" type="number" min="0" />
              </b-input-group>
            </b-form-group>
          </div>

          <div class="group">
            <b-form-group label="Credit Personal">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.creditPersonal" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Credit Business">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.creditBusiness" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Credit International">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.creditInternational" type="number" min="0" />
              </b-input-group>
            </b-form-group>
          </div>

          <div class="group">
            <b-form-group label="Inter-Regional">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.interRegional" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Intra-Regional">
              <b-input-group prepend="%">
                <b-form-input v-model="deal.intraRegional" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="International Fee">
              <b-input-group :prepend="$currencySymbol">
                <b-form-input v-model="deal.internationalFee" type="number" min="0" />
              </b-input-group>
            </b-form-group>
          </div>

          <div class="group">
            <b-form-group label="Non-Compliance Fee">
              <b-input-group :prepend="$currencySymbol">
                <b-form-input v-model="deal.nonComplianceFee" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Authorisation Fee">
              <b-input-group prepend="p">
                <b-form-input v-model="deal.authorisationFee" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Management Fee">
              <b-input-group :prepend="$currencySymbol">
                <b-form-input v-model="deal.managementFee" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Min Monthly Charge">
              <b-input-group :prepend="$currencySymbol">
                <b-form-input v-model="deal.minimumMonthlyCharge" type="number" min="0" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="E-commerce Fee">
              <b-input-group :prepend="$currencySymbol">
                <b-form-input v-model="deal.ecommerceFee" type="number" min="0" />
              </b-input-group>
            </b-form-group>
          </div>

          <div class="break"></div>
          <cnp-charges-input v-model="deal" @new-charge="set" />

          <div class="break"></div>
          <additional-costs-input
            :additional-costs="deal.additionalMonthlyCosts"
            @change="set('additionalMonthlyCosts', $event)"
          />

          <div class="break"></div>
          <terminals-input :terminals="deal.terminals" @change="set('terminals', $event)" />

          <!-- For smooth tabbing -->
          <a @focus="focusOnSaveBtn($el)" href />
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { dealFormMixin } from "../mixins/dealFormMixin";
import AdditionalCostsInput from "./parts/inputs/cardpayments/AdditionalCostsInput";
import CnpChargesInput from "./parts/inputs/cardpayments/CnpChargesInput";
import TerminalsInput from "./parts/inputs/cardpayments/TerminalsInput";

export default {
  name: "CardPaymentDealForm",
  mixins: [dealFormMixin],
  components: { TerminalsInput, AdditionalCostsInput, CnpChargesInput }
};
</script>

<style lang="scss" scoped>
.deal-form-wrapper {
  .list-input.empty {
    max-width: 13.5rem !important;
  }
}
</style>