<template>
  <div :id="'dl-' + supply.supplyId" class="deal-list">
    <div v-for="(dealIndexes, state) in dealsMap" :key="state" :state="state" class="deal-category">
      <!-- Header -->
      <div class="deal-state-header">
        <div class="d-flex">
          <!-- Deals state (current, recommended...) -->
          <h5>{{ state == "FUTURE" ? "offers" : state.toLowerCase() }}</h5>

          <!-- Add deal button -->
          <b-button
            v-if="canAddDeal(state)"
            size="sm"
            class="deal-header-btn"
            v-b-tooltip.hover.top="'Add a new deal'"
            @click="
              newDeal(state);
              submitChanges();
            "
          >
            <font-awesome-icon :icon="['fas', 'plus']" />
          </b-button>

          <!-- Sort deals button -->
          <b-button
            v-if="dealIndexes.length > 1 && state == 'FUTURE'"
            size="sm"
            class="deal-header-btn"
            v-b-tooltip.hover.top="
              sortShortestTermFirst ? 'Sort longest term first' : 'Sort shortest term first'
            "
            @click="
              sortShortestTermFirst = !sortShortestTermFirst;
              sortDeals();
            "
          >
            <font-awesome-icon
              v-if="sortShortestTermFirst"
              :icon="['fas', 'sort-numeric-up-alt']"
            />
            <font-awesome-icon v-else :icon="['fas', 'sort-numeric-down-alt']" />
          </b-button>

          <!-- Add placeholder offers -->
          <b-button
            v-if="
              state == 'FUTURE' &&
                futureDeals.length &&
                (supply.type == 'ELECTRICITY' || supply.type == 'GAS') &&
                !deals.some(d => d.placeholder)
            "
            size="sm"
            class="deal-header-btn"
            v-b-tooltip.hover="'Add placeholder offers'"
            @click="showModal.addPlaceholderOffers = true"
          >
            <font-awesome-icon :icon="['far', 'fill-drip']" />
          </b-button>

          <!-- Delete placeholders -->
          <b-button
            v-else-if="
              state == 'FUTURE' &&
                futureDeals.length &&
                (supply.type == 'ELECTRICITY' || supply.type == 'GAS')
            "
            size="sm"
            class="deal-header-btn"
            v-b-tooltip.hover="'Delete placeholders'"
            @click="showModal.deletePlaceholders = true"
          >
            <font-awesome-icon :icon="['far', 'trash-restore-alt']" />
          </b-button>

          <!-- Delete deals buttons -->
          <b-button
            v-if="state == 'RECOMMENDED' && dealsMap.RECOMMENDED.length"
            size="sm"
            class="deal-header-btn"
            v-b-tooltip.hover="'Delete recommended deal and all offers'"
            @click="showModal.deleteOffersRecommended = true"
          >
            <font-awesome-icon :icon="['far', 'trash-alt']" />
          </b-button>
          <b-button
            v-else-if="state == 'FUTURE' && dealsMap.FUTURE.length"
            size="sm"
            class="deal-header-btn"
            v-b-tooltip.hover="'Delete all offers'"
            @click="showModal.deleteOffers = true"
          >
            <font-awesome-icon :icon="['far', 'trash-alt']" />
          </b-button>
        </div>
      </div>

      <!-- Deal Forms -->
      <div v-for="i in dealIndexes" :key="i" class="deal-category-form">
        <ElectricityDealForm
          v-if="supply.type == 'ELECTRICITY'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <GasDealForm
          v-else-if="supply.type == 'GAS'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <WaterDealForm
          v-else-if="supply.type == 'WATER'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <CardPaymentDealForm
          v-else-if="supply.type == 'CARD_PAYMENTS'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <TelecomsDealForm
          v-else-if="supply.type == 'TELECOMS'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <WasteDealForm
          v-else-if="supply.type == 'WASTE'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <FuelDealForm
          v-else-if="supply.type == 'FUEL'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <MobileDealForm
          v-else-if="supply.type == 'MOBILE'"
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
        <CustomDealForm
          v-else
          v-model="deals[i]"
          :class="'deal-form-' + deals[i].mapIndex"
          :supply="supply"
          @change.native="submitChanges"
        />
      </div>
    </div>
    <b-modal v-model="showModal.deleteOffers" @ok="deleteOffers()" hide-header>
      Are you sure you want to delete all offers for this supply? Recommended deal will not be
      deleted.
    </b-modal>
    <b-modal v-model="showModal.deleteOffersRecommended" @ok="deleteOffers(true)" hide-header>
      Are you sure you want to delete all offers, including recommended, for this supply?
    </b-modal>
    <b-modal v-model="showModal.addPlaceholderOffers" @ok="addPlaceholderOffers" hide-header>
      Are you sure you want to add placeholder offers to this supply?
    </b-modal>
    <b-modal v-model="showModal.deletePlaceholders" hide-header @ok="deletePlaceholders()">
      Are you sure you want to delete all placeholder deals?
    </b-modal>
    <TelecomsPricingModal
      v-if="showModal.telecomsPricing"
      :show.sync="showModal.telecomsPricing"
      :deal="deals[dealsMap.CURRENT[0]]"
      @submit-offers="priceOffers"
    />
    <EnergyPricingModal
      v-if="showModal.energyPricing"
      :show.sync="showModal.energyPricing"
      :deal="deals[dealsMap.CURRENT[0]]"
      :supply="supply"
      :deals-count="deals.length - 1"
      @submit-offers="priceOffers"
    />
  </div>
</template>

<script>
import CustomDealForm from "./dealform/CustomDealForm";
import WaterDealForm from "./dealform/WaterDealForm";
import FuelDealForm from "./dealform/FuelDealForm";
import GasDealForm from "./dealform/GasDealForm";
import ElectricityDealForm from "./dealform/ElectricityDealForm";
import WasteDealForm from "./dealform/WasteDealForm";
import CardPaymentDealForm from "./dealform/CardPaymentDealForm";
import TelecomsDealForm from "./dealform/TelecomsDealForm";
import MobileDealForm from "./dealform/MobileDealForm";
import { EventBus } from "@/components/eventbus";
import IDHelper from "@/helper/idhelper";
import PricingHelper from "./helpers/pricinghelper";
import CalculationHelper from "./helpers/calculationhelper";
import TelecomsPricingModal from "./modals/TelecomsPricingModal";
import GlobalEvents from "@/helper/globalevents";
import EnergyPricingModal from "./modals/EnergyPricingModal";
import Static from "@/components/savingseditor/helpers/statichelper";

export default {
  name: "DealList",
  components: {
    CustomDealForm,
    WaterDealForm,
    FuelDealForm,
    GasDealForm,
    ElectricityDealForm,
    WasteDealForm,
    CardPaymentDealForm,
    TelecomsDealForm,
    MobileDealForm,
    TelecomsPricingModal,
    EnergyPricingModal
  },
  model: {
    prop: "deals",
    event: "change"
  },
  props: {
    deals: {
      type: Array,
      required: false,
      default: () => []
    },
    supply: {
      type: Object,
      required: true
    },
    savings: Object
  },
  data() {
    return {
      topSuppliers: {
        ELECTRICITY: [
          "British Gas",
          "E.ON",
          "EDF",
          "SSE",
          "Opus",
          "Scottish Power",
          "British Gas Lite",
          "Ecotricity"
        ],
        GAS: [
          "British Gas",
          "Gazprom",
          "Opus",
          "E.ON",
          "SSE",
          "EDF",
          "Total",
          "Scottish Power",
          "Ecotricity"
        ]
      },
      sortShortestTermFirst: true,
      showModal: {
        deleteOffers: false,
        deleteOffersRecommended: false,
        telecomsPricing: false,
        energyPricing: false,
        addPlaceholderOffers: false,
        deletePlaceholders: false
      }
    };
  },
  created() {
    this.createNewDeals();
  },
  mounted() {
    this.sortDeals();

    this.registerEvt("savingsEditorUnrecommendDeal", () => this.unrecommendDeal());
    this.registerEvt("savingsEditorCopyDeal", (params, toState) => this.copyDeal(params, toState));
    this.registerEvt("savingsEditorMoveDeal", (params, toState) => this.moveDeal(params, toState));
    this.registerEvt("savingsEditorRecommendCheapestOffer", () => this.recommendCheapestOffer());
    this.registerEvt("savingsEditorRecommendHighestCommsOffer", () =>
      this.recommendHighestCommsOffer()
    );
    this.registerEvt("savingsEditorFocusOnNextForm", params =>
      this.focusOnForm(params.mapIndex + 1)
    );
    this.registerEvt("savingsEditorPriceOffers", () => this.priceOffers());
  },
  computed: {
    futureDeals() {
      return this.dealsMap.FUTURE.concat(this.dealsMap.RECOMMENDED);
    },
    dealsMap() {
      let map = {
        CURRENT: [],
        RECOMMENDED: [],
        FUTURE: [],
        LEGACY: []
      };
      if (this.deals) {
        this.deals.forEach((d, i) => {
          // Set index in deals list. Useful when deleting/setting deal in array
          d.index = i;
          if (d.recommended) map.RECOMMENDED.push(i);
          else map[d.state].push(i);
        });

        // Set index in deals map. Useful when navigating to next form
        Object.values(map)
          .flat()
          .map((dealIndex, mapIndex) => (this.deals[dealIndex].mapIndex = mapIndex));
      }
      return map;
    }
  },
  methods: {
    dealState: state => (state == "RECOMMENDED" ? "FUTURE" : state),

    submitChanges() {
      this.$emit("change", this.deals);
    },

    createNewDeals() {
      if (!this.supply.createdDate && !this.deals.length) {
        this.newDeal("CURRENT");
        if (this.supply.type == "ELECTRICITY" || this.supply.type == "GAS") {
          this.newDeal("RECOMMENDED", { contractTerm: 12 });
          this.newDeal("FUTURE", { contractTerm: 60 });
          this.newDeal("FUTURE", { contractTerm: 60 });
          this.newDeal("FUTURE", { contractTerm: 48 });
          this.newDeal("FUTURE", { contractTerm: 48 });
          this.newDeal("FUTURE", { contractTerm: 36 });
          this.newDeal("FUTURE", { contractTerm: 36 });
          this.newDeal("FUTURE", { contractTerm: 24 });
          this.newDeal("FUTURE", { contractTerm: 24 });
          this.newDeal("FUTURE", { contractTerm: 12 });

          if (this.supply.type == "ELECTRICITY") {
            this.newDeal("FUTURE", { contractTerm: 12 });
          }
        } else {
          this.newDeal("RECOMMENDED");
        }
        this.submitChanges();
      }
    },

    newDeal(state, props, append) {
      let deal = {
        state: this.dealState(state),
        type: this.supply.type,
        "@type": this.supply.type,
        supplyId: this.supply.supplyId,
        published: true,
        new: true,
        recommended: state == "RECOMMENDED"
      };
      if (props) {
        Object.entries(props).forEach(([key, value]) => (deal[key] = value));
      }
      if (append) {
        this.deals.push(deal);
      } else {
        this.deals.unshift(deal);
      }
    },

    canAddDeal(state) {
      // Only one deal allowed
      if (state == "CURRENT" || state == "RECOMMENDED") {
        return this.dealsMap[state].length < 1;
      } else return true;
    },

    unrecommendDeal() {
      if (this.dealsMap.RECOMMENDED.length) {
        this.deals[this.dealsMap.RECOMMENDED[0]].draftId = IDHelper.generateUuid();
        this.deals[this.dealsMap.RECOMMENDED[0]].recommended = false;
        this.submitChanges();
      }
    },

    async copyDeal({ index }, toState) {
      // Check for conflicts
      if (toState == "CURRENT" && this.dealsMap.CURRENT.length) {
        this.showAlert("A current deal already exists.", "primary", "Woah there partner!");
      } else if (toState == "RECOMMENDED" && this.dealsMap.RECOMMENDED.length) {
        this.showAlert("A recommended deal already exists.", "primary", "Woah there partner!");
      } else {
        // Deep copy for nested objects
        let copiedDeal = JSON.parse(JSON.stringify(this.deals[index]));
        copiedDeal.draftId = IDHelper.generateUuid();
        copiedDeal.dealId = null;
        copiedDeal.state = this.dealState(toState);
        copiedDeal.recommended = toState == "RECOMMENDED";

        this.deals.unshift(copiedDeal);

        // Wait for data to render, then scroll to the new copied form
        await this.$nextTick();
        this.focusOnForm(0);
      }
    },

    async moveDeal({ index }, toState) {
      // Check for conflicts
      if (toState == "CURRENT" && this.dealsMap.CURRENT.length) {
        this.showAlert("A current deal already exists.", "primary", "Woah there partner!");
      } else if (toState == "RECOMMENDED" && this.dealsMap.RECOMMENDED.length) {
        this.showAlert("A recommended deal already exists.", "primary", "Woah there partner!");
      } else {
        this.deals[index].recommended = toState == "RECOMMENDED";
        this.$set(this.deals[index], "draftId", IDHelper.generateUuid());
        this.$set(this.deals[index], "state", this.dealState(toState));

        await this.$nextTick();
        this.focusOnForm(0);
      }
    },

    priceOffers(offers) {
      if (this.supply.type == "TELECOMS" && !offers) {
        this.showModal.telecomsPricing = true;
        return;
      } else if ((this.supply.type == "ELECTRICITY" || this.supply.type == "GAS") && !offers) {
        this.showModal.energyPricing = true;
        return;
      }
      if (!offers) {
        offers = PricingHelper.price(this.deals[this.dealsMap.CURRENT[0]], this.supply);
      }
      if (offers && offers.length) {
        offers.forEach(o => {
          o.draftId = IDHelper.generateUuid();
          o.dealId = null;
          o.state = "FUTURE";
          o.annualCost = CalculationHelper.calculateAnnualCost(o, this.supply);
          if (!o.consumptionCommission)
            o.consumptionCommission = CalculationHelper.calculateCommission(o, this.supply);
        });

        this.deals.unshift(...offers);
        this.recommendHighestCommsOffer();
        this.removeBlankOffers();
        this.showAlert("Created " + offers.length + " new offers", "success");
      }
    },

    recommendCheapestOffer() {
      if (this.dealsMap.FUTURE.length) {
        let dealsToSearch = this.futureDeals;

        // Telecoms: recommend offer with matching broadband type
        if (this.supply.type == "TELECOMS") {
          let currentBroadbandType = null;
          try {
            currentBroadbandType = this.deals[this.dealsMap.CURRENT[0]].broadbandServices[0].type;
          } catch {
            console.debug("No broadband type found for current deal.");
          }

          if (currentBroadbandType) {
            let matchingBroadbandTypeDeals = dealsToSearch.filter(i => {
              if (this.deals[i].broadbandServices && this.deals[i].broadbandServices.length) {
                return this.deals[i].broadbandServices[0].type == currentBroadbandType;
              } else return false;
            });

            if (matchingBroadbandTypeDeals.length) {
              dealsToSearch = matchingBroadbandTypeDeals;
            }
          }
        }
        let i = this.findCheapestOffer();

        if (!this.deals[i].recommended) {
          this.unrecommendDeal();
          this.deals[i].draftId = IDHelper.generateUuid();
          this.$set(this.deals[i], "recommended", true);
          this.submitChanges();
        }
      }
    },

    recommendHighestCommsOffer() {
      if (this.dealsMap.FUTURE.length) {
        const valueOrMax = val => (val ? val : Number.MIN_VALUE);
        let i = this.futureDeals.reduce((i1, i2) =>
          parseFloat(valueOrMax(this.deals[i1].consumptionCommission)) >
          parseFloat(valueOrMax(this.deals[i2].consumptionCommission))
            ? i1
            : i2
        );

        if (!this.deals[i].recommended) {
          this.unrecommendDeal();
          this.deals[i].draftId = IDHelper.generateUuid();
          this.$set(this.deals[i], "recommended", true);
          this.submitChanges();
        }
      }
    },

    removeBlankOffers() {
      this.deleteOffers(
        false,
        this.deals.filter(d => d.state == "FUTURE" && !d.dealId && !d.annualCost && !d.supplierId),
        true
      );
    },

    deletePlaceholders() {
      this.deleteOffers(
        false,
        this.deals.filter(d => d.placeholder),
        true
      );
    },

    deleteOffers(deleteRecommended, dealsToDelete, hideMessage = false) {
      if (!dealsToDelete) {
        dealsToDelete = this.dealsMap.FUTURE.map(i => this.deals[i]);
      }
      if (deleteRecommended) dealsToDelete.push(this.deals[this.dealsMap.RECOMMENDED[0]]);

      GlobalEvents.emit(
        "savingsEditorDeleteDeals",
        { supplyId: this.supply.supplyId, hideMessage: hideMessage },
        dealsToDelete
      );
    },

    addPlaceholderOffers() {
      if (this.topSuppliers[this.supply.type] && this.futureDeals.length) {
        // Generate existing offer keys for easy lookup
        const existingOfferKeys = this.futureDeals.map(
          i => this.deals[i].supplierName + this.deals[i].contractTerm
        );
        const currentSupplier = this.deals[this.dealsMap.CURRENT].supplierName;

        // Get offers with highest annual cost for each contract term
        let priciestDeals = {};
        new Set(this.futureDeals.map(i => this.deals[i].contractTerm)).forEach(t => {
          if (t) {
            let deal = this.deals[this.findPriciestOffer(o => o.contractTerm == t && o.supplierId)];
            if (deal) priciestDeals[t] = deal;
          }
        });

        // Loop through each supplier, get a random multiplier (1.1-1.2)
        this.topSuppliers[this.supply.type].forEach(supplier => {
          const multiplier = Math.random() / 10 + 1.1;

          // For each term, check if supplier-term deal exists, if not, add placeholder deal
          Object.keys(priciestDeals).forEach(term => {
            if (!existingOfferKeys.includes(supplier + term) && supplier != currentSupplier) {
              const multipliedRate = r =>
                !isNaN(priciestDeals[term][r])
                  ? parseFloat((priciestDeals[term][r] * multiplier).toFixed(2))
                  : null;

              this.newDeal(
                "FUTURE",
                {
                  supplierName: supplier,
                  contractTerm: parseInt(term),
                  supplierId: this.findSupplierId(supplier),
                  placeholder: true,
                  dayUnitRate: multipliedRate("dayUnitRate"),
                  nightUnitRate: multipliedRate("nightUnitRate"),
                  ewUnitRate: multipliedRate("ewUnitRate"),
                  standingCharge: multipliedRate("standingCharge")
                    ? parseFloat(multipliedRate("standingCharge").toFixed(0))
                    : null
                },
                true
              );
            }
          });
        });
      }
    },

    sortDeals() {
      const compareFunc = (d1, d2) => {
        if (d1.placeholder && !d2.placeholder) {
          return 1;
        }
        if (!d1.placeholder && d2.placeholder) {
          return -1;
        }
        if (d1.contractTerm > d2.contractTerm) {
          return this.sortShortestTermFirst ? 1 : -1;
        }
        if (d1.contractTerm < d2.contractTerm) {
          return this.sortShortestTermFirst ? -1 : 1;
        }
      };
      this.deals.sort(compareFunc);
      this.submitChanges();
    },

    // Focus on form with mapIndex
    focusOnForm(index) {
      const form = document.querySelector(
        `#dl-${this.supply.supplyId} .deal-form-${index} .custom-select`
      );
      if (form) {
        form.focus();
      }
    },

    findSupplierId(supplierName) {
      let supplier = Static.supplierOptionsMap[this.supply.type].find(s => s.text == supplierName);
      if (supplier) return supplier.value;
      else return null;
    },

    // Find index of offer with lowest annual cost
    findCheapestOffer() {
      const valueOrMax = val => (val ? val : Number.MAX_VALUE);
      return this.futureDeals.reduce((i1, i2) =>
        valueOrMax(this.deals[i1].annualCost) < valueOrMax(this.deals[i2].annualCost) ? i1 : i2
      );
    },

    // Find index of offer with highest annual cost
    findPriciestOffer(dealFilter) {
      const valueOrMin = val => (val ? val : 0);
      let deals = this.futureDeals;

      if (dealFilter) {
        deals = deals.filter(d => dealFilter(this.deals[d]));
        if (!deals.length) return null;
      }
      return deals.reduce((i1, i2) =>
        valueOrMin(this.deals[i1].annualCost) > valueOrMin(this.deals[i2].annualCost) ? i1 : i2
      );
    },

    registerEvt(name, callback) {
      name = GlobalEvents.getEvent(name) + this.supply.supplyId;
      EventBus.$off(name); // prevent duplicate listeners
      EventBus.$on(name, (params, extraParams) => callback(params, extraParams));
    },

    showAlert(message, variant, title) {
      EventBus.$emit("show-toast", {
        message: message,
        variant: variant,
        title: title
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.deal-list {
  max-width: max-content;
  .deal-category {
    background-color: $color-grey-lighter3;
    border-radius: 6px;
    margin-bottom: 2rem;
    padding: 0.5rem;
    // Add negative margin to style deal headers
    .deal-category-form:nth-child(2) .deal-form-tabs {
      margin-top: -2rem;
    }

    // Headers above deals with buttons
    .deal-state-header {
      display: flex;
      align-items: center;
      padding: 0.4rem;
      border-radius: 6px;
      width: max-content;
      h5 {
        color: white !important;
        text-transform: capitalize;
        margin-bottom: 0;
      }
      .deal-header-btn {
        color: white !important;
        height: 1.5rem;
        width: 1.5rem;
        padding: 0;
        margin-left: 0.65rem;
        border: none;
      }
    }

    // Delete buttons
    .delete-deals-btn {
      background-color: white;
      position: absolute;
      right: 0;
      top: -2.75rem;
      border-color: $color-red-light1;
      color: $color-red-light1;
      &:hover,
      &:focus {
        color: white;
        background: $color-red-light1;
      }
    }

    .deal-form-wrapper {
      margin-top: 0.75rem;
    }

    // Different deal state header colors
    &[state="CURRENT"] {
      .deal-state-header {
        background-color: $color-blue-lighter2;
      }
      .deal-header-btn {
        background-color: $color-blue-darker2;
        color: $color-blue-darker2;
        &:hover {
          background-color: $color-blue-darker3;
        }
      }
    }
    &[state="RECOMMENDED"] {
      .deal-state-header {
        background-color: $color-red-light1;
      }
      .deal-header-btn {
        background-color: $color-red-dark2;
        &:hover {
          background-color: $color-red-dark3 !important;
        }
      }
    }
    &[state="FUTURE"] {
      .deal-state-header {
        background-color: $color-bright-green;
      }
      .deal-header-btn {
        background-color: $color-green;
        &:hover {
          background-color: $color-green-darker;
        }
      }
    }
    &[state="LEGACY"] {
      .deal-state-header {
        background-color: $color-grey-lighter1;
      }
      .deal-header-btn {
        background-color: $color-grey;
        &:hover {
          background-color: $color-font-para;
        }
      }
    }
  }
}
</style>
