<template>
  <div id="ds-supply-fields" class="ds-section">
    <div cols="12" id="section-header">
      <p class="m-0">SUPPLY SECTION</p>
    </div>

    <span>{{ errorMessage }}</span>

    <b-row id="section-body">
      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <b-form-group
          :label="!showDsLabels ? 'Supply Name' : 'supply_name'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_name')"
        >
          <b-form-input
            v-model="dsSupply.name"
            :class="highlight('supply_name', dsSupply.name)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Product Name' : 'supply_product_name'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_product_name')"
        >
          <b-form-input
            :class="highlight('supply_product_name', dsSupply.productName)"
            v-model="dsSupply.productName"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Quote Ref' : 'supply_quote_ref'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_quote_ref')"
        >
          <b-form-input
            :class="highlight('supply_quote_ref', dsSupply.quoteRef)"
            v-model="dsSupply.quoteRef"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Quote Validity' : 'supply_quote_validity'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_quote_validity')"
        >
          <b-form-input
            :class="highlight('supply_quote_validity', dsSupply.quoteValidity)"
            type="number"
            v-model="dsSupply.quoteValidity"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Meter No.' : 'supply_meter_number'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_meter_number')"
        >
          <b-form-input
            :class="highlight('supply_meter_number', dsSupply.meterNumber)"
            v-model="dsSupply.meterNumber"
            :state="meterNumberState"
          ></b-form-input>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_meter_number')"
          type="meterNumber"
          :labels="{
            supply_meter_number: dsSupply.meterNumber
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Meter Serial No.' : 'supply_meter_serial'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_meter_serial')"
        >
          <b-form-input
            :class="highlight('supply_meter_serial', dsSupply.meterSerial)"
            v-model="dsSupply.meterSerial"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Meter Type' : 'supply_meter_type'"
          label-cols="12"
          label-cols-sm="3"
          v-if="show('supply_meter_type')"
        >
          <b-input-group>
            <template #append>
              <b-button class="checkbox-append" v-if="show('supply_is_AMR_meter')"
                ><b-form-checkbox
                  switch
                  v-model="dsSupply.isAMRMeter"
                  :class="highlight('supply_is_AMR_meter')"
                ></b-form-checkbox>
                AMR meter
              </b-button>
            </template>

            <ds-input-tooltip text="AMR stands for Automated Meter Reading device" icon="info" />

            <b-form-input
              :class="highlight('supply_meter_type', dsSupply.meterType)"
              v-model="dsSupply.meterType"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_is_AMR_meter')"
          type="checkbox"
          :labels="{
            supply_is_AMR_meter: dsSupply.isAMRMeter
          }"
        />

        <div
          class="subsection-card my-2"
          v-if="
            showGroup([
              'supply_address',
              'supply_address_line1',
              'supply_address_line2',
              'supply_address_postcode',
              'supply_address_city'
            ])
          "
        >
          <div class="header">
            <p>ADDRESS</p>
            <b-form-select
              class="float-sm-right w-auto mb-3 mb-sm-0"
              :disabled="!addresses.length > 0"
              :options="addresses"
              variant="outline-secondary"
              v-model="selectedAddress"
              @input="dsSupply.address = Object.assign({}, selectedAddress)"
            />
          </div>
          <b-form-group
            :label="!showDsLabels ? 'Line 1' : 'supply_address_line1'"
            label-cols="12"
            label-cols-sm="4"
          >
            <b-form-input
              @change="$emit('supply-address-changed', dsSupply.address)"
              :class="
                highlight(['supply_address', 'supply_address_line1'], dsSupply.address.address1)
              "
              v-model="dsSupply.address.address1"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Line 2' : 'supply_address_line2'"
            label-cols="12"
            label-cols-sm="4"
          >
            <b-form-input
              @change="$emit('supply-address-changed', dsSupply.address)"
              :class="
                highlight(['supply_address', 'supply_address_line2'], dsSupply.address.address2)
              "
              v-model="dsSupply.address.address2"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'City' : 'supply_address_city'"
            label-cols="12"
            label-cols-sm="4"
          >
            <b-form-input
              @change="$emit('supply-address-changed', dsSupply.address)"
              :class="highlight(['supply_address', 'supply_address_city'], dsSupply.address.city)"
              v-model="dsSupply.address.city"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Postcode' : 'supply_address_postcode'"
            label-cols="12"
            label-cols-sm="4"
          >
            <b-form-input
              @change="$emit('supply-address-changed', dsSupply.address)"
              :class="
                highlight(['supply_address', 'supply_address_postcode'], dsSupply.address.postcode)
              "
              v-model="dsSupply.address.postcode"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="showDsLabels && show('')"
            label="supply_address"
            label-cols="12"
            label-cols-sm="4"
          >
            <p class="m-0 mt-2">
              {{ dsSupply.address.address1 }} {{ dsSupply.address.address2 }}
              {{ dsSupply.address.city }}
              {{ dsSupply.address.postcode }}
            </p>
          </b-form-group>
        </div>
      </b-col>

      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <b-form-group
          :label="!showDsLabels ? 'Day cons.' : 'supply_day_consumption'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_day_consumption')"
        >
          <b-input-group append="kWh/year">
            <b-input
              :class="highlight('supply_day_consumption', dsSupply.dayConsumption)"
              type="number"
              v-model="dsSupply.dayConsumption"
              @change="changeConsumptionData"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_day_consumption')"
          type="decimal"
          :labels="{
            supply_day_consumption: dsSupply.dayConsumption
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Night cons.' : 'supply_night_consumption'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_night_consumption')"
        >
          <b-input-group append="kWh/year">
            <b-input
              :class="highlight('supply_night_consumption', dsSupply.nightConsumption)"
              type="number"
              v-model="dsSupply.nightConsumption"
              @change="changeConsumptionData"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_night_consumption')"
          type="decimal"
          :labels="{
            supply_night_consumption: dsSupply.nightConsumption
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'EW cons.' : 'supply_ew_consumption'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_ew_consumption')"
        >
          <b-input-group append="kWh/year">
            <b-input
              :class="highlight('supply_ew_consumption', dsSupply.ewConsumption)"
              type="number"
              v-model="dsSupply.ewConsumption"
              @change="changeConsumptionData"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_ew_consumption')"
          type="decimal"
          :labels="{
            supply_ew_consumption: dsSupply.ewConsumption
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Winter cons.' : 'supply_winter_consumption'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_winter_consumption')"
        >
          <b-input-group append="kWh/year">
            <b-input
              type="number"
              :class="highlight('supply_winter_consumption', dsSupply.winterConsumption)"
              v-model="dsSupply.winterConsumption"
              @change="changeConsumptionData"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_winter_consumption')"
          type="decimal"
          :labels="{
            supply_winter_consumption: dsSupply.winterConsumption
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'RHT cons.' : 'supply_rht_consumption'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_rht_consumption')"
        >
          <b-input-group append="kWh/year">
            <ds-input-tooltip text="RHT stands for restricted hours tariff" icon="info" />
            <b-input
              type="number"
              :class="highlight('supply_rht_consumption', dsSupply.rhtConsumption)"
              v-model="dsSupply.rhtConsumption"
              @change="changeConsumptionData"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_rht_consumption')"
          type="decimal"
          :labels="{
            supply_rht_consumption: dsSupply.rhtConsumption
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Total cons.' : 'supply_total_consumption'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_total_consumption')"
        >
          <b-input-group append="kWh/year">
            <ds-input-tooltip text="Sum of consumptions" />
            <b-input
              type="number"
              :class="highlight('supply_total_consumption', dsSupply.totalConsumption)"
              v-model="dsSupply.totalConsumption"
              @change="changeConsumptionData(false)"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_total_consumption')"
          type="decimal"
          :labels="{
            supply_total_consumption: dsSupply.totalConsumption
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Authorised Capacity Estim.' : 'supply_authorised_capacity'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_authorised_capacity')"
        >
          <b-input-group append="kVa">
            <b-input
              type="number"
              :class="highlight('supply_authorised_capacity', dsSupply.authorisedCapacity)"
              v-model="dsSupply.authorisedCapacity"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_authorised_capacity')"
          type="decimal"
          :labels="{
            supply_authorised_capacity: dsSupply.authorisedCapacity
          }"
        />

        <b-form-group
          :label="
            !showDsLabels ? 'Authorised Capacity Charge' : 'supply_authorised_capacity_charge'
          "
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_authorised_capacity_charge')"
        >
          <b-input-group append="pence/kVa/day">
            <b-input
              type="number"
              :class="
                highlight('supply_authorised_capacity_charge', dsSupply.authorisedCapacityCharge)
              "
              v-model="dsSupply.authorisedCapacityCharge"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_authorised_capacity_charge')"
          type="decimal"
          :labels="{
            supply_authorised_capacity_charge: dsSupply.authorisedCapacityCharge
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Excess Capacity Charge' : 'supply_excess_capacity_charge'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('supply_excess_capacity_charge')"
        >
          <b-input-group append="pence/kVa/day">
            <b-input
              type="number"
              :class="highlight('supply_excess_capacity_charge', dsSupply.excessCapacityCharge)"
              v-model="dsSupply.excessCapacityCharge"
            ></b-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('supply_excess_capacity_charge')"
          type="decimal"
          :labels="{
            supply_excess_capacity_charge: dsSupply.excessCapacityCharge
          }"
        />

        <b-form-checkbox
          class="mt-2"
          switch
          v-if="show('current_is_renewal')"
          v-model="dsSupply.currentIsRenewal"
          :class="highlight('current_is_renewal')"
          >Is current account with {{ deal.supplierName }} supplier? (renewal)</b-form-checkbox
        >
        <ds-label-breakdown
          v-if="showDsLabels && show('current_is_renewal')"
          type="checkbox"
          :labels="{
            current_is_renewal: dsSupply.currentIsRenewal
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Current Acc. No.? (renewal)' : 'current_supplier_account_number'"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '5'"
          v-if="show('current_supplier_account_number')"
        >
          <b-form-input
            :class="highlight('current_supplier_account_number', dsSupply.existSupplierAccNo)"
            v-model="dsSupply.existSupplierAccNo"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#ds-supply-fields {
  .checkbox-append {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.2rem 0 0.2rem;

    background-color: $color-grey-lighter6;
    border-color: $color-grey-lighter2;
    color: $color-grey-lighter1;

    font-size: 12px;

    &:hover {
      cursor: initial;
    }
  }
}
</style>

<script>
import { dsFieldsMixin } from "@/components/docusign/mixins/dsFieldsMixin";

export default {
  name: "DSSupplyFields",
  mixins: [dsFieldsMixin],
  props: {
    supply: Object,
    persistedSupply: Object,
    dSFields: Object,
    deal: Object
  },
  data() {
    return {
      dsSupply: {
        address: {},
        name: null,
        quoteRef: null,
        quoteValidity: null,
        meterNumber: null,
        meterSerial: null,
        meterType: null,
        productName: null,

        totalConsumption: null,
        dayConsumption: null,
        nightConsumption: null,
        ewConsumption: null,
        winterConsumption: null,
        rhtConsumption: null,
        authorisedCapacity: null,
        authorisedCapacityCharge: null,
        excessCapacityCharge: null,

        existSupplierAccNo: null,
        currentIsRenewal: false,
        isAMRMeter: false
      },

      consumptionData: {
        totalConsumption: null,
        dayConsumption: null,
        nightConsumption: null,
        ewConsumption: null,
        winterConsumption: null,
        rhtConsumption: null
      },

      selectedAddress: null,
      errorMessage: null
    };
  },
  created() {
    if (this.persistedSupply && Object.keys(this.persistedSupply).length > 0) {
      this.dsSupply = Object.assign({}, this.persistedSupply);
    } else if (!this.isEmpty(this.supply)) {
      this.dsSupply.name = this.supply.name;
      this.dsSupply.address = this.supply.address ? Object.assign({}, this.supply.address) : {};
      this.dsSupply.dayConsumption = this.supply.dayConsumption;
      this.dsSupply.ewConsumption = this.supply.ewConsumption;
      this.dsSupply.winterConsumption = this.supply.winterConsumption;
      this.dsSupply.nightConsumption = this.supply.nightConsumption;
      this.dsSupply.meterNumber = this.supply.meterNumber;
    }
    this.changeConsumptionData();
  },
  computed: {
    meterNumberState: function () {
      if (
        !this.isEmpty(this.supply) &&
        this.supply.type == "ELECTRICITY" &&
        !this.isEmpty(this.dsSupply) &&
        !this.isEmpty(this.dsSupply.meterNumber)
      ) {
        if (this.dsSupply.meterNumber.replace(/\s/g, "").length != 21) {
          return false;
        } else {
          return true;
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    changeConsumptionData(changeTotal = true) {
      if (changeTotal) {
        this.dsSupply.totalConsumption =
          Number(this.dsSupply.dayConsumption ? this.dsSupply.dayConsumption : 0) +
          Number(this.dsSupply.ewConsumption ? this.dsSupply.ewConsumption : 0) +
          Number(this.dsSupply.winterConsumption ? this.dsSupply.winterConsumption : 0) +
          Number(this.dsSupply.nightConsumption ? this.dsSupply.nightConsumption : 0) +
          Number(this.dsSupply.rhtConsumption ? this.dsSupply.rhtConsumption : 0);
      }

      this.consumptionData.totalConsumption = this.dsSupply.totalConsumption;
      this.consumptionData.dayConsumption = this.dsSupply.dayConsumption;
      this.consumptionData.nightConsumption = this.dsSupply.nightConsumption;
      this.consumptionData.ewConsumption = this.dsSupply.ewConsumption;
      this.consumptionData.winterConsumption = this.dsSupply.winterConsumption;
      this.consumptionData.rhtConsumption = this.dsSupply.rhtConsumption;
      this.$emit("consumption-data-changed", this.consumptionData);
    },

    clearFields() {
      this.dsSupply = {
        address: {}
      };
    },

    failChecks() {
      if (this.meterNumberState == false) {
        this.errorMessage = "MPAN should have 21 characters.";
        return false;
      } else return true;
    },

    getSupply() {
      if (this.failChecks) {
        return this.dsSupply;
      }
    }
  }
};
</script>

