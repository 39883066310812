<template>
  <div class="supply-form-address">
    <b-dropdown
      lazy
      :class="btnClass"
      :text="displayAddress"
      @show="updateAddressOptions"
      size="sm"
    >
      <div class="d-flex pt-2">
        <b-select
          :value="address"
          :options="addressOptions"
          class="mr-1 address-select"
          :disabled="newAddress || !addressOptions.length"
          @input="onAddressSelect"
        >
        </b-select>
        <b-button
          @click="onNewAddressClick()"
          :class="'new-address-btn' + (newAddress ? ' active' : '')"
          v-b-tooltip="newAddress ? 'Cancel' : 'Add new address'"
          size="sm"
        >
          <font-awesome-icon :icon="['fas', 'plus']" />
        </b-button>
      </div>
      <b-form class="pt-2" v-if="address && !newAddress">
        <b-form-group label="Address 1" label-for="address-shared-address1">
          <b-form-input
            id="address-shared-address1"
            type="text"
            v-model="address.address1"
            placeholder="Address line 1"
            trim
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Address 2" label-for="address-shared-address2">
          <b-form-input
            id="address-shared-address2"
            type="text"
            v-model="address.address2"
            placeholder="Address line 2"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Address 3" label-for="address-shared-address3">
          <b-form-input
            id="address-shared-address3"
            type="text"
            v-model="address.address3"
            placeholder="Address line 3"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="City" label-for="address-shared-city">
          <b-form-input
            id="address-shared-city"
            type="text"
            v-model="address.city"
            placeholder="City"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="County" label-for="address-shared-county">
          <b-form-input
            id="address-shared-county"
            type="text"
            v-model="address.county"
            placeholder="County"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Postcode" label-for="address-shared-postcode">
          <b-form-input
            id="address-shared-postcode"
            type="text"
            v-model="address.postcode"
            placeholder="Postcode"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
      </b-form>

      <!-- New address form -->
      <b-form class="mt-2" @submit.prevent.stop @input.stop @change.stop v-else>
        <b-form-group label="Address 1" label-for="address-new-address1">
          <b-form-input
            id="address-new-address1"
            type="text"
            v-model="newAddressForm.address1"
            placeholder="Address line 1"
            trim
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Address 2" label-for="address-new-address2">
          <b-form-input
            id="address-new-address2"
            type="text"
            v-model="newAddressForm.address2"
            placeholder="Address line 2"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Address 3" label-for="address-new-address3">
          <b-form-input
            id="address-new-address3"
            type="text"
            v-model="newAddressForm.address3"
            placeholder="Address line 3"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="City" label-for="address-new-city">
          <b-form-input
            id="address-new-city"
            type="text"
            v-model="newAddressForm.city"
            placeholder="City"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="County" label-for="address-new-county">
          <b-form-input
            id="address-new-county"
            type="text"
            v-model="newAddressForm.county"
            placeholder="County"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Postcode" label-for="address-new-postcode">
          <b-form-input
            id="address-new-postcode"
            type="text"
            v-model="newAddressForm.postcode"
            placeholder="Postcode"
            :disabled="noAddressSelected"
          >
          </b-form-input>
        </b-form-group>
        <b-button v-if="!noAddressSelected" size="sm" class="mt-1 ml-1" @click="createNewAddress()"
          >Save</b-button
        >
      </b-form>
    </b-dropdown>
  </div>
</template>

<script>
import Addresses from "@/components/savingseditor/helpers/sharedaddresses";

export default {
  name: "SupplyFormAddress",
  model: {
    prop: "address",
    event: "change"
  },
  props: {
    address: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      addressOptions: [],
      newAddress: false,
      newAddressForm: {
        address1: "",
        address2: "",
        address3: "",
        county: "",
        city: "",
        postcode: "",
        country: "GBR"
      }
    };
  },
  computed: {
    displayAddress() {
      if (this.address && this.address.address1) {
        return this.address.address1.length > 50
          ? this.address.address1.substring(0, 50) + "..."
          : this.address.address1;
      } else {
        return "Enter Address";
      }
    },
    btnClass() {
      return ["dropdown-btn", { new: this.displayAddress == "Enter Address" }];
    },
    noAddressSelected() {
      return !this.newAddress && !this.address;
    }
  },
  methods: {
    saveForm(value) {
      this.$emit("change", value);
    },

    createNewAddress() {
      let address = Object.assign({}, this.newAddressForm);
      this.newAddress = false;

      this.saveForm(address);
      Addresses.addAddresses([address]);
      this.resetNewAddressForm();
    },

    async onAddressSelect(val) {
      await this.$nextTick();
      this.saveForm(val);
      await this.$nextTick();
      this.$forceUpdate();
    },

    onNewAddressClick() {
      if (this.newAddress) {
        this.newAddress = false;
        this.resetNewAddressForm();
      } else {
        this.newAddress = true;
      }
    },

    updateAddressOptions() {
      this.addressOptions = Addresses.addressOptions;
    },

    resetNewAddressForm() {
      this.newAddressForm = {
        address1: "",
        address2: "",
        address3: "",
        county: "",
        city: "",
        postcode: "",
        country: "GBR"
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.supply-form-address {
  .dropdown-menu {
    padding: 0.3rem !important;
    width: 23rem !important;
  }
  .address-select {
    max-width: none !important;
    width: 100% !important;
  }
  label {
    margin: 0 0.5rem;
    white-space: nowrap;
    font-size: 0.85rem;
  }
  .form-group {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    padding-right: 0 !important;
    input {
      min-width: 16rem !important;
    }
  }
  .dropdown-btn > .btn {
    background-color: white;
    color: $color-font-para;
    border-color: $color-grey-lighter2;
  }
  .dropdown-btn.disabled > .btn {
    background-color: $color-grey-lighter2;
  }
  .dropdown-btn.new > .btn {
    color: $color-font-para;
  }
  .new-address-btn {
    color: $color-font-para;
    background-color: white;
    border-color: $color-grey-lighter2;
    svg {
      transform: rotate(0deg);
      transition: all 0.25s ease-in-out;
    }
    &:focus {
      background-color: white !important;
    }
    &.active {
      svg {
        transform: rotate(45deg);
      }
      background-color: white !important;
      color: red !important;
      border-color: red !important;
    }
  }
}
</style>
