<template>
  <div class="deal-form-wrapper">
    <div v-if="deal.supplierId || dealSavings" class="deal-form-tabs">
      <div v-if="deal.supplierId" class="deal-form-tab">
        <b-img-lazy v-if="supplierLogo" :src="supplierLogo" />
        <span class="p-1 small" v-else>{{ deal.supplierName }}</span>
      </div>
      <div v-if="dealSavings" class="deal-form-tab savings-tab">
        <b-badge variant="primary"> Savings: {{ $currencySymbol }}{{ dealSavings }} </b-badge>
      </div>
    </div>

    <div :class="dealFormClass" tabindex="0">
      <deal-form-toolbar
        :deal="deal"
        :supply="supply"
        :show-fields.sync="showFields"
        :draft-id="deal.draftId"
        :supplyContractIds="supplyContractIds"
        @change="assignDraftId"
        @loadSupplyContractIds="loadSupplyContractIds"
      />
      <b-form @change="assignDraftId">
        <div class="inline-inputs">
          <b-form-group label="Supplier">
            <b-form-select
              :value="deal.supplierId"
              :options="supplierOptions"
              @change="assignSupplier"
            />
          </b-form-group>

          <b-form-group label="Annual Cost">
            <b-form-input v-model="deal.annualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.projected" label="Proj Annual Cost">
            <b-form-input v-model="deal.projectedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.estimated" label="Estimated Annual Cost">
            <b-form-input v-model="deal.estimatedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea v-model="deal.description" />
          </b-form-group>

          <b-form-group v-if="CURRENT || FUTURE" label="Contract Start">
            <date-input v-model="deal.contractStartDate" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Contract Term">
            <contract-term-input v-model="deal.contractTerm" />
          </b-form-group>

          <b-form-group v-if="CURRENT || LEGACY" label="Contract End">
            <date-input v-model="deal.contractExpiry" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Total Commission">
            <b-form-input
              v-model="deal.consumptionCommission"
              type="number"
              min="0"
              @change="set('consumptionComission', $event)"
            />
          </b-form-group>

          <!-- For smooth tabbing -->
          <a @focus="focusOnSaveBtn($el)" href />
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { dealFormMixin } from "../mixins/dealFormMixin";

export default {
  name: "CustomDealForm",
  mixins: [dealFormMixin]
};
</script>
