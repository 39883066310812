var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ds-section",attrs:{"id":"ds-organisation-fields"}},[_c('div',{attrs:{"cols":"12","id":"section-header"}},[_c('p',{staticClass:"m-0"},[_vm._v("DEAL SECTION")]),_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":_vm.toggleAutocalc},model:{value:(_vm.autocalculations),callback:function ($$v) {_vm.autocalculations=$$v},expression:"autocalculations"}},[_vm._v("AUTOCALCULATIONS")])],1),_c('div',{attrs:{"id":"section-body"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":!_vm.reqOnly ? '6' : '12'}},[(_vm.show('deal_contract_name'))?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('can also be: Contract name, Plan name, Version Code'),expression:"'can also be: Contract name, Plan name, Version Code'",modifiers:{"hover":true}}],attrs:{"label":!_vm.showDsLabels ? 'Contract Code' : 'deal_contract_name',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',[_c('b-input',{class:_vm.highlight('deal_contract_name', _vm.dsDeal.contractName),attrs:{"type":"text"},model:{value:(_vm.dsDeal.contractName),callback:function ($$v) {_vm.$set(_vm.dsDeal, "contractName", $$v)},expression:"dsDeal.contractName"}})],1)],1):_vm._e(),(_vm.show('deal_contract_start'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Contract Start' : 'deal_contract_start',"label-cols":"4","label-cols-xl":"5"}},[_c('b-form-input',{class:_vm.highlight('deal_contract_start', _vm.dsDeal.contractStartDate),attrs:{"type":"date"},on:{"input":_vm.generateContractEndDate},model:{value:(_vm.dsDeal.contractStartDate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "contractStartDate", $$v)},expression:"dsDeal.contractStartDate"}})],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_contract_start'))?_c('ds-label-breakdown',{attrs:{"type":"date","labels":{
            deal_contract_start: _vm.dsDeal.contractStartDate
          }}}):_vm._e(),(_vm.show('deal_contract_term'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Contract Term' : 'deal_contract_term',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"months"}},[_c('b-input',{class:_vm.highlight('deal_contract_term', _vm.dsDeal.contractTerm),attrs:{"type":"number","step":".01"},on:{"input":function($event){_vm.autocalc(null, false);
                _vm.generateContractEndDate();}},model:{value:(_vm.dsDeal.contractTerm),callback:function ($$v) {_vm.$set(_vm.dsDeal, "contractTerm", $$v)},expression:"dsDeal.contractTerm"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_contract_term'))?_c('ds-label-breakdown',{attrs:{"labels":{
            deal_contract_term_month: _vm.dsDeal.contractTerm,
            deal_contract_term_year: parseInt(
              Number(_vm.dsDeal.contractTerm ? _vm.dsDeal.contractTerm : 0) / 12
            ),
            deal_contract_term_month_specific: _vm.dsDeal.contractTerm + ' month(s)',
            deal_contract_term_year_specific:
              parseInt(Number(_vm.dsDeal.contractTerm ? _vm.dsDeal.contractTerm : 0) / 12) + ' year(s)'
          }}}):_vm._e(),(_vm.show('deal_contract_end'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Contract End' : 'deal_contract_end',"label-cols":"4","label-cols-xl":"5"}},[_c('b-form-input',{class:_vm.highlight('deal_contract_end', _vm.dsDeal.contractExpiry),attrs:{"type":"date"},model:{value:(_vm.dsDeal.contractExpiry),callback:function ($$v) {_vm.$set(_vm.dsDeal, "contractExpiry", $$v)},expression:"dsDeal.contractExpiry"}})],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_contract_end'))?_c('ds-label-breakdown',{attrs:{"type":"date","labels":{
            deal_contract_end: _vm.dsDeal.contractExpiry
          }}}):_vm._e(),(_vm.show('deal_current_contract_end'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Current Contract End' : 'deal_current_contract_end',"label-cols":"4","label-cols-lg":"5"}},[_c('b-form-input',{class:_vm.highlight('deal_current_contract_end', _vm.dsDeal.currentContractExpiry),attrs:{"type":"date","placeholder":"YYYY-MM-DD"},model:{value:(_vm.dsDeal.currentContractExpiry),callback:function ($$v) {_vm.$set(_vm.dsDeal, "currentContractExpiry", $$v)},expression:"dsDeal.currentContractExpiry"}})],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_current_contract_end'))?_c('ds-label-breakdown',{attrs:{"type":"date","labels":{
            deal_current_contract_end: _vm.dsDeal.currentContractExpiry
          }}}):_vm._e(),(_vm.show('deal_is_green_energy'))?_c('b-form-checkbox',{staticClass:"mt-2",class:_vm.highlight('deal_is_green_energy'),attrs:{"switch":""},model:{value:(_vm.dsDeal.isGreenEnergy),callback:function ($$v) {_vm.$set(_vm.dsDeal, "isGreenEnergy", $$v)},expression:"dsDeal.isGreenEnergy"}},[_vm._v("Is Green Energy?")]):_vm._e(),(_vm.showDsLabels && _vm.show('deal_is_green_energy'))?_c('ds-label-breakdown',{attrs:{"type":"checkbox","labels":{
            deal_is_green_energy: _vm.dsDeal.isGreenEnergy
          }}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","xl":!_vm.reqOnly ? '6' : '12'}},[(_vm.show('deal_bills_volume'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Bills Est. Vol.' : 'deal_bills_volume',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"kWh Term"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"4 x contract term (years)"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_bills_volume', _vm.dsDeal.billsVolume),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.billsVolume),callback:function ($$v) {_vm.$set(_vm.dsDeal, "billsVolume", $$v)},expression:"dsDeal.billsVolume"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_bills_volume'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_bills_volume: _vm.dsDeal.billsVolume
          }}}):_vm._e(),(_vm.show('deal_day_volume'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Day Est. Vol.' : 'deal_day_volume',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"kWh Term"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"Day consumption * contract term (years)"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_day_volume', _vm.dsDeal.dayVolume),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.dayVolume),callback:function ($$v) {_vm.$set(_vm.dsDeal, "dayVolume", $$v)},expression:"dsDeal.dayVolume"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_day_volume'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_day_volume: _vm.dsDeal.dayVolume
          }}}):_vm._e(),(_vm.show('deal_night_volume'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Night Est. Vol.' : 'deal_night_volume',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"kWh Term"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"Night consumption * contract term (years)"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_night_volume', _vm.dsDeal.nightVolume),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.nightVolume),callback:function ($$v) {_vm.$set(_vm.dsDeal, "nightVolume", $$v)},expression:"dsDeal.nightVolume"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_night_volume'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_night_volume: _vm.dsDeal.nightVolume
          }}}):_vm._e(),(_vm.show('deal_ew_volume'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'EW Est. Vol.' : 'deal_ew_volume',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"kWh Term"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"EW consumption * contract term (years)"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_ew_volume', _vm.dsDeal.ewVolume),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.ewVolume),callback:function ($$v) {_vm.$set(_vm.dsDeal, "ewVolume", $$v)},expression:"dsDeal.ewVolume"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_ew_volume'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_ew_volume: _vm.dsDeal.ewVolume
          }}}):_vm._e(),(_vm.show('deal_fits_volume'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'FiTS Est. Vol.' : 'deal_fits_volume',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"kWh Term"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"FiTS consumption * contract term (years)"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_fits_volume', _vm.dsDeal.fitsVolume),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.fitsVolume),callback:function ($$v) {_vm.$set(_vm.dsDeal, "fitsVolume", $$v)},expression:"dsDeal.fitsVolume"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_fits_volume'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_fits_volume: _vm.dsDeal.fitsVolume
          }}}):_vm._e(),(_vm.show('deal_total_volume'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Total Est. Vol.' : 'deal_total_volume',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"kWh Term"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"Total consumption * contract term (years)"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_total_volume', _vm.dsDeal.totalVolume),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcTotal},model:{value:(_vm.dsDeal.totalVolume),callback:function ($$v) {_vm.$set(_vm.dsDeal, "totalVolume", $$v)},expression:"dsDeal.totalVolume"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_total_volume'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_total_volume: _vm.dsDeal.totalVolume
          }}}):_vm._e()],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":!_vm.reqOnly ? '6' : '12'}},[(_vm.show('deal_standing_charge'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Standing Charge' : 'deal_standing_charge',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/QTR"}},[_c('b-input',{class:_vm.highlight('deal_standing_charge', _vm.dsDeal.standingCharge),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.standingCharge),callback:function ($$v) {_vm.$set(_vm.dsDeal, "standingCharge", $$v)},expression:"dsDeal.standingCharge"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_standing_charge'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_standing_charge: _vm.dsDeal.standingCharge
          }}}):_vm._e(),(_vm.show('deal_day_rate'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Day Rate' : 'deal_day_rate',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/kWh"}},[_c('b-input',{class:_vm.highlight('deal_day_rate', _vm.dsDeal.dayRate),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.dayRate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "dayRate", $$v)},expression:"dsDeal.dayRate"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_day_rate'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_day_rate: _vm.dsDeal.dayRate
          }}}):_vm._e(),(_vm.show('deal_night_rate'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Night Rate' : 'deal_night_rate',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/kWh"}},[_c('b-input',{class:_vm.highlight('deal_night_rate', _vm.dsDeal.nightRate),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.nightRate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "nightRate", $$v)},expression:"dsDeal.nightRate"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_night_rate'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_night_rate: _vm.dsDeal.nightRate
          }}}):_vm._e(),(_vm.show('deal_ew_rate'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'EW Rate' : 'deal_ew_rate',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/kWh"}},[_c('b-input',{class:_vm.highlight('deal_ew_rate', _vm.dsDeal.ewRate),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.ewRate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "ewRate", $$v)},expression:"dsDeal.ewRate"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_ew_rate'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_ew_rate: _vm.dsDeal.ewRate
          }}}):_vm._e(),(_vm.show('deal_fits_rate'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'FiTS Rate' : 'deal_fits_rate',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/kWh"}},[_c('b-input',{class:_vm.highlight('deal_fits_rate', _vm.dsDeal.fitsRate),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcCosts},model:{value:(_vm.dsDeal.fitsRate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "fitsRate", $$v)},expression:"dsDeal.fitsRate"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_fits_rate'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_fits_rate: _vm.dsDeal.fitsRate
          }}}):_vm._e(),(_vm.show('deal_winter_rate'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Winter Rate' : 'deal_winter_rate',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/kWh"}},[_c('b-input',{class:_vm.highlight('deal_winter_rate', _vm.dsDeal.winterRate),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.winterRate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "winterRate", $$v)},expression:"dsDeal.winterRate"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_winter_rate'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_winter_rate: _vm.dsDeal.winterRate
          }}}):_vm._e(),(_vm.show('deal_rht_rate'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'RHT Rate' : 'deal_rht_rate',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"pence/kWh"}},[_c('b-input',{class:_vm.highlight('deal_rht_rate', _vm.dsDeal.rhtRate),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.rhtRate),callback:function ($$v) {_vm.$set(_vm.dsDeal, "rhtRate", $$v)},expression:"dsDeal.rhtRate"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_rht_rate'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_rht_rate: _vm.dsDeal.rhtRate
          }}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","xl":!_vm.reqOnly ? '6' : '12'}},[(_vm.show('deal_bills_costs'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Bills Est. Cost' : 'deal_bills_costs',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":_vm.$currencySymbol}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"Standing charge * Bills Est. Vol."}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_bills_costs', _vm.dsDeal.billsCosts),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.billsCosts),callback:function ($$v) {_vm.$set(_vm.dsDeal, "billsCosts", $$v)},expression:"dsDeal.billsCosts"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_bills_costs'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_bills_costs: _vm.dsDeal.billsCosts
          }}}):_vm._e(),(_vm.show('deal_day_costs'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Day Est. Cost' : 'deal_day_costs',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":_vm.$currencySymbol}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"(Day Rate * Day Est. Vol) / 100"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_day_costs', _vm.dsDeal.dayCosts),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.dayCosts),callback:function ($$v) {_vm.$set(_vm.dsDeal, "dayCosts", $$v)},expression:"dsDeal.dayCosts"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_day_costs'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_day_costs: _vm.dsDeal.dayCosts
          }}}):_vm._e(),(_vm.show('deal_night_costs'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Night Est. Cost' : 'deal_night_costs',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":_vm.$currencySymbol}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"(Night Rate * Night Est. Vol) / 100"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_night_costs', _vm.dsDeal.nightCosts),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.nightCosts),callback:function ($$v) {_vm.$set(_vm.dsDeal, "nightCosts", $$v)},expression:"dsDeal.nightCosts"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_night_costs'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_night_costs: _vm.dsDeal.nightCosts
          }}}):_vm._e(),(_vm.show('deal_ew_costs'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'EW Est. Cost' : 'deal_ew_costs',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":_vm.$currencySymbol}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"(EW Rate * EW Est. Vol) / 100"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_ew_costs', _vm.dsDeal.ewCosts),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.ewCosts),callback:function ($$v) {_vm.$set(_vm.dsDeal, "ewCosts", $$v)},expression:"dsDeal.ewCosts"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_ew_costs'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_ew_costs: _vm.dsDeal.ewCosts
          }}}):_vm._e(),(_vm.show('deal_fits_costs'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'FiTS Est. Cost' : 'deal_fits_costs',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":_vm.$currencySymbol}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"(FiTS Rate * FiTS Est. Vol) / 100"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_fits_costs', _vm.dsDeal.fitsCosts),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.fitsCosts),callback:function ($$v) {_vm.$set(_vm.dsDeal, "fitsCosts", $$v)},expression:"dsDeal.fitsCosts"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_fits_costs'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_fits_costs: _vm.dsDeal.fitsCosts
          }}}):_vm._e(),(_vm.show('deal_annual_cost'))?_c('b-form-group',{attrs:{"label":_vm.showDsLabels
              ? 'deal_annual_cost'
              : _vm.autocalculations
              ? 'Total Est. Cost'
              : 'Annual Cost',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":_vm.$currencySymbol + '/year'}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"Sum of all est. cost"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_annual_cost', _vm.dsDeal.annualCost),attrs:{"type":"number","step":".01"},on:{"input":_vm.autocalcTotal},model:{value:(_vm.dsDeal.annualCost),callback:function ($$v) {_vm.$set(_vm.dsDeal, "annualCost", $$v)},expression:"dsDeal.annualCost"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_annual_cost'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_annual_cost: _vm.dsDeal.annualCost
          }}}):_vm._e(),(_vm.show('deal_estimated_total'))?_c('b-form-group',{attrs:{"label":!_vm.showDsLabels ? 'Estimated Total' : 'deal_estimated_total',"label-cols":"4","label-cols-xl":"5"}},[_c('b-input-group',{attrs:{"append":"AVEPPU"}},[(_vm.autocalculations)?_c('ds-input-tooltip',{attrs:{"text":"( Total. Est. Cost / Total Est. Vol. ) * 100"}}):_vm._e(),_c('b-input',{class:_vm.highlight('deal_estimated_total', _vm.dsDeal.estimatedTotal),attrs:{"type":"number","step":".01"},model:{value:(_vm.dsDeal.estimatedTotal),callback:function ($$v) {_vm.$set(_vm.dsDeal, "estimatedTotal", $$v)},expression:"dsDeal.estimatedTotal"}})],1)],1):_vm._e(),(_vm.showDsLabels && _vm.show('deal_estimated_total'))?_c('ds-label-breakdown',{attrs:{"type":"decimal","labels":{
            deal_estimated_total: _vm.dsDeal.estimatedTotal
          }}}):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":!_vm.reqOnly ? '6' : '12'}},[(
            _vm.showGroup([
              'deal_business_standard',
              'deal_business_economy7',
              'deal_business_ew',
              'deal_business_ew_economy7',
              'deal_business'
            ])
          )?_c('b-form-group',{attrs:{"label":"Business Type","label-cols":"3","label-cols-xl":"3"}},[_c('b-form-checkbox-group',{staticClass:"ds-checkbox-group",model:{value:(_vm.dsDeal.businessTypes),callback:function ($$v) {_vm.$set(_vm.dsDeal, "businessTypes", $$v)},expression:"dsDeal.businessTypes"}},[(_vm.showGroup(['deal_business', 'deal_business_standard']))?_c('b-form-checkbox',{class:_vm.highlight(['deal_business', 'deal_business_standard']),attrs:{"value":"standard"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Standard" : "deal_business_standard")+" ")]):_vm._e(),(_vm.showGroup(['deal_business', 'deal_business_economy7']))?_c('b-form-checkbox',{class:_vm.highlight(['deal_business', 'deal_business_economy7']),attrs:{"value":"economy7"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Economy 7" : "deal_business_economy7"))]):_vm._e(),(_vm.showGroup(['deal_business', 'deal_business_ew']))?_c('b-form-checkbox',{class:_vm.highlight(['deal_business', 'deal_business_ew']),attrs:{"value":"eveningWeekend"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Evening & Weeken" : "deal_business_ew"))]):_vm._e(),(_vm.showGroup(['deal_business', 'deal_business_ew_economy7']))?_c('b-form-checkbox',{class:_vm.highlight(['deal_business', 'deal_business_ew_economy7']),attrs:{"value":"eveningWeekendEconomy7"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Evening & Weekend with Economy 7" : "deal_business_ew_economy7"))]):_vm._e()],1)],1):_vm._e(),(
            _vm.showDsLabels &&
            _vm.showGroup([
              'deal_business_standard',
              'deal_business_economy7',
              'deal_business_ew',
              'deal_business_ew_economy7',
              'deal_business'
            ])
          )?_c('ds-label-breakdown',{attrs:{"labels":{ deal_business_text: _vm.textCheckboxLabel(_vm.dsDeal.businessTypes) }}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","xl":!_vm.reqOnly ? '6' : '12'}},[(
            _vm.showGroup([
              'sale_type_acquisition',
              'sale_type_upgrade',
              'sale_type_renewal',
              'sale_type_retention',
              'sale_type'
            ])
          )?_c('b-form-group',{attrs:{"label":"Sale Type","label-cols":"3","label-cols-xl":"3"}},[_c('b-form-checkbox-group',{staticClass:"ds-checkbox-group",model:{value:(_vm.dsDeal.salesTypes),callback:function ($$v) {_vm.$set(_vm.dsDeal, "salesTypes", $$v)},expression:"dsDeal.salesTypes"}},[(_vm.showGroup(['sale_type_acquisition', 'sale_type']))?_c('b-form-checkbox',{class:_vm.highlight(['sale_type_acquisition', 'sale_type']),attrs:{"value":"acquisition"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Acquisition" : "sale_type_acquisition"))]):_vm._e(),(_vm.showGroup(['sale_type_upgrade', 'sale_type']))?_c('b-form-checkbox',{class:_vm.highlight(['sale_type_upgrade', 'sale_type']),attrs:{"value":"upgrade"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Upgrade" : "sale_type_upgrade"))]):_vm._e(),(_vm.showGroup(['sale_type_renewal', 'sale_type']))?_c('b-form-checkbox',{class:_vm.highlight(['sale_type_renewal', 'sale_type']),attrs:{"value":"renewal"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Renewal" : "sale_type_renewal"))]):_vm._e(),(_vm.showGroup(['sale_type_retention', 'sale_type']))?_c('b-form-checkbox',{class:_vm.highlight(['sale_type_retention', 'sale_type']),attrs:{"value":"retention"}},[_vm._v(_vm._s(!_vm.showDsLabels ? "Retention" : "sale_type_retention"))]):_vm._e()],1)],1):_vm._e(),(
            _vm.showDsLabels &&
            _vm.showGroup([
              'sale_type_acquisition',
              'sale_type_upgrade',
              'sale_type_renewal',
              'sale_type_retention',
              'sale_type'
            ])
          )?_c('ds-label-breakdown',{attrs:{"labels":{ sale_type_text: _vm.textCheckboxLabel(_vm.dsDeal.salesTypes) }}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }