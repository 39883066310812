<template>
  <b-input-group class="calculated-input" size="sm">
    <b-form-input
      :value="calculatedProjUnitRate"
      :disabled="autoCalculate"
      @input="onChange"
      type="number"
      min="0"
    />
    <b-input-group-append>
      <b-button @click="autoCalculate = !autoCalculate" :class="{ active: autoCalculate }">
        <font-awesome-icon :icon="['fas', 'calculator']" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: "ProjectedUnitRateInput",
  props: {
    deal: Object,
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      autoCalculate: true
    };
  },
  computed: {
    calculatedProjUnitRate() {
      if (this.autoCalculate) {
        const calculated = parseFloat(
          parseFloat(this.deal[this.currentUnitRateType]) +
            this.deal[this.currentUnitRateType] * (this.deal.projectedUnitRatePercent / 100)
        ).toFixed(3);
        this.onChange(calculated);
        return calculated;
      } else {
        return this.deal[this.type];
      }
    },
    currentUnitRateType() {
      let rate = this.type.replace("projected", "");
      return rate.charAt(0).toLowerCase() + rate.slice(1);
    }
  },
  updated() {
    this.checkIfAutoCalculated();
  },
  methods: {
    onChange(value) {
      this.$emit("input", isNaN(value) ? null : parseFloat(value));
    },

    checkIfAutoCalculated() {
      if (!this.deal[this.currentUnitRateType] || !this.deal.projectedUnitRatePercent) {
        this.autoCalculate = false;
      }
    }
  }
};
</script>
