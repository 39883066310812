var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supply-header d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"d-flex flex-column supply-title"},[_c('span',{staticClass:"supply-name"},[_vm._v(_vm._s(_vm.supply.name ? _vm.supply.name : "New supply " + (_vm.index + 1)))]),_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s(_vm.supply.meterNumber))])]),_c('div',{staticClass:"d-flex mr-auto pl-4"},[(_vm.currentSupplier)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.ds200",value:('Current supplier: ' + _vm.currentSupplier.text),expression:"'Current supplier: ' + currentSupplier.text",modifiers:{"ds200":true}}],staticClass:"supplier-badge border p-0 pr-2",attrs:{"variant":"secondary"}},[_c('span',{staticClass:"cur-supplier"},[_vm._v("CUR")]),(_vm.currentSupplier.logo)?_c('b-img',{staticClass:"m-auto",attrs:{"src":_vm.currentSupplier.logo}}):_c('span',{},[_vm._v(_vm._s(_vm.currentSupplier.text))])],1):_vm._e(),(_vm.recommendedSupplier)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.ds200",value:('Recommended supplier: ' + _vm.recommendedSupplier.text),expression:"'Recommended supplier: ' + recommendedSupplier.text",modifiers:{"ds200":true}}],staticClass:"supplier-badge border p-0 pr-2",attrs:{"variant":"secondary"}},[_c('span',{staticClass:"rec-supplier"},[_vm._v("REC")]),(_vm.recommendedSupplier.logo)?_c('b-img',{staticClass:"m-auto",attrs:{"src":_vm.recommendedSupplier.logo}}):_c('span',{},[_vm._v(_vm._s(_vm.recommendedSupplier.text))])],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[(_vm.supply.invoiceIds)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Auto Published'),expression:"'Auto Published'"}],staticClass:"ml-2",attrs:{"variant":_vm.supply.nosaving == 'No current or recommended' ? 'auto' : 'secondary'}},[_vm._v("AP")]):_vm._e(),(
        !_vm.supply.noQuotes &&
          _vm.savings.REPORT[_vm.type] &&
          _vm.savings.REPORT[_vm.type].supplies[_vm.id] &&
          _vm.savings.REPORT[_vm.type].supplies[_vm.id].commission
      )?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"commission"}},[_vm._v(" "+_vm._s(_vm.$currencySymbol)+_vm._s(_vm.supply.commission.toFixed(2))+" ")]):_vm._e(),(
        !_vm.supply.noQuotes &&
          _vm.savings.REPORT[_vm.type] &&
          _vm.savings.REPORT[_vm.type].supplies[_vm.id] &&
          _vm.savings.REPORT[_vm.type].supplies[_vm.id].saving
      )?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"savings"}},[_vm._v(" "+_vm._s(_vm.$currencySymbol)+_vm._s(_vm.savings.REPORT[_vm.type].supplies[_vm.id].saving.toFixed(2))+" ")]):_vm._e(),(_vm.supply.noQuotes && _vm.supply.showIndicativeSavings && _vm.supply.indicativeSaving)?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"indicative-savings"}},[_vm._v(" Indicative savings: "+_vm._s(_vm.$currencySymbol)+_vm._s(_vm.supply.indicativeSaving)+" ")]):_vm._e(),(_vm.supply.published && _vm.supply.noQuotes)?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"no-quotes"}},[_vm._v(" "+_vm._s(_vm.supply.noQuotes.replace(/_/g, " "))+" ")]):_vm._e(),(!_vm.supply.published)?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"unpublished"}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v(" Not published ")],1):_vm._e(),(!_vm.supply.noQuotes && _vm.supply.nosaving)?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"issue"}},[_vm._v(" "+_vm._s(_vm.supply.nosaving)+" ")]):_vm._e(),(_vm.contractState)?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":'contract' +
          (_vm.contractState.state == 'DEAD' || _vm.contractState.state == 'FAILED'
            ? '-danger'
            : _vm.contractState.state == 'LIVE'
            ? '-good'
            : '-progress')}},[_vm._v(" Contract: "+_vm._s(_vm.contractState.state)+" "),(_vm.contractState.dealExists == false)?_c('span',[_vm._v("- Deal removed ")]):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }