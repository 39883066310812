import FormatHelper from "@/helper/formathelper";
import DsInputTooltip from "@/components/docusign/parts/DsInputTooltip.vue";
import DsLabelBreakdown from "@/components/docusign/parts/DsLabelBreakdown.vue";

export const dsFieldsMixin = {
    model: {
        prop: "deal",
        event: "change"
    },
    components: {
        DsInputTooltip,
        DsLabelBreakdown
    },
    props: {
        isST: {
            type: Boolean,
            default: false
        },
        reqOnly: {
            type: Boolean,
            default: false
        },
        dSFields: Object,
        addresses: Array,
        showDsLabels: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        };
    },
    methods: {
        isEmpty: FormatHelper.isEmpty,
        formatTitleCase: FormatHelper.formatTitleCase,


        //Returns true if the fieldName is found under the template name in the supplierfields.json
        //Used on v-ifs
        show(fieldName) {
            if (this.reqOnly) {
                if (!this.isEmpty(this.dSFields[fieldName])) {
                    return true;
                } else return false;
            }
            return true;
        },

        //Returns true if the ANY of the fieldNames are found under the template name in the supplierfields.json
        //Used on v-ifs
        showGroup(fieldNames) {
            if (this.reqOnly) {
                for (const fieldName of fieldNames) {
                    if (!this.isEmpty(this.dSFields[fieldName])) {
                        return true;
                    }
                }

                return false;
            } else {
                return true;
            }
        },

        //Returns 'highlight' if the fieldName/any of the fieldNames is/are found in the supplierfields.json under the template name. 
        //Returns 'highlight req' if any of the fieldNames/fieldName is states as required (if sole trader is checked, checkes for req. in sole trader)
        //Returns 'highlight [...] filled' if the modelValue is != null signaling the input is filled
        //Best to use as addition to classes
        highlight(fieldNames, modelValue = null) {
            let response = "";
            if (!this.isEmpty(this.dSFields)) {
                if (typeof fieldNames === 'string' || typeof fieldNames === 'number') {
                    let field = this.dSFields[fieldNames];
                    if (!this.isEmpty(field)) {
                        response = "highlight";

                        if ((this.isST && field.required_st) || (!this.isST && field.required)) {
                            response += " required";
                        }
                    }
                }
                else if (!this.isEmpty(fieldNames)) {
                    let found = false;
                    fieldNames.forEach(fieldName => {
                        let field = this.dSFields[fieldName];
                        if (!this.isEmpty(field) && !found) {
                            response = "highlight";

                            if ((this.isST && field.required_st) || (!this.isST && field.required)) {
                                response += " required";
                                found = true;
                            }
                        }
                    })
                }
            }

            if (modelValue) {
                response += " filled";
            }

            return response;
        },

        formatCheckboxTitles(value) {
            if (value) {
                let result = value.replace(/(_)/g, "/").replace(/([A-Z])/g, "_$1");
                return result.charAt(0).toUpperCase() + result.slice(1);
            } else return value;
        },

        addTime(value) {
            if (!this.isEmpty(value) && !value.includes("T00:00:00")) {
                return (value += "T00:00:00");
            } else return value;
        },

        textCheckboxLabel(list) {
            let text = "";
            for (let i = 0; i < list.length; i++) {
                if (list[i] == "eveningWeekendEconomy7") {
                    text += "Evening Weekend Economy 7 ";
                } else if (list[i] == "eveningWeekend") {
                    text += "Evening Weekend ";
                } else if (list[i] == "economy7") {
                    text += "Economy 7 ";
                } else if (list[i] == "limitedCompany") {
                    text += "Limited Company ";
                } else if (list[i] == "publicSector") {
                    text += "Public Sector ";
                } else if (list[i] == "soleTrader") {
                    text += "Sole Trader ";
                } else {
                    text += this.formatTitleCase(list[i]) + " ";
                }
            }
            return text;
        }
    }
};

