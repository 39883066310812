<template>
  <div :class="['group list-input', { empty: !additionalCosts.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{
        additionalCosts.length ? "ADDITIONAL MONTHLY COSTS" : "ADD ADDITIONAL COSTS"
      }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new cost'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- Additional costs -->
    <div v-for="(additionalCost, i) in costObjects" :key="i" class="list-input-item">
      <b-form-group label="Description">
        <b-form-textarea
          :value="additionalCost.description"
          @change="setDescription(additionalCost, i, $event)"
        />
      </b-form-group>

      <b-form-group label="Charge">
        <b-form-input
          :value="additionalCost.charge"
          type="number" min="0"
          @input="setCharge(additionalCost, i, $event)"
        />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(additionalCost)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != additionalCosts.length - 1" class="divider" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalCostsInput",
  model: {
    prop: "additionalCosts",
    event: "change"
  },
  props: {
    additionalCosts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // additionalMonthlyCosts field is an array of key-value pairs
    // where key is description and value is the cost
    // we're converting it into an array of objects
    costObjects() {
      return this.additionalCosts.map((cost) => {
        const costEntry = Object.entries(cost)[0];
        return { description: costEntry[0], charge: costEntry[1] };
      });
    }
  },
  methods: {
    onChange() {
      this.$emit("change", this.additionalCosts);
    },

    add(additionalCost) {
      if (!additionalCost) {
        additionalCost = { "": 0 };
      } else {
        additionalCost = { [additionalCost.description]: additionalCost.charge };
      }
      this.additionalCosts.push(additionalCost);
    },

    remove(index) {
      this.$delete(this.additionalCosts, index);
      this.onChange();
    },

    setCharge(obj, i, value) {
      this.additionalCosts[i][obj.description] = value;
      this.onChange();
    },

    setDescription(obj, i, value) {
      this.$delete(this.additionalCosts[i], obj.description);
      this.additionalCosts[i][value] = obj.charge;
      this.onChange();
    }
  }
};
</script>
<style lang="scss" scoped>
.list-input.empty {
  max-width: 13.5rem;
}
</style>