<template>
  <div class="ds-section" id="ds-customer-fields">
    <div cols="12" id="section-header">
      <p class="m-0">CUSTOMER SECTION</p>
      <b-form-select
        class="float-xl-right w-auto"
        :disabled="!customers.length > 0"
        :options="customers"
        v-model="selectedCustomer"
        @input="selectCustomer(selectedCustomer)"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>
            -- Select customer --
          </b-form-select-option>
        </template>
      </b-form-select>
    </div>

    <b-row id="section-body">
      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <b-form-group
          :label="!showDsLabels ? 'Name' : ''"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '12'"
        >
          <div class="d-flex">
            <b-form-select
              v-if="show('customer_title')"
              :class="highlight('customer_title', dsCustomer.title)"
              v-model="dsCustomer.title"
              :options="customerTitles"
              class="w-auto"
            ></b-form-select>
            <b-form-input
              :class="
                highlight(['customer_full_name', 'customer_first_name'], dsCustomer.firstName)
              "
              v-model="dsCustomer.firstName"
              placeholder="First name"
              :state="customerState"
            ></b-form-input>
            <b-form-input
              :class="highlight(['customer_full_name', 'customer_last_name'], dsCustomer.lastName)"
              :state="customerState"
              v-model="dsCustomer.lastName"
              placeholder="Last name"
            ></b-form-input>
          </div>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels"
          type="name"
          section="customer"
          :labels="{
            customer_title: dsCustomer.title,
            customer_first_name: dsCustomer.firstName,
            customer_last_name: dsCustomer.lastName
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Email' : 'customer_email'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            :class="highlight('customer_email', dsCustomer.email)"
            :state="customerState"
            v-model="dsCustomer.email"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="show('customer_position')"
          :label="!showDsLabels ? 'Position' : 'customer_position'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            :class="highlight('customer_position', dsCustomer.position)"
            v-model="dsCustomer.position"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Contact No.' : ''"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '12'"
          v-if="showGroup(['customer_tel', 'customer_mobile'])"
        >
          <div class="d-flex">
            <b-form-input
              :class="highlight('customer_tel', dsCustomer.telNo)"
              placeholder="Telephone No."
              v-if="show('customer_tel')"
              v-model="dsCustomer.telNo"
            ></b-form-input>

            <b-form-input
              :class="highlight('customer_mobile', dsCustomer.mobileNo)"
              v-if="show('customer_mobile')"
              placeholder="Mobile No."
              v-model="dsCustomer.mobileNo"
            ></b-form-input>
          </div>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && showGroup(['customer_tel', 'customer_mobile'])"
          :labels="{
            customer_tel: dsCustomer.telNo,
            customer_mobile: dsCustomer.mobileNo
          }"
        />

        <b-form-group
          v-if="showGroup(['customer_on_behalf_of'])"
          :label="!showDsLabels ? 'On behalf of' : 'customer_on_behalf_of'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            :class="highlight('customer_on_behalf_of', dsCustomer.onBehalfOf)"
            v-model="dsCustomer.onBehalfOf"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="!showDsLabels ? 'Sales Agent Name' : 'sales_agent_full_name'"
          label-cols="12"
          label-cols-sm="3"
          v-if="showGroup(['sales_agent_full_name'])"
        >
          <b-form-input
            list="salesAgentNames"
            :class="highlight('sales_agent_full_name', dsCustomer.salesAgent)"
            v-model="dsCustomer.salesAgent"
          ></b-form-input>
          <datalist id="salesAgentNames">
            <option v-for="(value, index) in salesAgentNames" v-bind:key="index">
              {{ value }}
            </option>
          </datalist>
        </b-form-group>
      </b-col>

      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <div
          class="subsection-card mt-1"
          v-if="
            showGroup([
              'customer_address',
              'customer_address_line1',
              'customer_address_line2',
              'customer_address_postcode',
              'customer_address_city'
            ])
          "
        >
          <div class="header">
            <p>ADDRESS</p>
            <b-form-select
              class="w-auto"
              :disabled="!addresses.length > 0"
              :options="addresses"
              v-model="selectedAddress"
              @input="dsCustomer.address = Object.assign({}, selectedAddress)"
            />
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Line 1' : 'customer_address_line1'"
            label-cols="12"
            :label-cols-sm="!showDsLabels ? '3' : '4'"
          >
            <b-form-input
              v-if="showGroup(['customer_address', 'customer_address_line1'])"
              :class="
                highlight(
                  ['customer_address', 'customer_address_line1'],
                  dsCustomer.address.address1
                )
              "
              v-model="dsCustomer.address.address1"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Line 2' : 'customer_address_line2'"
            label-cols="12"
            :label-cols-sm="!showDsLabels ? '3' : '4'"
          >
            <b-form-input
              v-if="showGroup(['customer_address', 'customer_address_line2'])"
              :class="
                highlight(
                  ['customer_address', 'customer_address_line2'],
                  dsCustomer.address.address2
                )
              "
              v-model="dsCustomer.address.address2"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'City' : 'customer_address_city'"
            label-cols="12"
            :label-cols-sm="!showDsLabels ? '3' : '4'"
          >
            <b-form-input
              v-if="showGroup(['customer_address', 'customer_address_city'])"
              :class="
                highlight(['customer_address', 'customer_address_city'], dsCustomer.address.city)
              "
              v-model="dsCustomer.address.city"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Postcode' : 'customer_address_postcode'"
            label-cols="12"
            :label-cols-sm="!showDsLabels ? '3' : '4'"
          >
            <b-form-input
              v-if="showGroup(['customer_address', 'customer_address_postcode'])"
              :class="
                highlight(
                  ['customer_address', 'customer_address_postcode'],
                  dsCustomer.address.postcode
                )
              "
              v-model="dsCustomer.address.postcode"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="
              showDsLabels &&
              showGroup([
                'customer_address',
                'customer_address_line1',
                'customer_address_line2',
                'customer_address_postcode',
                'customer_address_city'
              ])
            "
            label="customer_address"
            label-cols="12"
            label-cols-sm="4"
          >
            <p class="m-0 mt-1">
              {{ dsCustomer.address.address1 }} {{ dsCustomer.address.address2 }}
              {{ dsCustomer.address.city }} {{ dsCustomer.address.postcode }}
            </p>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#ds-customer-fields {
  #section-header {
    margin-top: -1.5rem !important;
    border-top: none !important;
  }
}
</style>

<script>
import { dsFieldsMixin } from "@/components/docusign/mixins/dsFieldsMixin";

export default {
  name: "DSCustomerFields",
  mixins: [dsFieldsMixin],
  props: {
    customers: Array,
    persistedCustomer: Object
  },
  data() {
    return {
      customerState: null,
      dsCustomer: {
        title: null,
        firstName: null,
        lastName: null,
        onBehalfOf: null,
        address: {},
        email: null,
        telNo: null,
        mobileNo: null,
        position: null,
        salesAgent: null
      },

      //SELECTS
      selectedCustomer: null,
      selectedAddress: null,
      customerTitles: [
        { value: null, text: "Title" },
        { value: "Mr", text: "Mr" },
        { value: "Mrs", text: "Mrs" },
        { value: "Ms", text: "Ms" },
        { value: "Miss", text: "Miss" }
      ],
      salesAgentNames: [
        "Rishi Sharma",
        "Charles McAteer",
        "Amar Sharma",
        "Lucy Melville",
        "Judy Kingaby",
        "Page Wilson"
      ]
    };
  },
  created() {
    if (this.persistedCustomer && Object.keys(this.persistedCustomer).length > 0) {
      this.dsCustomer = Object.assign({}, this.persistedCustomer);
    } else if (
      !this.isEmpty(this.addresses) &&
      this.addresses.length > 0 &&
      !this.isEmpty(this.addresses[0].value)
    ) {
      this.selectedAddress = this.addresses[0].value;
      this.dsCustomer.address = Object.assign({}, this.selectedAddress);
    }
  },
  methods: {
    clearFields() {
      this.dsCustomer = {
        address: {}
      };
    },

    selectCustomer(selected) {
      this.dsCustomer.firstName = selected.forename;
      this.dsCustomer.lastName = selected.surname;
      this.dsCustomer.email = selected.email;
    },

    getCustomer() {
      this.customerState = null;

      if (!this.dsCustomer.email || (!this.dsCustomer.firstName && !this.dsCustomer.lastName)) {
        this.customerState = false;
      } else {
        return this.dsCustomer;
      }
    }
  }
};
</script>

