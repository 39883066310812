<template>
  <div class="ds-label-breakdown">
    <p v-for="(value, label) in labels" :key="label + '-given'" :class="type + '-given'">
      <b>{{ label }}</b> : {{ value }}
    </p>

    <p
      v-for="(value, label) in generatedLabels"
      :key="label + '-generated'"
      :class="type + '-generated'"
    >
      <b>{{ label }}</b> : {{ value }}
    </p>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
.ds-label-breakdown {
  border: 1px solid $color-grey-lighter2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -0.5rem;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  background-color: $color-grey-lighter3;

  display: flex;
  flex-wrap: wrap;

  p {
    margin: 0;
    font-size: 12px;
    flex-grow: 1;
    width: 50%;
  }

  .decimal-generated,
  .meterNumber-generated,
  .address-generated,
  .address-given {
    width: 100%;
  }

  .date-given,
  .decimal-given {
    display: none;
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "DsLabelBreakdown",
  props: {
    type: String, // meterNumber , date, decimal, name, checkbox
    labels: Object,
    section: String,
    label: Object
  },
  data() {
    return {
      generatedLabels: {}
    };
  },
  created() {
    this.generateFields();
  },
  beforeUpdate() {
    this.generateFields();
  },
  methods: {
    formatDateTimeCustom: FormatHelper.formatDateTimeCustom,
    isEmpty: FormatHelper.isEmpty,

    generateFields() {
      if (this.labels && Object.keys(this.labels).length > 0) {
        switch (this.type) {
          case "name":
            this.generateNameFields();
            break;
          case "address":
            this.generateAddressFields();
            break;
          default:
            for (let [label, value] of Object.entries(this.labels)) {
              this.generateDecimalFields(label, value);
              this.generateDateFields(label, value);
              this.generateCheckboxFields(label, value);
              this.generateMeterNumberFields(label, value);
            }
        }
      }
    },

    generateNameFields() {
      let firstName = !this.isEmpty(this.labels[this.section + "_first_name"])
        ? this.labels[this.section + "_first_name"] + " "
        : "";
      let lastName = !this.isEmpty(this.labels[this.section + "_last_name"])
        ? this.labels[this.section + "_last_name"]
        : "";

      let fullName = firstName + lastName;
      this.generatedLabels[this.section + "_full_name"] = fullName;
      this.generatedLabels[this.section + "_full_name_UPPER"] = fullName
        ? fullName.toUpperCase()
        : "";
    },
    generateAddressFields() {
      let fullAddress = "";
      for (let value of Object.values(this.labels)) {
        fullAddress += value ? value + " " : "";
      }

      this.generatedLabels[this.section + "_address"] = fullAddress;
    },

    generateDecimalFields(label, value) {
      if (this.type == "decimal") {
        this.generatedLabels[label + "_whole_part"] = value ? Math.trunc(value) : "";
        this.generatedLabels[label + "_fractional_part"] = value
          ? Number((value - Math.trunc(value)).toFixed(2))
              .toString()
              .substr(2)
          : "";
      }
    },
    generateDateFields(label, value) {
      if (this.type == "date") {
        let date = value ? new Date(value) : "";

        this.generatedLabels[label] = value ? this.formatDateTimeCustom(value, "DD.MM.YYYY") : "";
        this.generatedLabels[label + "_day"] = date ? date.getUTCDate() : "";
        this.generatedLabels[label + "_month"] = date ? date.getUTCMonth() + 1 : "";
        this.generatedLabels[label + "_year"] = date
          ? date.getUTCFullYear().toString().substr(-2)
          : "";
      }
    },
    generateCheckboxFields(label, value) {
      if (this.type == "checkbox") {
        this.generatedLabels[label + "_yes"] = value;
        this.generatedLabels[label + "_no"] = !value;
      }
    },
    generateMeterNumberFields(label, value) {
      if (this.type == "meterNumber") {
        this.generatedLabels[label + "_half_1"] = value ? value.substr(0, value.length / 2) : "";
        this.generatedLabels[label + "_half_2"] = value ? value.substr(value.length / 2) : "";
        this.generatedLabels[label + "_number_1"] =
          value && value.length > 20 ? value.substr(0, 2) : "";
        this.generatedLabels[label + "_number_2"] =
          value && value.length > 20 ? value.substr(2, 5) : "";
        this.generatedLabels[label + "_number_3"] =
          value && value.length > 20 ? value.substr(5, 8) : "";
        this.generatedLabels[label + "_number_4"] =
          value && value.length > 20 ? value.substr(8, 10) : "";
        this.generatedLabels[label + "_number_5"] =
          value && value.length > 20 ? value.substr(10, 14) : "";
        this.generatedLabels[label + "_number_6"] =
          value && value.length > 20 ? value.substr(14, 18) : "";
        this.generatedLabels[label + "_number_7"] =
          value && value.length > 20 ? value.substr(18, 21) : "";
      }
    }
  }
};
</script>

