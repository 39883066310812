class SharedAddresses {
  static addressMap = {};
  static addressOptions = [];

  static isValidAddress(address) {
    return address && address.address1;
  }

  // We need a unique identifier to prevent duplicate addresses
  static addressId(address) {
    return address.address1 + address.postcode;
  }

  static setAddressesFromSupplies(supplies, additionalAddresses = []) {
    let supplyAddresses = [];
    if (supplies) {
      supplyAddresses = Object.values(supplies)
        .flat()
        .map(s => s.address);
    }
    this.addAddresses(additionalAddresses.concat(supplyAddresses).flat());
  }

  static addAddresses(addresses) {
    if (addresses) {
      addresses.forEach(a => {
        if (this.isValidAddress(a)) {
          this.addressMap[this.addressId(a)] = a;
        }
      });
      this.updateAddressOptions();
    }
  }

  static updateAddressOptions() {
    this.addressOptions = Object.values(this.addressMap).map(a => {
      return { value: a, text: a.address1 };
    });
    this.addressOptions.push({
      value: null,
      text: "-- No address selected --"
    });
    Object.freeze(this.addressOptions);
  }
}
export default SharedAddresses;
