<template>
  <div class="supply-header d-flex justify-content-end align-items-center">
    <!-- Supply Name, Meter Number -->
    <div class="d-flex flex-column supply-title">
      <span class="supply-name">{{ supply.name ? supply.name : "New supply " + (index + 1) }}</span>
      <span class="text-muted small">{{ supply.meterNumber }}</span>
    </div>

    <!-- Current and Recommended Suppliers -->
    <div class="d-flex mr-auto pl-4">
      <b-badge
        v-if="currentSupplier"
        variant="secondary"
        class="supplier-badge border p-0 pr-2"
        v-b-tooltip.ds200="'Current supplier: ' + currentSupplier.text"
      >
        <span class="cur-supplier">CUR</span>
        <b-img v-if="currentSupplier.logo" :src="currentSupplier.logo" class="m-auto" />
        <span v-else class="">{{ currentSupplier.text }}</span>
      </b-badge>

      <b-badge
        v-if="recommendedSupplier"
        variant="secondary"
        class="supplier-badge border p-0 pr-2"
        v-b-tooltip.ds200="'Recommended supplier: ' + recommendedSupplier.text"
      >
        <span class="rec-supplier">REC</span>
        <b-img v-if="recommendedSupplier.logo" :src="recommendedSupplier.logo" class="m-auto" />
        <span v-else class="">{{ recommendedSupplier.text }}</span>
      </b-badge>
    </div>

    <div class="d-flex flex-row-reverse">
      <b-badge
        v-if="supply.invoiceIds"
        class="ml-2"
        :variant="supply.nosaving == 'No current or recommended' ? 'auto' : 'secondary'"
        v-b-tooltip="'Auto Published'"
        >AP</b-badge
      >
      <!-- Published Commission -->
      <b-badge
        v-if="
          !supply.noQuotes &&
            savings.REPORT[type] &&
            savings.REPORT[type].supplies[id] &&
            savings.REPORT[type].supplies[id].commission
        "
        class="ml-2"
        variant="commission"
      >
        {{ $currencySymbol }}{{ supply.commission.toFixed(2) }}
      </b-badge>

      <!-- Published Savings -->
      <b-badge
        v-if="
          !supply.noQuotes &&
            savings.REPORT[type] &&
            savings.REPORT[type].supplies[id] &&
            savings.REPORT[type].supplies[id].saving
        "
        class="ml-2"
        variant="savings"
      >
        {{ $currencySymbol }}{{ savings.REPORT[type].supplies[id].saving.toFixed(2) }}
      </b-badge>

      <!-- Indicative Savings -->
      <b-badge
        v-if="supply.noQuotes && supply.showIndicativeSavings && supply.indicativeSaving"
        class="ml-2"
        variant="indicative-savings"
      >
        Indicative savings:
        {{ $currencySymbol }}{{ supply.indicativeSaving }}
      </b-badge>

      <!-- Published Supply No quotes: On a good deal, no data etc -->
      <b-badge v-if="supply.published && supply.noQuotes" class="ml-2" variant="no-quotes">
        {{ supply.noQuotes.replace(/_/g, " ") }}
      </b-badge>

      <!-- Unpublished Flag -->
      <b-badge v-if="!supply.published" class="ml-2" variant="unpublished">
        <font-awesome-icon class="mr-1" :icon="['fas', 'exclamation-triangle']" />
        Not published
      </b-badge>

      <!-- Warnings (no deals, no current, no recommended) -->
      <b-badge v-if="!supply.noQuotes && supply.nosaving" class="ml-2" variant="issue">
        {{ supply.nosaving }}
      </b-badge>

      <!-- Contract states -->
      <b-badge
        v-if="contractState"
        class="ml-2"
        :variant="
          'contract' +
            (contractState.state == 'DEAD' || contractState.state == 'FAILED'
              ? '-danger'
              : contractState.state == 'LIVE'
              ? '-good'
              : '-progress')
        "
      >
        Contract: {{ contractState.state }}
        <span v-if="contractState.dealExists == false">- Deal removed </span>
      </b-badge>
    </div>
  </div>
</template>

<script>
import Static from "@/components/savingseditor/helpers/statichelper";

export default {
  name: "SupplyHeader",
  props: {
    id: String,
    index: Number,
    supply: Object,
    savings: Object,
    type: String,
    supplyContractStates: Object
  },
  computed: {
    contractState() {
      if (
        this.supplyContractStates &&
        Object.keys(this.supplyContractStates).length > 0 &&
        this.supplyContractStates[this.supply.supplyId] &&
        Object.keys(this.supplyContractStates[this.supply.supplyId]).length > 0
      ) {
        return this.supplyContractStates[this.supply.supplyId];
      }

      return null;
    },

    currentSupplier() {
      let supplier = null;
      if (this.supply && this.supply.currentDeal && this.supply.currentDeal.supplierId) {
        supplier = Static.supplierOptionsMap[this.supply.type].find(
          s => this.supply.currentDeal.supplierId == s.value
        );
      }
      return supplier;
    },
    recommendedSupplier() {
      let supplier = null;
      if (this.supply && this.supply.recommendedDeal && this.supply.recommendedDeal.supplierId) {
        supplier = Static.supplierOptionsMap[this.supply.type].find(
          s => this.supply.recommendedDeal.supplierId == s.value
        );
      }
      return supplier;
    }
  }
};
</script>

<style lang="scss">
.supply-header {
  @import "@/styles/common";
  @import "../styles/badges";
  @include badge-styles;
  height: 2rem;
  .indicative-savings-badge {
    background-color: darkorange !important;
  }

  .badge-contract-danger {
    color: white;
    background-color: $color-grey;
  }
  .badge-contract-good {
    color: white;
    background-color: $color-bright-green;
  }
  .badge-contract-progress {
    color: white;
    background-color: $color-blue-lighter3;
  }
  .badge-auto {
    background-color: $color-blue-darker2;
    color: white;
  }

  .badge-issue,
  .badge-unpublished,
  .indicative-savings-badge {
    padding: 6px;
    color: white;
  }
  .badge {
    display: flex;
    align-items: center;
  }
  .supplier-badge {
    padding: 0;
    height: 2rem;
    min-width: 4rem;
    &:not(:first-of-type) {
      margin-left: 0.5rem;
    }
    img {
      max-height: 1.4rem;
    }
  }
  .supply-name {
    min-width: 8rem;
  }
  .cur-supplier,
  .rec-supplier {
    color: white;
    transform: rotate(270deg);
    margin-left: -8px;
    width: 100%;
    padding: 3px;
    width: 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .cur-supplier {
    background-color: $color-blue-lighter2;
  }
  .rec-supplier {
    background-color: $color-red-light1;
  }
  .supply-title {
    min-width: 10rem;
  }
}
</style>
