<template>
  <b-modal
    id="docuSign-modal"
    v-model="isVisible"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-stacking
    :size="!generatePreview ? 'md' : 'xl'"
    @hidden="hide()"
  >
    <div id="ds-header" class="p-2" v-if="generatePreview">
      <div class="pl-2">
        <h5 class="mb-1 text-left">DOCUSIGN CONTRACT</h5>
        <b-checkbox switch size="sm" class="d-inline-block" v-model="isSoleTrader">
          Sole Trader
        </b-checkbox>
        <b-checkbox switch size="sm" class="d-inline-block ml-4" v-model="reqOnly">
          Only relevant fields
        </b-checkbox>
        <b-checkbox switch size="sm" class="d-inline-block ml-4" v-model="showDsLabels">
          Show DS Labels
        </b-checkbox>
      </div>

      <b-button class="close-button" @click="hide()" :disabled="busy">X</b-button>
    </div>
    <div v-else>
      <b-button class="close-button-rel" @click="hide()" :disabled="busy">X</b-button>
    </div>

    <!-- BEFORE SUBMIT -->
    <div v-if="generatePreview" class="mt-4 mb-4 text-left">
      <DSCustomerFields
        ref="customerFields"
        :dSFields="dSFields"
        :addresses="addresses"
        :customers="customers"
        :persistedCustomer="dsCustomer ? dsCustomer : null"
        :isST="isSoleTrader"
        :reqOnly="reqOnly"
        :showDsLabels="showDsLabels"
      />
      <DSOrganisationFields
        ref="organisationFields"
        :organisation="organisation"
        :dSFields="dSFields"
        :addresses="addresses"
        :persistedOrganisation="dsOrganisation ? dsOrganisation : null"
        :isST="isSoleTrader"
        :reqOnly="reqOnly"
        :showDsLabels="showDsLabels"
        class="mb-2"
      />
      <DSSupplyFields
        ref="supplyFields"
        :supply="supply"
        :dSFields="dSFields"
        :addresses="addresses"
        :persistedSupply="dsSupply ? dsSupply : null"
        :isST="isSoleTrader"
        :reqOnly="reqOnly"
        :showDsLabels="showDsLabels"
        :deal="deal"
        @supply-address-changed="supplyAddressChanged"
        @consumption-data-changed="consumptionDataChanged"
      />
      <DSDealFields
        ref="dealFields"
        :supply="supply"
        :dSFields="dSFields"
        :deal="deal"
        :addresses="addresses"
        :reqOnly="reqOnly"
        :persistedDeal="dsDeal ? dsDeal : null"
        :showDsLabels="showDsLabels"
        :isST="isSoleTrader"
      />
      <DSBillingFields
        ref="billingFields"
        :dSFields="dSFields"
        :supply="supply"
        :addresses="addresses"
        :bankAccounts="bankAccounts"
        :persistedBilling="dsBilling ? dsBilling : null"
        :isST="isSoleTrader"
        :dsSupply="dsSupply"
        :reqOnly="reqOnly"
        :supplyAddress="supplyAddress"
        :showDsLabels="showDsLabels"
      />
    </div>

    <!-- AFTER SUBMIT -->
    <div
      v-else
      class="mt-3 mb-4 d-flex justify-content-center align-items-center loading-status-modal"
    >
      <div class="d-inline-block">
        <font-awesome-icon v-if="busy" :icon="['fal', 'file']" class="fa-5x" />
        <font-awesome-icon v-else-if="dsSuccess" class="fa-5x" :icon="['fal', 'file-check']" />
        <font-awesome-icon v-else class="fa-5x" :icon="['fal', 'file-excel']" />
      </div>
      <h5 class="ml-3">{{ message }}</h5>
      <Busy class="mt-3" id="busy-spinner" v-if="busy" primary :size="1.7"></Busy>
    </div>

    <div class="d-inline-block" v-if="generatePreview">
      <b-button v-if="!busy" variant="outline-danger" class="mr-2" @click="hide()">Close</b-button>
      <b-button variant="outline-secondary" class="mr-2" @click="clearFields()"
        >Clear fields</b-button
      >

      <b-button
        v-if="isDeveloper"
        variant="outline-secondary"
        class="mr-2"
        @click="getTemplatesFields()"
      >
        <span v-if="!loadingTemplateFields"> Log Templates Fields </span>
        <Busy v-else :margin="0" class="px-3" :size="1" />
      </b-button>

      <b-button v-if="templateUrl" variant="outline-primary" :href="templateUrl" target="_blank"
        >Open Docusign Template</b-button
      >

      <b-button variant="primary" class="ml-2" @click="generateContract(false)"
        >Generate Docusign contract</b-button
      >
    </div>

    <div v-else-if="!busy">
      <div class="d-inline-block">
        <b-button v-if="!busy" variant="outline-danger" class="mr-2" @click="hide()"
          >Close</b-button
        >

        <b-button
          variant="outline-secondary"
          @click="generatePreview = true"
          target="_blank"
          class="mr-2"
          >Start another DS contract</b-button
        >

        <b-button
          variant="outline-primary"
          v-if="dsSuccess"
          :disabled="!previewURL"
          :href="previewURL"
          target="_blank"
          class="mr-2"
          >Preview</b-button
        >

        <b-button
          variant="primary"
          v-if="dsSuccess"
          :disabled="!editURL"
          :href="editURL"
          target="_blank"
          class="mr-2"
          >Edit</b-button
        >
      </div>

      <div class="observation-box mt-3 w-100 p-3">
        <div v-if="!loadingContract">
          <p class="m-0">
            {{ contractMessage }}
          </p>
          <b-button
            v-if="!contractId && editURL"
            variant="outline-primary"
            class="mt-2"
            @click="$emit('redirectToContractEditor', editURL)"
          >
            Take me to Contract Editor
          </b-button>
        </div>
        <Busy v-else :size="1.5" :margin="0" />
      </div>
    </div>

    <p class="mt-2 errorSpanRequ px-2 py-1" v-if="requiredFieldsError && generatePreview">
      Invalid customer name or email.
    </p>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#docuSign-modal {
  .modal-body {
    background-color: $color-grey-lighter10;
    text-align: center;
    position: relative;
    #ds-header {
      background-color: $color-font-headings;
      margin: -1rem;
      align-items: center;
      justify-content: space-between;
      display: flex;
      .close-button {
        background-color: transparent;
        border-color: transparent;
        color: white;
        font-weight: bold;
      }

      h5,
      .custom-control-label {
        color: white;
      }
    }

    .close-button-rel {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: transparent;
      border-color: transparent;
      color: $color-font-headings;
      font-weight: bold;
    }
  }

  .ds-section {
    //STYLE INPUTS
    .form-control,
    .input-group-text,
    .custom-select {
      height: calc(2rem + 2px);
      padding: 0.25rem 0.5rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-size: 0.875rem;
    }
    .custom-select {
      padding-top: 0.25rem;
    }
    .form-group,
    .custom-switch {
      margin-bottom: 0.35rem !important;
      font-size: 80%;
      font-weight: 400;
    }
    .custom-switch,
    .custom-checkbox {
      font-family: Arial;
      font-stretch: ultra-condensed;
      font-weight: bold;
    }

    //SECTION STYLE
    #section-header {
      border-top: 2px solid $color-grey;
      margin: 0 -1rem 0 -1rem;
      border-bottom: 1px solid $color-grey-lighter3;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;

      & > p {
        font-family: Arial;
        font-stretch: ultra-condensed;
        font-weight: bold;
        font-size: 16px;
      }
    }

    #section-body {
      padding: 1.5rem 0.5rem 1.5rem 0.5rem;

      //INPUTS

      .ds-checkbox-group {
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;

        .custom-checkbox,
        & > div {
          font-size: 11px;
          flex-grow: 1;
          width: 50%;
        }
      }

      .highlight {
        border-bottom: 2px solid $color-blue-main;

        &.required {
          border-bottom: 2px solid $color-pink-darker3;
        }

        &.filled {
          border-bottom: 2px solid $color-green !important;
        }
      }

      //CARDS
      .subsection-card {
        border: 1px solid $color-grey-lighter2;
        border-radius: 10px;
        padding: 0.5rem;
        margin: 0 -0.7rem 0 -0.7rem;

        & > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          p {
            font-family: Arial;
            font-stretch: ultra-condensed;
            margin-bottom: 0 !important;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }

  //AFTER SUBMIT
  .observation-box {
    border: 1px solid lightcoral;
    border-radius: 10px;
    svg {
      color: lightcoral;
    }
  }

  .errorSpanRequ {
    background-color: lightcoral;
    border-radius: 10px;
    color: white;
  }

  .loading-status-modal,
  .loading-status-modal h5 {
    color: $color-blue-main;

    #busy-spinner {
      position: absolute;
      top: 50px;
      left: 85px;
    }
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import { EventBus } from "@/components/eventbus";
import { docusign } from "@/config/reducerproperties";
import FormatHelper from "@/helper/formathelper";
import Console from "@/console";
import CognitoAuth from "@/cognito/cognitoauth";

import DSCustomerFields from "@/components/docusign/DSCustomerFields";
import DSOrganisationFields from "@/components/docusign/DSOrganisationFields";
import DSSupplyFields from "@/components/docusign/DSSupplyFields";
import DSBillingFields from "@/components/docusign/DSBillingFields";
import DSDealFields from "@/components/docusign/DSDealFields";

import Static from "@/components/savingseditor/helpers/statichelper";
import Addresses from "@/components/savingseditor/helpers/sharedaddresses";
import dSSupplierFields from "@/assets/ds_supplier_fields";

export default {
  name: "CreateDocuSignModal",
  props: {
    deal: Object,
    currentDeal: Object,
    supply: Object,
    templateId: String,
    contractId: String //'system contract' ID
  },
  components: {
    Busy,
    DSCustomerFields,
    DSOrganisationFields,
    DSBillingFields,
    DSDealFields,
    DSSupplyFields
  },
  created() {
    this.organisation = Static.organisation;
    this.customers = Static.customerOptions;
    this.addresses = Addresses.addressOptions;
    this.bankAccounts = Static.customerBankAccOptions;
    this.supplyAddress = Object.assign({}, this.supply.address);
    this.getDeveloper();
  },
  data() {
    return {
      isDeveloper: false,
      showDsLabels: false,
      loadingTemplateFields: false,
      templateFields: {},
      reqOnly: false,
      isVisible: false,
      busy: false,
      generatePreview: true,
      dsSuccess: false,
      message: "",
      contractMessage: "",
      editURL: null,
      previewURL: null,
      isSoleTrader: false,
      requiredFieldsError: false,
      loadingContract: false,

      //STATIC
      organisation: null,
      customers: [],
      addresses: [],
      bankAccounts: [],

      //DOCUSIGN OBJs
      dsDeal: {},
      dsOrganisation: {},
      dsCustomer: {},
      dsBilling: {},
      dsSupply: {},

      //CROSS DATA
      supplyAddress: {}
    };
  },
  computed: {
    templateUrl() {
      if (this.templateId) {
        return docusign.baseUrl + "prepare-template/" + this.templateId + "/add-fields";
      } else {
        return null;
      }
    },

    dSFields() {
      if (dSSupplierFields) {
        let dsTemplateName = (
          !this.isEmpty(this.deal) ? this.deal.supplierName + this.deal.type : ""
        )
          .toLowerCase()
          .replace(/[^0-9a-z]/gi, "");

        return dSSupplierFields[dsTemplateName];
      } else return {};
    }
  },
  methods: {
    isEmpty: FormatHelper.isEmpty,

    async getDeveloper() {
      this.isDeveloper = await CognitoAuth.isCurrentUserDev();
    },

    consumptionDataChanged(value) {
      if (this.$refs.dealFields) {
        this.$refs.dealFields.autocalc(value, true);
      }
    },
    supplyAddressChanged(value) {
      this.supplyAddress = Object.assign({}, value);
    },

    async getTemplatesFields() {
      this.loadingTemplateFields = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}contracts/docusign/templates/fields`
        );
        if (response.data.status === "success") {
          this.templateFields = Object.assign({}, response.data.fields);
          // eslint-disable-next-line
          Console.log(JSON.stringify(this.templateFields));
        }
      } catch {
        Console.log("There was a problem when getting the ds templates fields.");
      } finally {
        this.loadingTemplateFields = false;
      }
    },

    clearFields() {
      this.$refs.dealFields.clearFields();
      this.$refs.organisationFields.clearFields();
      this.$refs.customerFields.clearFields();
      this.$refs.billingFields.clearFields();
      this.$refs.supplyFields.clearFields();
    },

    /* Generates a docusign contract with the deal details and on success redirects to it */
    async generateContract() {
      this.dsDeal = this.$refs.dealFields.getDeal();
      this.dsOrganisation = this.$refs.organisationFields.getOrganisation();
      this.dsBilling = this.$refs.billingFields.getBilling();
      this.dsCustomer = this.$refs.customerFields.getCustomer();
      this.dsSupply = this.$refs.supplyFields.getSupply();

      let form = Object();
      form["templateId"] = this.templateId;
      form["deal"] = this.dsDeal;
      form["organisation"] = this.dsOrganisation;
      form["customer"] = this.dsCustomer;
      form["supply"] = this.dsSupply;
      form["billing"] = this.dsBilling;

      this.requiredFieldsError = false;
      this.contractMessage = null;

      if (this.dsCustomer && this.dsDeal) {
        this.busy = true;
        this.message = "Generating Docusign contract ...";
        this.generatePreview = false;

        try {
          const client = await ApiHelper.http();
          const response = await client.post(`${ApiHelper.endPoint()}contracts/docusign`, form);

          if (response.data.status === "success") {
            this.message = "Docusign contract generated";
            this.dsSuccess = true;
            if (response.data.envelopeId) {
              this.editURL =
                docusign.baseUrl + "prepare/" + response.data.envelopeId + "/add-fields";

              this.updateSystemContract();
            }

            this.previewURL = response.data.previewURL;
          } else {
            this.message = "Error generating Docusign contract";
          }
        } catch (e) {
          if (e.response.data.status == "notFound") {
            this.message = e.response.data.message;
          } else {
            this.message = "Error generating Docusign contract";
          }

          this.dsSuccess = false;
        } finally {
          this.busy = false;
        }
      } else {
        this.requiredFieldsError = true;
      }
    },

    updateSystemContract() {
      if (this.contractId) {
        this.getContract();
      } else {
        this.contractMessage =
          "We found no contract in the system to associate the generated DS contract URL with. By clicking the button below, you will be redirected to contract editor where you can create a new contract and save the URL within it.";
      }
    },

    async getContract() {
      this.loadingContract = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}contracts/${this.contractId}`);
        if (response.data.status === "success") {
          let contract = Object.assign({}, response.data.contract);
          if (contract) {
            if (!contract.docusignContractURL) {
              contract.docusignContractURL = new Array();
            }

            contract.docusignContractURL.push(this.editURL);
            if (contract.state == "NEW") {
              contract.state = "OUT";
              if (!contract.outDate) {
                contract.outDate = new Date().toISOString();
              }
            }

            this.saveContract(contract);
          }
        } else {
          this.contractMessage =
            "We tried to update the existing contract with the generated DS contract URL, but failed. Please do it manually.";
        }
      } catch {
        this.contractMessage =
          "We tried to update the existing contract with the generated DS contract URL, but failed. Please do it manually.";
      } finally {
        this.loadingContract = false;
      }
    },

    async saveContract(contract) {
      this.loadingContract = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}contracts`, {
          contract: contract
        });
        if (response.data.status === "success") {
          this.contractMessage =
            "We updated the existing contract instance with the generated DS contract URL.";
        } else {
          this.contractMessage =
            "We tried to update the existing contract with the generated DS contract URL, but failed. Please do it manually.";
        }
      } catch {
        this.contractMessage =
          "We tried to update the existing contract with the generated DS contract URL, but failed. Please do it manually.";
      } finally {
        this.loadingContract = false;
      }
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    }
  }
};
</script>