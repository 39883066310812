<template>
  <div id="magic-spinner">
    <svg
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <symbol id="s--circle">
        <circle r="10" cx="20" cy="20"></circle>
      </symbol>
      <g class="g-circles g-circles--v2">
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
        <g class="g--circle">
          <use xlink:href="#s--circle" class="u--circle" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MagicSpinner"
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
$max: 12;
$angle: 360 / $max;
$size: 120px;
$fill: dodgerblue;

svg {
  height: $size;
  width: $size;
  margin: 0 2em 2em;
  overflow: visible;
}

@function transform($item: 1) {
  $base: rotate(#{-$angle * $item}deg) translate(5px, 5px);
  @return $base;
}

.g--circle {
  transform-origin: $size/2 $size/2;

  fill: $fill;
  animation: opacity 1.2s linear infinite;

  @for $item from 1 through $max {
    &:nth-child(#{$max}n + #{$item}) {
      animation-delay: -#{$item/10}s;
      transform: transform($item) scale(0.9);
    }
  }
}
.g-circles--v2 .g--circle {
  fill-opacity: 0;
  stroke-opacity: 0;
  stroke-width: 1;
  stroke: yellowgreen;
  animation-name: opacity-stroke, colors, colors-stroke;
  .u--circle {
    //transform: scale(.6) translate(10px,10px);
    //transform-origin: 10px 10px;
    animation: transform-2 1.2s linear infinite;
  }
}

.g-circles--v2 .g--circle {
  @for $item from 1 through $max {
    &:nth-child(#{$max}n + #{$item}) .u--circle {
      animation-delay: -#{$item/10}s;
    }
  }
}
@keyframes opacity {
  3% {
    fill-opacity: 1;
  }
  75% {
    fill-opacity: 0;
  }
}

@keyframes opacity-stroke {
  10% {
    stroke-opacity: 1;
  }
  85% {
    stroke-opacity: 0;
  }
}

@keyframes colors-stroke {
  0% {
    stroke: $color-pink-main;
  }
  10% {
    stroke: $color-pink-main;
  }
  75% {
    stroke: $color-blue-main;
  }
}

@keyframes transform {
  10% {
    transform-origin: 70px 70px;
    transform: scale(0.75);
  }
}

@keyframes transform-2 {
  40% {
    transform: scale(0.85) translate(10px, 10px);
  }
  60% {
    stroke-width: 20;
  }
}
</style>
