<template>
  <div class="supply-form">
    <b-form @change="assignDraftId">
      <div class="d-flex justify-content-between align-items-end pb-2">
        <!-- Checkboxes -->
        <div class="d-flex align-items-center">
          <b-button
            variant="light"
            size="sm"
            :class="['border mr-2', { unsaved: draftId }]"
            v-b-tooltip.hover.bottom="'Save all'"
            @click="saveSupply"
          >
            <font-awesome-icon :icon="['fal', 'save']" class="mr-1" />
            Save
          </b-button>
          <b-form-checkbox class="mr-2" v-model="supply.published" @change="updateSavings()">
            Published
          </b-form-checkbox>

          <b-form-checkbox v-if="ELECTRICITY || GAS" class="mr-3" v-model="supply.paysClimateLevy">
            Include CCL
            <b-badge v-if="(ELECTRICITY || GAS) && supply.paysClimateLevy" class="ml-1">
              {{ ccl()[supply.type] }}p
            </b-badge>
          </b-form-checkbox>

          <b-form-checkbox v-if="ELECTRICITY || GAS" v-model="supply.tender" class="mr-3">
            Tender
          </b-form-checkbox>

          <b-form-checkbox
            v-if="supply.noQuotes == 'IN_CONTRACT'"
            v-model="supply.showIndicativeSavings"
            class="mr-3"
          >
            Indicative savings
            <b-badge v-if="supply.showIndicativeSavings" class="mr-1">
              {{ $currencySymbol }}{{ calculateIndicativeSavings() }}
            </b-badge>
          </b-form-checkbox>
        </div>

        <div>
          <!-- Spotlight Button -->
          <b-button
            v-if="supply.invoiceIds"
            variant="light"
            size="sm"
            class="border mr-2"
            v-b-tooltip.hover.bottom="'View supply invoices in spotlight'"
            :to="{
              name: 'spotlight',
              params: {
                organisationId: supply.organisationId
              },
              query: { exp: supply.invoiceIds }
            }"
            target="_blank"
          >
            <font-awesome-icon :icon="['fal', 'whale']" class="text-primary" />
          </b-button>
          <!-- Delete Button -->
          <b-button
            variant="light"
            size="sm"
            class="border mr-2"
            v-b-tooltip.hover.bottom="'Delete supply and deals'"
            @click="supply.createdDate ? (showDeleteModal = true) : deleteSupply()"
          >
            <font-awesome-icon :icon="['fal', 'trash-alt']" />
          </b-button>
        </div>
      </div>

      <!-- Form Inputs -->
      <div class="inline-inputs">
        <b-form-group label="Name">
          <b-form-input required v-model="supply.name" placeholder="Enter name"></b-form-input>
        </b-form-group>

        <b-form-group label="Address">
          <supply-form-address :address="supply.address" @change="onAddressChange" />
        </b-form-group>

        <!-- Electricity -->
        <b-form-group
          v-if="ELECTRICITY || GAS || WATER"
          :label="ELECTRICITY ? 'MPAN' : GAS ? 'MPRN' : 'SPID'"
        >
          <b-form-input v-model="supply.meterNumber" class="meter-number-width" />
        </b-form-group>
        <b-form-group v-if="GAS || ELECTRICITY" label="Day Consumption">
          <b-form-input v-model.number="supply.dayConsumption" type="number" min="0" />
        </b-form-group>

        <b-form-group v-if="ELECTRICITY" label="Night Consumption">
          <b-form-input v-model.number="supply.nightConsumption" type="number" min="0" />
        </b-form-group>
        <b-form-group v-if="ELECTRICITY" label="EW Consumption">
          <b-form-input v-model.number="supply.ewConsumption" type="number" min="0" />
        </b-form-group>
        <b-form-group v-if="ELECTRICITY" label="Winter Consumption">
          <b-form-input v-model.number="supply.winterConsumption" type="number" min="0" />
        </b-form-group>

        <!-- Water -->
        <b-form-group v-if="WATER" label="Consumption">
          <b-form-input v-model.number="supply.consumption" type="number" min="0" />
        </b-form-group>

        <!-- Custom -->
        <b-form-group label="Custom Type Name" v-if="CUSTOM">
          <b-form-input
            v-model="supply.customTypeName"
            placeholder="Enter custom type name"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="No Quotes">
          <b-form-select
            class="no-quotes-width"
            label="No Quotes"
            v-model="supply.noQuotes"
            :options="noQuoteOptions"
          />
        </b-form-group>

        <b-form-group label="Header">
          <b-form-textarea v-model="supply.header"></b-form-textarea>
        </b-form-group>

        <b-form-group label="Footer">
          <b-form-textarea v-model="supply.footer"></b-form-textarea>
        </b-form-group>

        <b-form-group label="Private Note">
          <b-form-textarea v-model="supply.privateNote"></b-form-textarea>
        </b-form-group>
      </div>
    </b-form>
    <!-- Modals -->
    <b-modal hide-header v-model="showDeleteModal" @ok="deleteSupply">
      <p class="my-1">
        Are you sure you want to delete this supply? This action is irreversible and all the deals
        associated with supply will also be deleted.
      </p>
    </b-modal>
  </div>
</template>

<script>
import SupplyTypes from "@/helper/supplytypehelper";
import SupplyFormAddress from "./SupplyFormAddress";
import IDHelper from "@/helper/idhelper";
import GlobalEvents from "@/helper/globalevents";
import StaticRates from "@/components/savingseditor/helpers/staticRates";

export default {
  name: "SupplyForm",
  components: { SupplyFormAddress },
  model: {
    prop: "supply",
    event: "change"
  },
  props: {
    supply: Object,
    deals: Array,
    // Explicitly pass in supply's draftId to triger component refresh on change
    // We need this for displaying save button state accurate (unsaved changes)
    draftId: String
  },
  data() {
    return {
      noQuoteOptions: [
        { text: "Not applicable", value: null },
        { text: "On a good deal", value: "ON_GOOD_DEAL" },
        { text: "Not enough data", value: "NOT_ENOUGH_DATA" },
        { text: "In contract", value: "IN_CONTRACT" }
      ],
      showDeleteModal: false
    };
  },
  created() {
    SupplyTypes.supplyTypes().forEach((type) => (this[type] = type == this.supply.type));
  },
  mounted() {
    if (!this.supply.address) this.supply.address = null;
  },
  updated() {
    // Add ccl rate to supply object
    // This is a transient field so we need to add it every time supply gets updated
    if (this.ELECTRICITY || this.GAS) {
      this.supply.cclRate = this.ccl()[this.supply.type];
    }
  },
  methods: {
    ccl: () => StaticRates.cclRates,
    onChange(value) {
      this.$emit("change", value);
    },

    assignDraftId() {
      if (!this.supply.draftId) {
        this.$set(this.supply, "draftId", IDHelper.generateUuid());
        this.onChange(this.supply);
      }
    },

    saveSupply() {
      GlobalEvents.emit("savingsEditorSaveSupplyDeals", { supplyId: this.supply.supplyId });
    },

    deleteSupply() {
      GlobalEvents.emit("savingsEditorDeleteSupply", this.supply);
    },

    generateSupplyName() {
      if (this.supply.address && !this.supply.name && this.supply.address.address1) {
        this.supply.name = this.supply.address.address1;
      }
      this.onChange();
    },

    onAddressChange(val) {
      this.$set(this.supply, "address", val);
      this.assignDraftId();
      this.generateSupplyName();
      this.$forceUpdate();
    },

    calculateIndicativeSavings() {
      if (this.supply.showIndicativeSavings) {
        let currentDeal = this.deals.find((d) => d.state == "CURRENT" && d.annualCost);
        let recommendedDeal = this.deals.find((d) => d.recommended && d.annualCost);

        if (currentDeal && recommendedDeal) {
          this.supply.indicativeSaving = Math.round(
            currentDeal.annualCost - recommendedDeal.annualCost
          );
          return this.supply.indicativeSaving;
        } else return "";
      } else return "";
    },

    updateSavings() {
      GlobalEvents.emit("savingsEditorUpdateSavings");
    }
  }
};
</script>

<style lang="scss">
.supply-form {
  @import "../styles/form";
  box-shadow: 0 1px 3px $color-grey-lighter2, 0 1px 2px $color-grey-lighter2;
  border: 1px solid $color-grey-lighter3;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: $color-grey-lighter3;
  padding-left: 1rem;
  padding-top: 0.3rem;
  margin-bottom: 1.5rem;
  .btn.unsaved {
    color: coral;
  }

  .meter-number-width {
    min-width: 12rem;
  }

  .no-quotes-width {
    min-width: 10rem;
  }
}
</style>
