<template>
  <div id="savings-editor-navbar">
    <h5 v-if="organisation.name.length > 50" class="sidebar-brand">
      {{ organisation.name.substring(0, 50) }}
    </h5>
    <h5 v-else class="sidebar-brand">
      {{ organisation.name }}
    </h5>
    <h5 class="sidebar-header">Total</h5>
    <div class="sidebar-total">
      <div>
        <b-badge class="p-2" variant="savings">
          {{
            savings["TOTAL"]
              ? "Savings: " + $currencySymbol + savings["TOTAL"].toFixed(0)
              : "Savings: " + $currencySymbol + "0"
          }}
        </b-badge>
      </div>
      <div>
        <b-badge class="mt-1 p-2" variant="commission">
          {{
            savings["COMMISSION"]
              ? "Commission: " + $currencySymbol + savings["COMMISSION"].toFixed(0)
              : "Commission: " + $currencySymbol + "0"
          }}
        </b-badge>
      </div>
      <b-button
        class="mt-1 border"
        variant="light"
        block
        size="sm"
        @click="$emit('preview-savings')"
      >
        <font-awesome-icon :icon="['fal', 'unicorn']" class="mr-1" />
        Preview Savings
      </b-button>
    </div>
    <h5 class="sidebar-header pt-2 mb-1">Categories</h5>
    <div
      :class="'sidebar-item' + (supplyType == type ? ' active' : '')"
      v-for="type in supplyTypes()"
      :key="type"
      @click="$emit('update:supply-type', type)"
    >
      <div class="row mr-0">
        <div class="sidebar-item-logo col-2">
          <SavingsIcon :supplyType="type" style="color: inherit" disabled />
        </div>
        <div class="sidebar-item-name col-9">
          <span>{{ type == "CARD_PAYMENTS" ? "Card Paym" : supplyTypesDisplay(type) }}</span>
          <b-badge
            v-if="supplies[type] && supplies[type].length > 0"
            class="ml-1 mb-1"
            pill
            v-b-tooltip.hover.noninteractive.top="
              supplies[type].length == 1 ? '1 supply' : supplies[type].length + ' supplies'
            "
            >{{ supplies[type].length }}
          </b-badge>
        </div>
      </div>
      <div class="sidebar-item-info">
        <!-- Category Savings Badge -->
        <b-badge
          v-if="savings.REPORT[type] && savings.REPORT[type].savings"
          v-b-tooltip.hover.noninteractive.bottom="'Savings'"
          variant="savings"
          >{{ $currencySymbol }}{{ savings["REPORT"][type].savings.toFixed(0) }}</b-badge
        >
        <!-- Category Commission Badge -->
        <b-badge
          variant="commission"
          v-if="savings.REPORT[type] && savings.REPORT[type].commission"
          v-b-tooltip.hover.noninteractive.bottom="'Commission'"
          >{{ $currencySymbol }}{{ savings["REPORT"][type].commission.toFixed(0) }}</b-badge
        >
        <!-- Category Issues Badge -->
        <b-badge
          variant="issue"
          v-if="savings.NOTREPORT[type] && savings.NOTREPORT[type].issues > 0"
          v-b-tooltip.hover.noninteractive.bottom="
            savings.NOTREPORT[type].issues == 1
              ? '1 issue found'
              : savings.NOTREPORT[type].issues + ' issues found'
          "
        >
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
          {{ savings.NOTREPORT[type].issues }}
        </b-badge>
        <!-- Category Unpublished Badge -->
        <b-badge
          v-b-tooltip.hover.noninteractive.bottom="
            savings.NOTREPORT[type].unpublished == 1
              ? '1 unpublished supply'
              : savings.NOTREPORT[type].unpublished + ' unpublished supplies'
          "
          variant="unpublished"
          v-if="savings.NOTREPORT[type] && savings.NOTREPORT[type].unpublished > 0"
        >
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
          {{ savings.NOTREPORT[type].unpublished }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import SupplyTypes from "@/helper/supplytypehelper";
import SavingsIcon from "@/components/SavingsIcon";

export default {
  name: "Navbar",
  components: { SavingsIcon },
  props: {
    supplyType: String,
    savings: Object,
    organisation: Object,
    supplies: Object
  },
  methods: {
    supplyTypes: SupplyTypes.supplyTypes,
    supplyTypesDisplay: SupplyTypes.toDisplay
  }
};
</script>

<style lang="scss">
#savings-editor-navbar {
  @import "@/styles/common";
  @import "./styles/badges";
  position: fixed;
  background-color: $color-grey-lighter4;
  box-shadow: 0 1px 3px $color-grey-lighter2, 0 1px 2px $color-font-headings;
  width: 11rem;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  padding: 0.5rem;
  border-right: 1px solid $color-grey-lighter3;
  display: flex;
  flex-flow: column;

  .sidebar-brand {
    color: $color-font-headings;
    margin-top: 1.75rem;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0rem;
  }
  .sidebar-header {
    color: $color-font-headings !important;
    font-size: 0.85rem;
    text-transform: uppercase;
    margin-top: 0.75rem;
    letter-spacing: 0.45px;
  }
  .sidebar-total {
    display: block;
    .badge {
      width: 100%;
      font-size: 0.75rem;
    }
    .btn {
      font-size: 0.8rem;
      border-radius: 0.25rem;
    }
  }
  .sidebar-item {
    margin-top: 0.25rem;
    position: relative;
    font-size: 0.9rem;
    color: $color-font-headings;
    letter-spacing: 0.25px;
    width: 100%;
    padding: 0.5rem;
    border-radius: 6px;
    &:hover,
    &.active {
      background-color: $color-grey-lighter2;
    }
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: $color-primary-blue-dark2 !important;
      .sidebar-item-logo {
        color: $color-primary-blue-dark2 !important;
      }
      &:hover {
        cursor: unset;
      }
    }
    .sidebar-item-name {
      white-space: pre;
      .badge {
        background-color: $color-blue-lighter5;
      }
    }
    .sidebar-item-logo {
      color: $color-font-headings;
      font-size: 0.95rem;
    }
    .sidebar-item-info {
      margin-right: -20px;
      .badge:not(:first-child) {
        margin-left: 0.25rem;
      }
    }
  }
}
</style>
