<template>
  <div :class="['group list-input', { empty: !bins.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{ bins.length ? "BINS" : "ADD A NEW BIN" }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new bin'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- Bins -->
    <div v-for="(bin, i) in bins" :key="i" class="list-input-item">
      <b-form-group label="Size">
        <b-form-select v-model="bin.binSize" :options="binSizeOptions" />
      </b-form-group>

      <b-form-group label="Waste Type">
        <b-form-select v-model="bin.wasteType" :options="wasteTypeOptions" />
      </b-form-group>

      <b-form-group label="Weekly Lifts No.">
        <b-form-input v-model="bin.liftsCount" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Rental Charge">
        <b-form-input v-model="bin.binRentalCharge" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Lift Cost">
        <b-form-input v-model="bin.binLiftCost" type="number" min="0" />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(bin)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != bins.length - 1" class="divider" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BinsInput",
  props: {
    bins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      binSizeOptions: [
        "1100 l",
        "660 l",
        "360 l",
        "240 l",
        "120 l",
        "Bag",
        "16 yard",
        "14 yard",
        "12 yard",
        "8 yard"
      ],
      wasteTypeOptions: [
        "General Waste",
        "Mixed Recycling",
        "Glass",
        "Cardboard",
        "Food",
        "Offensive Waste",
        "Confidential"
      ]
    };
  },
  methods: {
    onChange() {
      this.$emit("change", this.bins);
    },

    add(bin) {
      if (!bin) {
        bin = {
          binSize: null,
          wasteType: null,
          liftsCount: null,
          binRentalCharge: null,
          binLiftCost: null
        };
      } else {
        // Create a new object, we don't want a reference
        bin = Object.assign({}, bin);
      }
      this.bins.push(bin);
      this.onChange();
    },

    remove(index) {
      this.$delete(this.bins, index);
      this.onChange();
    }
  }
};
</script>