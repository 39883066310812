<template>
  <b-input-group-prepend class="ds-input-tooltip">
    <b-button
      class="input-info"
      size="sm"
      variant="outline-secondary"
      v-b-tooltip.noninteractive.hover="text"
    >
      <font-awesome-icon v-if="icon == 'info'" :icon="['fas', 'info-circle']" class="mx-auto" />
      <font-awesome-icon v-else :icon="['fas', 'calculator']" class="mx-auto" />
    </b-button>
  </b-input-group-prepend>
</template>

<style lang="scss">
@import "@/styles/common.scss";
.ds-input-tooltip {
  & > button {
    background-color: $color-grey-lighter6;
    border-color: $color-grey-lighter2;
    color: $color-grey-lighter1;
    &:hover {
      cursor: initial !important;
    }
  }
}
</style>

<script>
export default {
  name: "DsInputTooltip",
  props: {
    icon: String,
    text: String
  }
};
</script>

