<template>
  <div :class="['group list-input', { empty: !lineRentals.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small">{{
        lineRentals.length ? "LINE RENTALS" : "ADD LINE RENTALS"
      }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new line rental'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- Line Rentals -->
    <div v-for="(lineRental, i) in lineRentals" :key="i" class="list-input-item">
      <b-form-group label="Count">
        <b-form-input v-model="lineRental.count" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Charge">
        <b-form-input v-model="lineRental.charge" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Description">
        <b-form-input v-model="lineRental.description" />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(lineRental)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != lineRentals.length - 1" class="divider" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LineRentalsInput",
  model: {
    prop: "lineRentals",
    event: "change"
  },
  props: {
    lineRentals: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onChange() {
      this.$emit("change", this.lineRentals);
    },

    add(lineRental) {
      if (!lineRental) {
        lineRental = {
          description: null,
          charge: null,
          count: null
        };
      } else {
        // Create a new object, we don't want a reference
        lineRental = Object.assign({}, lineRental);
      }
      this.lineRentals.push(lineRental);
      this.onChange();
    },

    remove(index) {
      this.$delete(this.lineRentals, index);
      this.onChange();
    }
  }
};
</script>
