export default
   {
      "565c06c5-ee10-4819-81dd-484af101c6e4": {
         "supplierName": "ASH Waste",
         "bins":
            [
               {
                  "wasteType": "General Waste",
                  "binSize": "1100 l",
                  "binLiftCost": 12.00,
                  "binRentalCharge": 1.52

               },
               {
                  "wasteType": "General Waste",
                  "binSize": "660 l",
                  "binLiftCost": 9.00,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "1100 l",
                  "binLiftCost": 8.00,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "660 l",
                  "binLiftCost": 7.00,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "1100 l",
                  "binLiftCost": 6.00,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Glass",
                  "binSize": "360 l",
                  "binLiftCost": 6.00,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Glass",
                  "binSize": "240 l",
                  "binLiftCost": 5.00,
                  "binRentalCharge": 1.52
               },
            ]
      },
      "2336c12d-92f4-40ca-87ce-d3b2c2b81987": {
         "supplierName": "NWR",
         "bins":
            [
               {
                  "wasteType": "General Waste",
                  "binSize": "1100 l",
                  "binLiftCost": 14.40,
                  "binRentalCharge": 1.52

               },
               {
                  "wasteType": "General Waste",
                  "binSize": "660 l",
                  "binLiftCost": 10.80,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "1100 l",
                  "binLiftCost": 9.60,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "660 l",
                  "binLiftCost": 8.40,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "1100 l",
                  "binLiftCost": 7.20,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Glass",
                  "binSize": "360 l",
                  "binLiftCost": 7.20,
                  "binRentalCharge": 1.52
               },
               {
                  "wasteType": "Glass",
                  "binSize": "240 l",
                  "binLiftCost": 6.00,
                  "binRentalCharge": 1.52
               },
            ]
      },
      "6bad1b28-fe40-49aa-8393-e0b8fe6c25e5": {
         "supplierName": "Biffa",
         "bins":
            [
               {
                  "wasteType": "General Waste",
                  "binSize": "1100 l",
                  "binLiftCost": 15.15
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "660 l",
                  "binLiftCost": 12.95
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "360 l",
                  "binLiftCost": 9.25
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "240 l",
                  "binLiftCost": 7.75
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "Bag",
                  "binLiftCost": 1.8
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "1100 l",
                  "binLiftCost": 11.35
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "660 l",
                  "binLiftCost": 9.25
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "360 l",
                  "binLiftCost": 7.7
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "240 l",
                  "binLiftCost": 7.3
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "Bag",
                  "binLiftCost": 1.28
               },
               {
                  "wasteType": "Glass",
                  "binSize": "240 l",
                  "binLiftCost": 6
               },
               {
                  "wasteType": "Glass",
                  "binSize": "120 l",
                  "binLiftCost": 5.5
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "1100 l",
                  "binLiftCost": 10.35
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "660 l",
                  "binLiftCost": 8.45
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "360 l",
                  "binLiftCost": 5.6
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "240 l",
                  "binLiftCost": 4.95
               },
               {
                  "wasteType": "Food",
                  "binSize": "240 l",
                  "binLiftCost": 10.3
               },
               {
                  "wasteType": "Food",
                  "binSize": "120 l",
                  "binLiftCost": 8
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "16 yard",
                  "binLiftCost": 138
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "14 yard",
                  "binLiftCost": 125
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "12 yard",
                  "binLiftCost": 115
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "8 yard",
                  "binLiftCost": 94
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "16 yard",
                  "binLiftCost": 90.5
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "14 yard",
                  "binLiftCost": 85
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "12 yard",
                  "binLiftCost": 77.75
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "8 yard",
                  "binLiftCost": 67.5
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "16 yard",
                  "binLiftCost": 90.5
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "14 yard",
                  "binLiftCost": 85
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "12 yard",
                  "binLiftCost": 77.75
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "8 yard",
                  "binLiftCost": 67.5
               }
            ]
      },
      "01091d78-7963-44c0-9db3-4018e4afb83c": {
         "supplierName": "KP Waste",
         "bins":
            [
               {
                  "wasteType": "General Waste",
                  "binSize": "1100 l",
                  "binLiftCost": 13.5
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "660 l",
                  "binLiftCost": 9
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "360 l",
                  "binLiftCost": 6
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "240 l",
                  "binLiftCost": 5.5
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "Bag",
                  "binLiftCost": 1.25
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "1100 l",
                  "binLiftCost": 8.5
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "660 l",
                  "binLiftCost": 6.5
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "360 l",
                  "binLiftCost": 5.25
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "240 l",
                  "binLiftCost": 5
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "Bag",
                  "binLiftCost": 1.05
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "1100 l",
                  "binLiftCost": 8.5
               },
               {
                  "wasteType": "Food",
                  "binSize": "240 l",
                  "binLiftCost": 7
               },
               {
                  "wasteType": "Glass",
                  "binSize": "240 l",
                  "binLiftCost": 3.5
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "16 yard",
                  "binLiftCost": 100
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "12 yard",
                  "binLiftCost": 80
               },
               {
                  "wasteType": "General Waste",
                  "binSize": "8 yard",
                  "binLiftCost": 60
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "16 yard",
                  "binLiftCost": 80
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "12 yard",
                  "binLiftCost": 70
               },
               {
                  "wasteType": "Mixed Recycling",
                  "binSize": "8 yard",
                  "binLiftCost": 50
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "16 yard",
                  "binLiftCost": 80
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "12 yard",
                  "binLiftCost": 70
               },
               {
                  "wasteType": "Cardboard",
                  "binSize": "8 yard",
                  "binLiftCost": 50
               }
            ]
      }
   }