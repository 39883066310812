import Console from "@/console";
import { EventBus } from "@/components/eventbus";

class GlobalEvents {
  static eventsMap = {
    savingsEditorSaveSupplyDeals: "savings-editor-save-supply-deals",
    savingsEditorFocusOnNextForm: "savings-editor-focus-on-next-form",
    savingsEditorCopyDeal: "savings-editor-copy-deal",
    savingsEditorMoveDeal: "savings-editor-move-deal",
    savingsEditorRecommendCheapestOffer: "savings-editor-recommend-cheapest-offer",
    savingsEditorRecommendHighestCommsOffer: "savingsEditorRecommendHighestCommsOffer",
    savingsEditorPriceOffers: "savings-editor-price-offers",
    savingsEditorUnrecommendDeal: "savings-editor-unrecommend-deal",
    savingsEditorDeleteDeals: "savings-editor-delete-deals",
    savingsEditorUndoDeal: "savings-editor-undo-deal",
    savingsEditorDeleteSupply: "savings-editor-delete-supply",
    savingsEditorUpdateSavings: "savings-editor-update-savings",
    savingsEditorUpdateContractStatus: "savings-editor-update-contract-status"
  };

  static eventExists(name) {
    let exists = Object.prototype.hasOwnProperty.call(this.eventsMap, name);
    if (!exists) {
      Console.error("Could not find a global event with name:", name);
    }
    return exists;
  }

  static getEvent(name) {
    if (this.eventExists(name)) {
      return this.eventsMap[name];
    }
  }

  static emit(name, ...args) {
    if (this.eventExists(name)) {
      Console.log("Emitting global event:", name);
      EventBus.$emit(this.eventsMap[name], ...args);
    }
  }

  static on(name, callback) {
    if (this.eventExists(name)) {
      Console.log("Listening to global event:", name);
      EventBus.$on(this.eventsMap[name], callback);
    }
  }

  static off(name) {
    if (this.eventExists(name)) {
      EventBus.$off(this.eventsMap[name]);
    }
  }
}
export default GlobalEvents;
