<template>
  <b-input-group size="sm">
    <b-form-input :value="calculatedContractTerm" @input="onChange" type="number" min="0" />
    <b-input-group-append>
      <b-button variant="primary" @click="autoCalculate = !autoCalculate">
        {{ autoCalculate ? "y" : "m" }}
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: "ContractTermInput",
  model: {
    prop: "contractTerm",
    event: "change"
  },
  props: {
    contractTerm: Number
  },
  data() {
    return {
      autoCalculate: true
    };
  },
  computed: {
    calculatedContractTerm() {
      if (this.autoCalculate && this.contractTerm) {
        return this.contractTerm / 12;
      } else {
        return this.contractTerm;
      }
    }
  },
  mounted() {
    this.checkIfAutoCalculated(this.contractTerm);
  },
  methods: {
    onChange(value) {
      if (this.autoCalculate) {
        this.$emit("change", parseInt(value * 12));
      } else {
        this.$emit("change", parseInt(value));
      }
    },
    checkIfAutoCalculated(contractTerm) {
      if (contractTerm && contractTerm % 12 != 0) {
        this.autoCalculate = false;
      }
    }
  }
};
</script>
