<template>
  <b-modal
    id="energy-pricing-modal"
    :visible="show"
    hide-header
    :hide-footer="busy || !selectedOffers.length"
    :size="busy ? 'sm' : cantPriceMessage || pricingErrorMessage ? 'md' : 'xxl'"
    :ok-title="replaceDeals ? 'Replace deals' : 'Add deals'"
    @ok.prevent="submitOffers"
    @show="priceOffers"
    @change="$emit('update:show', $event)"
  >
    <Busy v-if="busy" primary text="Pricing in progress..." />

    <!-- Warning and error alerts -->
    <b-alert v-else-if="cantPriceMessage" show variant="warning" class="mb-0">
      <font-awesome-icon :icon="['fal', 'exclamation-circle']" class="mr-1" />
      Can't price this supply because {{ cantPriceMessage }}.
    </b-alert>
    <b-alert v-else-if="pricingErrorMessage" show variant="danger" class="mb-0">
      <font-awesome-icon :icon="['fal', 'exclamation-circle']" class="mr-1" />
      Pricing error: <span v-html="pricingErrorMessage"></span>
    </b-alert>

    <div v-else-if="prices.length">
      <b-row>
        <b-col cols="10">
          <!-- Current deal info badges -->
          <div class="d-flex mb-1">
            <b-badge v-if="supply.type == 'ELECTRICITY'">MPAN: {{ supply.meterNumber }}</b-badge>
            <b-badge v-else>{{ supply.address.postcode }}</b-badge>
            <b-badge v-if="supply.type == 'GAS' && prices.length" class="ml-2"
              >Exit Zone: {{ prices.filter(o => o.exitZone)[0].exitZone }}</b-badge
            >
            <b-badge class="ml-2">AQ: {{ totalConsumption }} kWh</b-badge>
            <b-badge class="ml-2">
              New contract start: {{ new Date(newContractStart).toLocaleDateString("en-GB") }}
            </b-badge>
            <b-badge v-if="deal.annualCost" class="ml-2">
              Annual cost: {{ $currencySymbol }}{{ deal.annualCost }}
            </b-badge>
          </div>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-checkbox v-model="showWindowClosed" switch>Show Window Closed</b-checkbox>
        </b-col>
      </b-row>

      <!-- Offers -->
      <p v-if="!offers">No offers found</p>
      <b-overlay v-else :show="deletingOffers" variant="light" spinner-variant="primary">
        <b-row class="mt-3">
          <b-col cols="3" class="mt-2 d-flex selected-header">
            <h5>Selected offers ({{ selectedOffers.length }})</h5>
            <div v-if="dealsCount" class="d-flex ml-auto pt-1">
              <p class="mb-0 mr-2">Add</p>
              <b-checkbox v-model="replaceDeals" switch></b-checkbox>
              <p class="mb-0">Replace</p>
            </div>
          </b-col>
          <!-- Pricing options -->
          <b-col cols="9" class="pr-4">
            <b-row class="pricing-options mb-2">
              <b-col v-if="supply.type == 'ELECTRICITY'" cols="12" class="pl-0 mt-2">
                <multiselect
                  v-model="selectedMeterTypes"
                  :options="meterTypes"
                  multiple
                  taggable
                  :custom-label="formatCobold"
                />
              </b-col>
              <b-col cols="2" class="pl-0">
                <b-select v-model="selectedSupplier" :options="availableSuppliers" />
              </b-col>
              <b-col cols="5" offset="1">
                <span class="pr-2 pb-1 ml-5">Term:</span>
                <b-form-checkbox-group
                  v-model="selectedTerms"
                  :options="termOptions"
                  buttons
                  button-variant="light"
                  @input="selectedTerms = [$event[$event.length - 1]]"
                  @change="selectedTerms = [$event[$event.length - 1]]"
                />
              </b-col>
              <b-col cols="3" class="align-items-center flex-column">
                <span>Global Uplift ({{ globalCommission }})</span>
                <b-input
                  v-model="globalCommission"
                  type="range"
                  :min="0"
                  :max="3"
                  :step="0.1"
                  @input="onGlobalCommissionChange"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Selected offers -->
          <b-col cols="3" class="selected-offers-col">
            <div class="selected-offers">
              <div v-for="(offer, i) in selectedOffers" :key="i" class="selected-offer-card">
                <div class="offer-section">
                  <img v-if="offer.logo" :src="offer.logo" />
                  <font-awesome-icon
                    :icon="['fas', 'plus']"
                    class="text-muted unselect-icon mt-1"
                    @click="offer.selected = false"
                  />
                </div>
                <div class="offer-section">
                  <strong>Term</strong>
                  {{ offer.contractTerm / 12 }} Year{{ offer.contractTerm == 12 ? "" : "s" }} <br />
                </div>
                <div class="offer-section">
                  <strong>Annual Cost</strong> {{ $currencySymbol }}{{ offer.annualCostComm }}
                </div>
                <div class="offer-section">
                  <strong>Savings</strong> {{ $currencySymbol }}{{ offer.saving }}
                </div>
                <div class="offer-section">
                  <strong>Commission</strong> {{ $currencySymbol }}{{ offer.consumptionCommission }}
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="9" class="pr-4">
            <b-row class="offers-header">
              <b-col>Supplier</b-col>
              <b-col>Term</b-col>
              <b-col>Rates</b-col>
              <b-col>Annual Cost</b-col>
              <b-col>Savings</b-col>
              <b-col>Commission</b-col>
              <b-col>Select</b-col>
            </b-row>
            <b-row
              v-for="(offer, i) in offers"
              :key="i"
              class="offer-card pointer"
              :selected="offer.selected"
              @click="offer.selected = !offer.selected"
            >
              <b-badge
                v-if="offer.greenTariff"
                v-b-tooltip="'Green tariff'"
                variant="success"
                class="ml-1 green-tariff"
                ><font-awesome-icon :icon="['fas', 'seedling']"
              /></b-badge>
              <b-col
                v-b-tooltip="'Last updated: ' + new Date(offer.created).toLocaleDateString('en-GB')"
              >
                <img v-if="offer.logo" :src="offer.logo" class="mb-1" />
                <div class="small text-muted">
                  {{ formatCobold(offer.meterType) }}
                  <span v-if="offer.productType"> {{ offer.productType }}</span>
                  <span v-if="offer.salesType"> {{ offer.salesType }}</span>
                  <span v-if="offer.standingChargeType"> {{ offer.standingChargeType }}</span>
                </div>
              </b-col>

              <b-col v-b-tooltip="contractDatesDescription(offer)">
                <span>
                  {{ offer.contractTerm / 12 }} Year{{ offer.contractTerm == 12 ? "" : "s" }}
                </span>
                <b-badge v-if="offer.windowClosed" variant="danger" pill>
                  <font-awesome-icon
                    class="mr-2"
                    :icon="['fas', 'exclamation-triangle']"
                    pill
                  />Window Closed</b-badge
                >
                <b-badge v-if="offer.windowOpenLater" variant="warning" class="mt-1 d-block" pill>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon class="ml-2" :icon="['fas', 'exclamation-triangle']" />
                    <div class="ml-3">
                      Window open<br />
                      {{ new Date(offer.contractStart).toLocaleDateString("en-GB") }}
                    </div>
                  </div>
                </b-badge>
              </b-col>

              <b-col>
                <div class="offer-field"><strong>SC: </strong>{{ offer.standingCharge }}</div>
                <div class="offer-field" v-if="offer.dayUnitRate || offer.unitRate">
                  <strong>Day: </strong>
                  {{ offer.unitRateComm ? offer.unitRateComm : offer.dayUnitRateComm }}
                </div>
                <div class="offer-field" v-if="offer.nightUnitRate">
                  <strong>Night: </strong> {{ offer.nightUnitRateComm }}
                </div>
                <div class="offer-field" v-if="offer.ewUnitRate">
                  <strong>EW: </strong> {{ offer.ewUnitRateComm }}
                </div>
                <div class="offer-field" v-if="offer.offPeakUnitRate">
                  <strong>Off Peak: </strong> {{ offer.offPeakUnitRateComm }}
                </div>
                <div class="offer-field" v-if="offer.nonWeekdayUnitRate">
                  <strong>Non Weekday: </strong> {{ offer.nonWeekdayUnitRateComm }}
                </div>
                <div class="offer-field" v-if="offer.fitsCharge">
                  <strong>FiTs: </strong> {{ offer.fitsCharge }}
                </div>
              </b-col>

              <b-col>
                {{ $currencySymbol }}{{ offer.annualCostComm }}
                <div v-if="offer.meterType == 'WEEKDAY'" class="mt-1">
                  <b-badge pill>
                    <font-awesome-icon class="mr-1" :icon="['fas', 'exclamation-triangle']" />
                    Missing weekend AQ
                  </b-badge>
                </div>
              </b-col>

              <b-col>{{ $currencySymbol }}{{ offer.saving }}</b-col>

              <b-col @click.stop>
                {{ offer.commissionLevel }}p
                <b-input
                  v-model.number="offer.commissionLevel"
                  type="range"
                  :min="0"
                  :max="offer.maxUplift"
                  :step="0.1"
                />
                {{ $currencySymbol }}{{ offer.consumptionCommission }}
              </b-col>

              <b-col>
                <b-checkbox :checked="offer.selected" class="ml-3 pointer-events-none" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import Busy from "@/components/Busy";
import axios from "axios";
import { pricing } from "@/config/reducerproperties";
import Static from "@/components/savingseditor/helpers/statichelper";
import FormatHelper from "@/helper/formathelper";
import StaticRates from "@/components/savingseditor/helpers/staticRates";

export default {
  name: "EnergyPricingModal",
  components: { Busy },
  props: {
    deal: {
      type: Object,
      required: true
    },
    supply: {
      type: Object,
      required: true
    },
    show: Boolean,
    dealsCount: Number
  },
  data() {
    return {
      busy: false,
      replaceDeals: false,
      deletingOffers: false,

      meterTypes: [
        "SINGLE_RATE",
        "OFF_PEAK",
        "DAY_NIGHT",
        "EVENING_WEEKEND",
        "EVENING_WEEKEND_NIGHT",
        "WEEKDAY"
      ],
      selectedMeterTypes: [],

      cantPriceMessage: "",
      pricingErrorMessage: "",

      globalCommission: 0.1,
      prices: [],

      supplierOptions: [],
      availableSuppliers: [{ text: "Supplier", value: null }],
      selectedSupplier: null,

      selectedTerms: ["any"],
      termOptions: [
        { text: "Any", value: "any" },
        { text: "1", value: 12 },
        { text: "2", value: 24 },
        { text: "3", value: 36 },
        { text: "4", value: 48 },
        { text: "5", value: 60 }
      ],

      showWindowClosed: false,
      windowOpen: {}
    };
  },
  created() {
    this.supplierOptions = Static.supplierOptionsMap[this.deal.type];
  },
  computed: {
    offers() {
      let offers = [];
      if (this.prices) {
        const gas = this.supply.type == "GAS";
        // Filter out types that are not selected
        let prices = this.prices.filter(p => {
          if (!this.showWindowClosed && p.windowClosed) {
            return false;
          } else if (!gas && !this.selectedMeterTypes.includes(p.meterType)) {
            return false;
          } else if (this.deal.supplierName == p.supplierName && p.salesType == "Acquisition") {
            return false;
          } else if (this.deal.supplierName != p.supplierName && p.salesType == "Renewal") {
            return false;
          } else if (
            this.selectedTerms[0] &&
            this.selectedTerms[0] != "any" &&
            this.selectedTerms[0] != p.contractTerm
          ) {
            return false;
          } else if (this.selectedSupplier && this.selectedSupplier != p.supplierName) {
            return false;
          }

          return true;
        });
        prices.forEach(p => {
          let annualCost = 0;
          let fitsCharge = p.fitsCharge ? p.fitsCharge : 0;

          // Update commission based on comm level
          p.consumptionCommission =
            (((this.totalConsumption * p.commissionLevel) / 100) * p.contractTerm) / 12;
          p.consumptionCommission = Math.round(p.consumptionCommission * 100) / 100;

          // Update unit rates based on comm level
          if (p.unitRate) {
            p.unitRateComm = parseFloat((p.unitRate + p.commissionLevel + fitsCharge).toFixed(3));
            annualCost += p.unitRateComm * this.supply.dayConsumption;
          }
          if (p.dayUnitRate) {
            p.dayUnitRateComm = parseFloat(
              (p.dayUnitRate + p.commissionLevel + fitsCharge).toFixed(3)
            );
            annualCost += p.dayUnitRateComm * this.supply.dayConsumption;
          }
          if (p.nightUnitRate) {
            p.nightUnitRateComm = parseFloat(
              (p.nightUnitRate + p.commissionLevel + fitsCharge).toFixed(3)
            );
            annualCost += p.nightUnitRateComm * this.supply.nightConsumption;
          }
          if (p.ewUnitRate) {
            p.ewUnitRateComm = parseFloat(
              (p.ewUnitRate + p.commissionLevel + fitsCharge).toFixed(3)
            );
            annualCost += p.ewUnitRateComm * this.supply.ewConsumption;
          }
          if (p.offPeakUnitRate) {
            p.offPeakUnitRateComm = parseFloat(
              (p.offPeakUnitRate + p.commissionLevel + fitsCharge).toFixed(3)
            );
            annualCost += p.offPeakUnitRateComm * this.totalConsumption;
          }
          if (p.nonWeekdayUnitRate) {
            p.nonWeekdayUnitRateComm = parseFloat(
              (p.nonWeekdayUnitRate + p.commissionLevel + fitsCharge).toFixed(3)
            );
            annualCost += p.nonWeekdayUnitRateComm * this.totalConsumption;
          }
          // Update annual cost based on comm level
          if (this.supply.paysClimateLevy) {
            annualCost += parseFloat(this.totalConsumption * this.supply.cclRate);
          }
          annualCost += p.standingCharge * 365;
          p.annualCostComm = parseFloat((annualCost / 100).toFixed(2));

          p.saving =
            parseFloat(p.annualCostComm) -
            parseFloat(
              this.deal.projectedAnnualCost ? this.deal.projectedAnnualCost : this.deal.annualCost
            );
          p.saving = p.saving < 0 ? (p.saving * -1).toFixed(2) : 0;
        });
        offers = prices;
      }
      return offers;
    },

    selectedOffers() {
      return this.prices.filter(p => p.selected);
    },

    totalConsumption() {
      let aq = 0;
      if (this.supply.dayConsumption) aq += this.supply.dayConsumption;
      if (this.supply.nightConsumption) aq += this.supply.nightConsumption;
      if (this.supply.ewConsumption) aq += this.supply.ewConsumption;
      if (this.supply.winterConsumption) aq += this.supply.winterConsumption;
      return aq;
    },

    newContractStart() {
      let newContractStart = Date.now();
      // Window opens 1 day after contract ends
      if (this.deal.contractExpiry && !this.deal.outOfContract) {
        newContractStart = new Date(this.deal.contractExpiry);
        newContractStart.setDate(newContractStart.getDate() + 1);
      }
      return newContractStart;
    }
  },
  methods: {
    formatCobold: FormatHelper.formatCoboldCase,
    async priceOffers() {
      if (!this.canPrice()) return;
      this.setMeterType();

      this.busy = true;
      let API_ENDPOINT = pricing.baseUrl + "price-" + (this.supply.type == "GAS" ? "gas" : "elec");
      const DEFAULT_HEADERS = axios.defaults.headers.common; // we'll need to reset them later
      axios.defaults.headers.common = {};

      await axios
        .post(API_ENDPOINT, JSON.stringify(this.getRequestParams()))
        .then(response => {
          console.log(response);
          if (!response.data.body) {
            this.pricingErrorMessage = "No prices found";
          } else {
            this.prices = JSON.parse(response.data.body)
              .map(p => this.setDefaultValues(p))
              .sort((a, b) => (a.annualCost > b.annualCost ? 1 : -1));

            this.prices.forEach(p => this.setWindowClosed(p));
          }
        })
        .catch(e => {
          console.log(e);
          this.pricingErrorMessage = e.response.data;
          console.log("Error pricing", e.response);
        })
        .finally(() => {
          axios.defaults.headers.common = DEFAULT_HEADERS;
          this.busy = false;
        });
    },

    canPrice() {
      if (this.supply.type == "ELECTRICITY") {
        if (!this.supply.meterNumber) {
          this.cantPriceMessage = "meter number is missing";
        } else if (!this.totalConsumption) {
          this.cantPriceMessage = "AQ is missing";
        } else {
          return true;
        }
      } else if (this.supply.type == "GAS") {
        if (!this.supply.address) {
          this.cantPriceMessage = "supply address is missing";
        } else if (!this.supply.address.postcode) {
          this.cantPriceMessage = "supply postcode is missing";
        } else if (!this.supply.dayConsumption) {
          this.cantPriceMessage = "AQ is missing";
        } else {
          return true;
        }
      } else {
        this.cantPriceMessage = "expected elec or gas supply";
      }
      return false;
    },

    setMeterType() {
      if (this.supply.type == "GAS") return;

      if (this.supply.ewConsumption) {
        this.selectedMeterTypes.push("EVENING_WEEKEND");
        if (this.supply.nightConsumption) {
          this.selectedMeterTypes.push("EVENING_WEEKEND_NIGHT");
        }
      } else if (this.supply.dayConsumption) {
        if (this.supply.nightConsumption) {
          this.selectedMeterTypes.push("DAY_NIGHT");
        } else {
          this.selectedMeterTypes.push("SINGLE_RATE");
        }
      }

      if (!this.selectedMeterTypes.length) {
        this.selectedMeterTypes.push("SINGLE_RATE");
      }

      // Do not select off-peak meters for now
      // if (
      //   (this.supply.dayConsumption &&
      //     !this.supply.nightConsumption &&
      //     !this.supply.ewConsumption) ||
      //   (this.supply.nightConsumption &&
      //     !this.supply.dayConsumption &&
      //     !this.supply.ewConsumption) ||
      //   (this.supply.ewConsumption && !this.supply.dayConsumption && !this.supply.nightConsumption)
      // ) {
      //   this.selectedMeterTypes.push("OFF_PEAK");
      // }
    },

    getRequestParams() {
      if (this.supply.type == "ELECTRICITY") {
        return {
          mpan: this.supply.meterNumber,
          dayAq: this.supply.dayConsumption,
          nightAq: this.supply.nightConsumption,
          ewAq: this.supply.ewConsumption,
          includeCcl: this.supply.paysClimateLevy
        };
      } else {
        return {
          postcode: this.supply.address.postcode,
          aq: this.totalConsumption,
          includeCcl: this.supply.paysClimateLevy
        };
      }
    },

    selectOffer(offer) {
      this.$set(offer, "selected", !offer.selected);
      if (offer.selected) {
        if (!this.selectedOffers[offer.supplierName]) {
          this.$set(this.selectedOffers, offer.supplierName, [offer]);
        } else {
          this.selectedOffers[offer.supplierName].push(offer);
        }
      } else {
        this.selectedOffers[offer.supplierName] = this.selectedOffers[offer.supplierName].filter(
          o => o.sortKey != offer.sortKey
        );
      }
    },

    supplierLogo(name) {
      let supplier = this.supplierOptions.find(s => name == s.text);
      if (supplier) return supplier.logo;
    },

    setDefaultValues(price) {
      price.logo = this.supplierLogo(price.supplierName);
      price.commissionLevel = this.globalCommission;
      price.selected = false;
      price.id = [
        price.supplierName,
        price.meterType,
        price.contractTerm,
        price.salesType,
        price.standingChargeType
      ].join("#");
      price.termKey = [price.contractStart, price.contractEnd].join("-");

      if (!this.availableSuppliers.includes(price.supplierName)) {
        this.availableSuppliers.push(price.supplierName);
      }
      if (!this.windowOpen[price.id]) {
        this.windowOpen[price.id] = [price.termKey];
      }
      if (!this.windowOpen[price.id].includes(price.termKey)) {
        this.windowOpen[price.id].push(price.termKey);
        this.windowOpen[price.id].sort();
      }

      if (price.weekdayUnitRate) {
        price.dayUnitRate = price.weekdayUnitRate;
      }

      if (price.meterType == "OFF_PEAK") {
        if (price.nightUnitRate) {
          price.offPeakUnitRate = price.nightUnitRate;
          price.nightUnitRate = null;
        }
        if (price.offPeakRate) {
          price.offPeakUnitRate = price.offPeakRate;
          price.offPeakRate = null;
        }
        if (price.dayUnitRate) {
          price.offPeakUnitRate = price.dayUnitRate;
          price.dayUnitRate = null;
        }
      }

      price.maxUplift = this.getMaxUplift(price);

      return price;
    },

    onGlobalCommissionChange(val) {
      const comm = parseFloat(val);
      this.prices.forEach(p => (p.commissionLevel = Math.min(p.maxUplift, comm)));
    },

    contractDatesDescription(offer) {
      let str = "";
      if (offer.contractStart) {
        str += "Window open: " + new Date(offer.contractStart).toLocaleDateString("en-GB");
      } else {
        str += "Window open: --";
      }
      if (offer.contractEnd && offer.contractEnd != 4070908800000) {
        str += "\nWindow close: " + new Date(offer.contractEnd).toLocaleDateString("en-GB");
      } else {
        str += "\nWindow close: --";
      }
      return str;
    },

    async deleteSupplyOffers() {
      this.deletingOffers = true;
      this.$parent.deleteOffers(true);
      // Wait 1s for deals to be deleted
      return new Promise(resolve => {
        setTimeout(() => {
          this.deletingOffers = true;
          resolve();
        }, 1000);
      });
    },

    async submitOffers() {
      if (this.replaceDeals && this.dealsCount) {
        await this.deleteSupplyOffers();
      }
      let deals = this.selectedOffers.map(o => {
        let op = o.meterType == "OFF_PEAK";
        return {
          dayUnitRate: o.unitRateComm
            ? o.unitRateComm
            : op && this.supply.dayConsumption
            ? o.offPeakUnitRateComm
            : o.dayUnitRateComm,
          nightUnitRate: o.nightUnitRateComm
            ? o.nightUnitRateComm
            : op && this.supply.nightConsumption
            ? o.offPeakUnitRateComm
            : null,
          ewUnitRate: o.ewUnitRate
            ? o.ewUnitRate
            : op && this.supply.ewConsumption
            ? o.offPeakUnitRateComm
            : null,
          fitsCharge: o.fitsCharge ? o.fitsCharge : null,
          commission: o.commissionLevel,
          standingCharge: o.standingCharge,
          supplierId: o.supplierId,
          supplierName: o.supplierName,
          published: true,
          type: this.deal.type,
          "@type": this.deal.type,
          contractTerm: o.contractTerm,
          recommended: false,
          greenTariff: o.greenTariff == true
        };
      });
      this.$emit("submit-offers", deals);
      this.$emit("update:show", false);
    },

    // Check if price window is open based on current contract expiry
    // If no prices have window open, show price with earliest available window
    setWindowClosed(p) {
      let closestWindowOpen = this.findClosestWindow(p.id);

      if (closestWindowOpen) {
        p.windowClosed = p.termKey != closestWindowOpen;
      } else {
        p.windowClosed = p.termKey != this.windowOpen[p.id][0];
        if (!p.windowClosed) p.windowOpenLater = true;
      }
    },

    findClosestWindow(priceId) {
      let difference = Number.MAX_VALUE;
      let closestTermKey = null;

      this.windowOpen[priceId].forEach(termKey => {
        const windowOpen = termKey.split("-")[0];
        const windowClose = termKey.split("-")[1];

        if (this.newContractStart >= windowOpen && this.newContractStart <= windowClose) {
          if (this.newContractStart - windowOpen < difference) {
            difference = this.newContractStart - windowOpen;
            closestTermKey = termKey;
          }
        }
      });

      return closestTermKey;
    },

    getMaxUplift(price) {
      let salesType =
        price.salesType && ["acquisition", "renewal"].includes(price.salesType.toLowerCase())
          ? price.salesType.toLowerCase()
          : "acquisition";
      let maxUplift = StaticRates.maxUplifts[this.supply.type][salesType][price.supplierName];

      if (maxUplift && price.supplierName == "SmartestEnergy") {
        maxUplift = maxUplift[price.productType.toLowerCase()];
      }
      if (!maxUplift) {
        maxUplift = 3;
      }

      return maxUplift;
    }
  }
};
</script>

<style lang="scss">
#energy-pricing-modal {
  @import "@/styles/common";
  @include badge-styles;
  .modal-xxl {
    width: 1600px;
    max-width: 90% !important;
  }
  strong {
    font-weight: 600;
  }

  .selected-header {
    p {
      font-size: 14px;
    }
  }
  .selected-offers-col {
    position: relative;
    min-height: 11rem;
    .selected-offers {
      position: absolute;
      display: flex;
      width: 95%;
      flex-flow: column;
      max-height: 100% !important;
      overflow-y: auto;
      overflow-x: hidden;
      .selected-offer-card {
        border: 1px solid #dbd7d7;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px #0000003d;
        margin-bottom: 7px;
        padding: 0.2rem 0.75rem;
        img {
          max-width: 6rem;
          max-height: 2rem;
        }
        .unselect-icon {
          transform: rotate(45deg);
          cursor: pointer;
        }
        .offer-section {
          display: flex;
          justify-content: space-between;
          padding-top: 0.2rem;
          padding-bottom: 0.3rem;
          font-size: 14px;
          &:not(:last-of-type),
          :not(:first-of-type) {
            border-bottom: 1px solid #dbd7d7;
          }
        }
      }
    }
  }

  .pricing-options {
    & > div {
      display: flex;
      align-items: flex-end;
    }
    .custom-range {
      max-width: 10rem;
    }
  }

  .offers-header {
    text-align: center;
    background-color: whitesmoke;
    font-weight: 600;
    padding: 1rem 0;
  }

  .offer-card {
    text-align: center;
    padding: 1rem 0;
    border: 1px solid #dbd7d7;
    margin-top: 7.5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px #0000003d;
    position: relative;
    .col {
      margin: auto;
    }
    img {
      max-width: 90% !important;
    }
    &[selected] {
      border-color: $color-blue-main;
    }
    .green-tariff {
      position: absolute;
      top: 2px;
      left: -2px;
      border-radius: 50%;
    }
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .multiselect {
    max-width: max-content;
  }
}
</style>
