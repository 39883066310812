<template>
  <div class="deal-form-wrapper">
    <div v-if="deal.supplierId || dealSavings" class="deal-form-tabs">
      <div v-if="deal.supplierId" class="deal-form-tab">
        <b-img-lazy v-if="supplierLogo" :src="supplierLogo" />
        <span class="p-1 small" v-else>{{ deal.supplierName }}</span>
      </div>
      <div v-if="dealSavings" class="deal-form-tab savings-tab">
        <b-badge variant="primary"> Savings: {{ $currencySymbol }}{{ dealSavings }} </b-badge>
      </div>
    </div>

    <div :class="dealFormClass" tabindex="0">
      <deal-form-toolbar
        :deal="deal"
        :supply="supply"
        :show-fields.sync="showFields"
        :draft-id="deal.draftId"
        :supplyContractIds="supplyContractIds"
        @loadSupplyContractIds="loadSupplyContractIds"
        @change="assignDraftId"
      />
      <b-form @change="assignDraftId">
        <div class="inline-inputs">
          <b-form-group label="Supplier">
            <b-form-select
              :value="deal.supplierId"
              :options="supplierOptions"
              @change="assignSupplier"
            />
          </b-form-group>

          <b-form-group label="Annual Cost">
            <annual-cost-input :deal="deal" :supply="supply" @change="set('annualCost', $event)" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.projected" label="Proj Annual Cost">
            <b-form-input v-model="deal.projectedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group v-if="CURRENT && showFields.estimated" label="Estimated Annual Cost">
            <b-form-input v-model="deal.estimatedAnnualCost" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea v-model="deal.description" />
          </b-form-group>

          <b-form-group v-if="CURRENT || FUTURE" label="Contract Start">
            <date-input v-model="deal.contractStartDate" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Contract Term">
            <contract-term-input v-model="deal.contractTerm" />
          </b-form-group>

          <b-form-group v-if="CURRENT || LEGACY" label="Contract End">
            <date-input v-model="deal.contractExpiry" />
          </b-form-group>

          <b-form-group v-if="showFields.combinedLineRentalVoip" label="Line Rental & VoIP Charge">
            <b-form-input v-model="deal.combinedLineRentalVoip" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Setup Fee">
            <b-form-input v-model="deal.setupFee" type="number" min="0" />
          </b-form-group>

          <b-form-group label="Calling packages">
            <b-form-textarea v-model="deal.callingPackages" />
          </b-form-group>

          <b-form-group v-if="FUTURE" label="Total Commission">
            <b-form-input
              v-model="deal.consumptionCommission"
              type="number"
              min="0"
              @change="set('consumptionCommission', $event)"
            />
          </b-form-group>

          <div class="break"></div>
          <monthly-extras-input
            :monthly-extras="deal.monthlyExtras"
            @change="set('monthlyExtras', $event)"
          />

          <div class="break"></div>
          <one-off-extras-input
            :one-off-extras="deal.oneOffExtras"
            @change="set('oneOffExtras', $event)"
          />

          <div class="break"></div>
          <line-rentals-input
            :line-rentals="deal.lineRentals"
            @change="set('lineRentals', $event)"
          />

          <div class="break"></div>
          <voip-services-input
            :voip-services="deal.voipServices"
            @change="set('voipServices', $event)"
          />

          <div class="break"></div>
          <broadband-services-input
            :broadband-services="deal.broadbandServices"
            @change="set('broadbandServices', $event)"
          />

          <!-- For smooth tabbing -->
          <a @focus="focusOnSaveBtn($el)" href />
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { dealFormMixin } from "../mixins/dealFormMixin";
import AnnualCostInput from "./parts/inputs/calculated/AnnualCostInput";
import BroadbandServicesInput from "./parts/inputs/telecoms/BroadbandServicesInput";
import LineRentalsInput from "./parts/inputs/telecoms/LineRentalsInput";
import MonthlyExtrasInput from "./parts/inputs/telecoms/MonthlyExtrasInput";
import OneOffExtrasInput from "./parts/inputs/telecoms/OneOffExtrasInput";
import VoipServicesInput from "./parts/inputs/telecoms/VoipServicesInput.vue";

export default {
  name: "TelecomsDealForm",
  mixins: [dealFormMixin],
  components: {
    AnnualCostInput,
    MonthlyExtrasInput,
    OneOffExtrasInput,
    LineRentalsInput,
    BroadbandServicesInput,
    VoipServicesInput
  }
};
</script>

<style lang="scss" scoped>
.deal-form {
  .list-input.empty {
    max-width: 14.5rem !important;
  }
}
</style>
