<template>
  <b-modal
    id="telecoms-pricing-modal"
    :visible="show"
    hide-header
    centered
    @ok="submitOffers"
    @show="priceOffers"
    @change="$emit('update:show', $event)"
  >
    <b-row v-if="deal && commsConsultOffer">
      <b-col cols="12">
        <b-form @input="priceOffers">
          <b-checkbox v-model="options.supplierSupportRequired" class="mb-3"
            >Supplier support required</b-checkbox
          >
          <b-form-group
            v-if="!broadbandOnly"
            label="Line Commission Level"
            label-for="line-commission-level"
          >
            <b-form-select
              id="line-commission-level"
              v-model="options.lineCommissionLevel"
              :options="lineCommissionLevelOptions"
            />
          </b-form-group>
          <b-form-group
            v-if="!lineOnly"
            label="Broadband Commission Level"
            label-for="broadband-commission-level"
          >
            <b-form-select
              id="broadband-commission-level"
              v-model="options.broadbandCommissionLevel"
              :options="broadbandCommissionLevelOptions"
            />
          </b-form-group>
        </b-form>
      </b-col>
      <b-col cols="12" class="text-right">
        <hr />
        <p>Comms Consult 3 year {{ broadbandType(commsConsultOffer) }} deal</p>
        <div class="d-flex justify-content-between">
          <p>Saving</p>
          <p>
            <strong v-if="commsConsultSaving > 0" class="text-success"
              >{{ $currencySymbol
              }}{{ commsConsultSaving ? commsConsultSaving.toFixed(2) : 0 }}</strong
            >
            <strong v-else class="text-danger"
              >{{ $currencySymbol
              }}{{ commsConsultSaving ? commsConsultSaving.toFixed(2) : 0 }}</strong
            >
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p>Gross commission</p>
          <p>
            <strong>{{ $currencySymbol }}{{ commsConsultOffer.consumptionCommission }}</strong>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p v-if="options.supplierSupportRequired">Supplier support required</p>
          <p v-else>No supplier support required</p>
          <p v-if="options.supplierSupportRequired"><strong>- 65%</strong></p>
          <p v-else><strong>- 50%</strong></p>
        </div>
        <div class="d-flex justify-content-between">
          <p>Net commission</p>
          <p>
            <strong class="text-success"
              >{{ $currencySymbol }}{{ commsConsultNetCommission }}</strong
            >
          </p>
        </div>
      </b-col>
      <b-col cols="12" class="text-right">
        <hr />
        <p>Global 4 Comms 3 year {{ broadbandType(global4Offer) }} deal</p>
        <div class="d-flex justify-content-between">
          <p>Saving</p>
          <p>
            <strong v-if="global4Saving > 0" class="text-success"
              >{{ $currencySymbol }}{{ global4Saving ? global4Saving.toFixed(2) : 0 }}</strong
            >
            <strong v-else class="text-danger"
              >{{ $currencySymbol }}{{ global4Saving ? global4Saving.toFixed(2) : 0 }}</strong
            >
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p>Gross commission</p>
          <p>
            <strong>{{ $currencySymbol }}{{ global4Offer.consumptionCommission }}</strong>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p v-if="options.supplierSupportRequired">Supplier support required</p>
          <p v-else>No supplier support required</p>
          <p v-if="options.supplierSupportRequired"><strong>- 65%</strong></p>
          <p v-else><strong>- 50%</strong></p>
        </div>
        <div class="d-flex justify-content-between">
          <p>Net commission</p>
          <p>
            <strong class="text-success">{{ $currencySymbol }}{{ global4NetCommission }}</strong>
          </p>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import PricingHelper from "../helpers/pricinghelper";
import CalculationHelper from "../helpers/calculationhelper";

export default {
  name: "TelecomsPricingModal",
  props: {
    deal: {
      type: Object,
      required: true
    },
    show: Boolean
  },
  data() {
    return {
      offers: null,
      commsConsultOffer: null,
      global4Offer: null,
      options: {
        supplierSupportRequired: false,
        lineCommissionLevel: "Medium",
        broadbandCommissionLevel: "Medium"
      },
      lineCommissionLevelOptions: ["High", "Medium", "Low", "Verylow"],
      broadbandCommissionLevelOptions: ["High", "Medium", "Low", "VeryLow"]
    };
  },
  computed: {
    commsConsultNetCommission() {
      if (this.commsConsultOffer) {
        return PricingHelper.getNetCommission(this.commsConsultOffer, this.options);
      } else return 0;
    },
    commsConsultSaving() {
      if (this.commsConsultOffer) {
        return (
          this.deal.annualCost -
          CalculationHelper.annualCostTelecoms(this.commsConsultOffer, {}).toFixed(2)
        );
      } else return 0;
    },
    global4NetCommission() {
      if (this.global4Offer) {
        return PricingHelper.getNetCommission(this.global4Offer, this.options);
      } else return 0;
    },
    global4Saving() {
      if (this.global4Offer) {
        return (
          this.deal.annualCost -
          CalculationHelper.annualCostTelecoms(this.global4Offer, {}).toFixed(2)
        );
      } else return 0;
    },
    lineOnly() {
      if (this.deal) {
        return !(this.deal.broadbandServices && this.deal.broadbandServices.length > 0);
      } else return false;
    },
    broadbandOnly() {
      if (this.deal) {
        return !(this.deal.lineRentals && this.deal.lineRentals.length > 0);
      } else return false;
    }
  },
  methods: {
    async priceOffers() {
      await this.$nextTick();
      if (this.lineOnly) this.options.broadbandCommissionLevel = null;
      if (this.broadbandOnly) this.options.lineCommissionLevel = null;

      this.offers = PricingHelper.priceTelecoms(this.deal, this.options);

      if (!this.offers) {
        this.closeModal();
      } else {
        this.commsConsultOffer = this.offers.find(
          (o) =>
            o.supplierId == "ecc2374c-3350-46ca-8a8d-0c6154d69ded" &&
            o.contractTerm == 36 &&
            this.broadbandType(o) == this.broadbandType(this.deal)
        );
        this.global4Offer = this.offers.find(
          (o) =>
            o.supplierId == "d4153c3f-51a8-46c7-8158-43fbf32db9f9" &&
            o.contractTerm == 36 &&
            this.broadbandType(o) == this.broadbandType(this.deal)
        );
      }
    },
    broadbandType(deal) {
      if (deal && deal.broadbandServices && deal.broadbandServices.length > 0) {
        return deal.broadbandServices[0].type;
      } else return "";
    },
    submitOffers() {
      this.offers.forEach(
        (o) => (o.consumptionCommission = PricingHelper.getNetCommission(o, this.options))
      );
      this.$emit("submit-offers", this.offers);
      this.closeModal();
    },
    closeModal() {
      this.$emit("update:show", false);
    }
  }
};
</script>