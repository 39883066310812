export default
    {
        "lineonly": [
            {
                "supplierName": "Bonline",
                "supplierId": "49d217d2-c404-49a2-9f90-9dfc37c27c6f",
                "monthlycharge": 15.95,
                "setupfee": 9.95,
                "contractterm": 24,
                "commission": 100.00
            },
            {
                "supplierName": "Clear Business",
                "supplierId": "f7bc71d5-7650-4710-b6bd-404ad1af62d6",
                "monthlycharge": 14.28,
                "setupfee": 7.99,
                "contractterm": 36,
                "commission": 150.00
            },
            {
                "supplierName": "Global 4 Communications",
                "supplierId": "d4153c3f-51a8-46c7-8158-43fbf32db9f9",
                "monthlycharge": {
                    "line": {
                        "verylow": 10.95,
                        "low": 11.95,
                        "medium": 13.95,
                        "high": 16.95
                    }
                },
                "setupfee": 6.99,
                "contractterm": 36,
                "commission": {
                    "line": {
                        "verylow": 11.40,
                        "low": 108.00,
                        "medium": 180.00,
                        "high": 288.00
                    }
                }
            },
            {
                "supplierName": "Comms Consult",
                "supplierId": "ecc2374c-3350-46ca-8a8d-0c6154d69ded",
                "monthlycharge": {
                    "line": {
                        "verylow": 12.95,
                        "low": 12.95,
                        "medium": 14.95,
                        "high": 17.95
                    }
                },
                "setupfee": 6.95,
                "contractterm": 36,
                "commission": {
                    "line": {
                        "verylow": 120.00,
                        "low": 120.00,
                        "medium": 187.00,
                        "high": 270.00
                    }
                }
            },
        ],
        "lineandbroadband": [
            {
                "supplierName": "Bonline",
                "supplierId": "49d217d2-c404-49a2-9f90-9dfc37c27c6f",
                "type": "ADSL",
                "downloadspeed": 25,
                "uploadspeed": 1,
                "monthlycharge": 18.41,
                "setupfee": 9.95,
                "contractterm": 24,
                "commission": 100.00
            },
            {
                "supplierName": "Bonline",
                "supplierId": "49d217d2-c404-49a2-9f90-9dfc37c27c6f",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": 29.29,
                "setupfee": 9.95,
                "contractterm": 24,
                "commission": 140.00
            },
            {
                "supplierName": "Clear Business",
                "supplierId": "f7bc71d5-7650-4710-b6bd-404ad1af62d6",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": 35.93,
                "setupfee": 7.99,
                "contractterm": 36,
                "commission": 280.00
            },
            {
                "supplierName": "Plusnet",
                "supplierId": "0a8478ce-4a1e-42de-9f65-a0334998ac86",
                "type": "ADSL",
                "downloadspeed": 25,
                "uploadspeed": 1,
                "monthlycharge": 21.50,
                "setupfee": 4.99,
                "contractterm": 12,
                "commission": 60.00
            },
            {
                "supplierName": "Plusnet",
                "supplierId": "0a8478ce-4a1e-42de-9f65-a0334998ac86",
                "type": "ADSL",
                "downloadspeed": 25,
                "uploadspeed": 1,
                "monthlycharge": 18.00,
                "setupfee": 4.99,
                "contractterm": 24,
                "commission": 70.00
            },
            {
                "supplierName": "Plusnet",
                "supplierId": "0a8478ce-4a1e-42de-9f65-a0334998ac86",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": 27.00,
                "setupfee": 4.99,
                "contractterm": 12,
                "commission": 70.00
            },
            {
                "supplierName": "Plusnet",
                "supplierId": "0a8478ce-4a1e-42de-9f65-a0334998ac86",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": 22.00,
                "setupfee": 4.99,
                "contractterm": 24,
                "commission": 80.00
            },
            {
                "supplierName": "Global 4 Communications",
                "supplierId": "d4153c3f-51a8-46c7-8158-43fbf32db9f9",
                "type": "ADSL",
                "downloadspeed": 25,
                "uploadspeed": 1,
                "monthlycharge": {
                    "line": {
                        "verylow": 0.00,
                        "low": 11.95,
                        "medium": 13.95,
                        "high": 16.95
                    },
                    "broadband": {
                        "verylow": 19.95,
                        "low": 14.98,
                        "medium": 16.98,
                        "high": 20.98
                    }
                },
                "setupfee": 6.99,
                "contractterm": 36,
                "commission": {
                    "line": {
                        "verylow": 0.0,
                        "low": 108.00,
                        "medium": 180.00,
                        "high": 288.00
                    },
                    "broadband": {
                        "verylow": 94.80,
                        "low": 305.64,
                        "medium": 377.64,
                        "high": 521.64
                    }
                }
            },
            {
                "supplierName": "Global 4 Communications",
                "supplierId": "d4153c3f-51a8-46c7-8158-43fbf32db9f9",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": {
                    "line": {
                        "verylow": 0.00,
                        "low": 11.95,
                        "medium": 13.95,
                        "high": 16.95
                    },
                    "broadband": {
                        "verylow": 29.75,
                        "low": 29.98,
                        "medium": 34.48,
                        "high": 38.48
                    }
                },
                "setupfee": 6.99,
                "contractterm": 36,
                "commission": {
                    "line": {
                        "verylow": 0.00,
                        "low": 108.00,
                        "medium": 180.00,
                        "high": 288.00
                    },
                    "broadband": {
                        "verylow": 96.00,
                        "low": 608.76,
                        "medium": 770.76,
                        "high": 914.76
                    }
                }
            },
            {
                "supplierName": "Comms Consult",
                "supplierId": "ecc2374c-3350-46ca-8a8d-0c6154d69ded",
                "type": "ADSL",
                "downloadspeed": 25,
                "uploadspeed": 1,
                "monthlycharge": {
                    "line": {
                        "verylow": 11.95,
                        "low": 12.95,
                        "medium": 14.95,
                        "high": 17.95
                    },
                    "broadband": {
                        "verylow": 14.98,
                        "low": 14.98,
                        "medium": 16.98,
                        "high": 20.98
                    }
                },
                "setupfee": 6.95,
                "contractterm": 36,
                "commission": {
                    "line": {
                        "verylow": 120.00,
                        "low": 120.00,
                        "medium": 187.50,
                        "high": 270.00
                    },
                    "broadband": {
                        "verylow": 193.11,
                        "low": 193.11,
                        "medium": 236.31,
                        "high": 322.71
                    }
                }
            },
            {
                "supplierName": "Comms Consult",
                "supplierId": "ecc2374c-3350-46ca-8a8d-0c6154d69ded",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": {
                    "line": {
                        "verylow": 12.95,
                        "low": 12.95,
                        "medium": 14.95,
                        "high": 17.95
                    },
                    "broadband": {
                        "verylow": 29.98,
                        "low": 29.98,
                        "medium": 34.48,
                        "high": 38.48
                    }
                },
                "setupfee": 6.95,
                "contractterm": 36,
                "commission": {
                    "line": {
                        "verylow": 120.00,
                        "low": 120.00,
                        "medium": 187.50,
                        "high": 270.00
                    },
                    "broadband": {
                        "verylow": 428.87,
                        "low": 428.87,
                        "medium": 526.07,
                        "high": 612.47
                    }
                }
            },
        ],
        "broadbandonly": [
            {
                "supplierName": "Comms Consult",
                "supplierId": "ecc2374c-3350-46ca-8a8d-0c6154d69ded",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": {
                    "broadband": {
                        "verylow": 29.98,
                        "low": 29.98,
                        "medium": 34.48,
                        "high": 38.48
                    }
                },
                "setupfee": 6.95,
                "contractterm": 36,
                "commission": {
                    "broadband": {
                        "verylow": 428.87,
                        "low": 428.87,
                        "medium": 526.07,
                        "high": 612.47
                    }
                }
            },
            {
                "supplierName": "Global 4 Communications",
                "supplierId": "d4153c3f-51a8-46c7-8158-43fbf32db9f9",
                "type": "FIBRE",
                "fibretype": "FTTC",
                "downloadspeed": 80,
                "uploadspeed": 20,
                "monthlycharge": {
                    "broadband": {
                        "verylow": 29.75,
                        "low": 29.98,
                        "medium": 34.48,
                        "high": 38.48
                    }
                },
                "setupfee": 6.99,
                "contractterm": 36,
                "commission": {
                    "broadband": {
                        "verylow": 96.00,
                        "low": 608.76,
                        "medium": 770.76,
                        "high": 914.76
                    }
                }
            },
        ]
    }