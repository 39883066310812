<template>
  <div id="ds-organisation-fields" class="ds-section">
    <div cols="12" id="section-header">
      <p class="m-0">COMPANY SECTION</p>
      <a
        v-if="orgSearchName"
        :href="
          'https://find-and-update.company-information.service.gov.uk/search?q=' + orgSearchName
        "
        target="_blank"
      >
        <u>Find in Companies House</u>
      </a>
    </div>

    <b-row id="section-body">
      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <b-form-group
          v-if="show('org_name')"
          :label="!showDsLabels ? 'Company Name' : ''"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '12'"
        >
          <b-form-input
            v-model="dsOrg.name"
            :class="highlight('org_name', dsOrg.name)"
          ></b-form-input>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('org_name')"
          :labels="{
            org_name: dsOrg.name,
            org_trading_name: dsOrg.name
          }"
        />

        <b-form-group
          v-if="show('org_trading_name')"
          :label="!showDsLabels ? 'Trading Name' : 'org_trading_name'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            v-model="dsOrg.tradingName"
            :class="highlight('org_trading_name', dsOrg.tradingName)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="show('org_registr_no')"
          :label="!showDsLabels ? 'Registr. No.' : 'org_registr_no'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            :class="highlight('org_registr_no', dsOrg.registrNo && registrNoState)"
            v-model="dsOrg.registrNo"
            :state="registrNoState"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="showGroup(['org_tel', 'org_fax'])"
          :label="!showDsLabels ? 'Contact' : ''"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '12'"
        >
          <div class="d-flex">
            <b-form-input
              :class="highlight('org_tel', dsOrg.phoneNumber)"
              placeholder="Phone"
              v-if="show('org_tel')"
              v-model="dsOrg.phoneNumber"
            ></b-form-input>
            <b-form-input
              :class="highlight('org_fax', dsOrg.fax)"
              placeholder="Fax"
              v-if="show('org_fax')"
              v-model="dsOrg.fax"
            ></b-form-input>
          </div>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && showGroup(['org_tel', 'org_fax'])"
          :labels="{
            org_tel: dsOrg.phoneNumber,
            org_fax: dsOrg.fax
          }"
        />

        <b-form-group
          v-if="show('org_email')"
          :label="!showDsLabels ? 'Email' : 'org_email'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            :class="highlight('org_email', dsOrg.email)"
            v-model="dsOrg.email"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="show('org_business_nature')"
          :label="!showDsLabels ? 'Business Nature' : 'org_business_nature'"
          label-cols="12"
          label-cols-sm="3"
        >
          <b-form-input
            :class="highlight('org_business_nature', dsOrg.businessNature)"
            v-model="dsOrg.businessNature"
          ></b-form-input>
        </b-form-group>

        <b-form-group v-if="showGroup(orgTypes())" label="Organisation Type" label-cols="12">
          <b-form-checkbox-group v-model="dsOrg.organisationTypes" class="ds-checkbox-group">
            <div v-for="(value, key) in organisationTypes" :key="key">
              <b-form-checkbox
                :value="value"
                :class="highlight([key, 'org_type'])"
                v-if="showGroup([key, 'org_type'])"
                >{{ !showDsLabels ? formatCheckboxTitles(value) : key }}</b-form-checkbox
              >
            </div>
          </b-form-checkbox-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && showGroup(orgTypes())"
          :labels="{ org_type_text: textCheckboxLabel(dsOrg.organisationTypes) }"
        />
      </b-col>

      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <b-form-checkbox
          v-if="show('org_trading_less12')"
          switch
          v-model="dsOrg.tradingLess12Months"
          :class="highlight('org_trading_less12')"
          >Has the company been trading under legal name for less than 12 months?</b-form-checkbox
        >

        <b-form-group
          :label="!showDsLabels ? 'Trading Time' : ''"
          label-cols="12"
          :label-cols-sm="!showDsLabels ? '3' : '12'"
          v-if="show('org_trading_length')"
        >
          <b-input-group append="total months">
            <ds-input-tooltip text="Trading time since the company has existed" icon="info" />

            <b-form-input
              :class="highlight('org_trading_length', dsOrg.tradingLength)"
              type="number"
              @input="checkupTradingLength"
              v-model="dsOrg.tradingLength"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('org_trading_length')"
          :labels="{
            org_trading_length: dsOrg.tradingLength,
            org_trading_length_years: parseInt(
              Number(dsOrg.tradingLength ? dsOrg.tradingLength : 0) / 12
            ),
            org_trading_length_months: parseInt(
              Number(dsOrg.tradingLength ? dsOrg.tradingLength : 0) % 12
            )
          }"
        />

        <div
          class="subsection-card mt-4"
          v-if="
            showGroup([
              'org_address',
              'org_address_line1',
              'org_address_line2',
              'org_address_postcode',
              'org_address_city'
            ])
          "
        >
          <div class="header">
            <p>ADDRESS</p>
            <b-form-select
              class="w-auto"
              :disabled="!addresses.length > 0"
              :options="addresses"
              v-model="selectedAddress"
              @input="dsOrg.address = Object.assign({}, selectedAddress)"
            />
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Line 1' : 'org_address_line1'"
            label-cols="12"
            label-cols-sm="3"
          >
            <b-form-input
              v-if="showGroup(['org_address', 'org_address_line1'])"
              :class="highlight(['org_address', 'org_address_line1'], dsOrg.address.address1)"
              v-model="dsOrg.address.address1"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Line 2' : 'org_address_line2'"
            label-cols="12"
            label-cols-sm="3"
          >
            <b-form-input
              v-if="showGroup(['org_address', 'org_address_line2'])"
              :class="highlight(['org_address', 'org_address_line2'], dsOrg.address.address2)"
              v-model="dsOrg.address.address2"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'City' : 'org_address_city'"
            label-cols="12"
            label-cols-sm="3"
          >
            <b-form-input
              v-if="showGroup(['org_address', 'org_address_city'])"
              :class="highlight(['org_address', 'org_address_city'], dsOrg.address.city)"
              v-model="dsOrg.address.city"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Postcode' : 'org_address_postcode'"
            label-cols="12"
            label-cols-sm="3"
          >
            <b-form-input
              v-if="showGroup(['org_address', 'org_address_postcode'])"
              :class="highlight(['org_address', 'org_address_postcode'], dsOrg.address.postcode)"
              v-model="dsOrg.address.postcode"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="showDsLabels && show('')"
            label="org_address"
            label-cols="12"
            label-cols-sm="3"
          >
            <p class="m-0 mt-2">
              {{ dsOrg.address.address1 }} {{ dsOrg.address.address2 }} {{ dsOrg.address.city }}
              {{ dsOrg.address.postcode }}
            </p>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Trading Time' : ''"
            label-cols="12"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
            v-if="show('org_trading_address_length')"
          >
            <b-input-group append="total months">
              <ds-input-tooltip text="Trading time at the specified address" icon="info" />

              <b-form-input
                :class="highlight('org_trading_address_length', dsOrg.tradingAddressLength)"
                type="number"
                v-model="dsOrg.tradingAddressLength"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('')"
            :labels="{
              org_trading_address_length: dsOrg.tradingAddressLength,
              org_trading_address_length_years: parseInt(
                Number(dsOrg.tradingAddressLength ? dsOrg.tradingAddressLength : 0) / 12
              ),
              org_trading_address_length_months: parseInt(
                Number(dsOrg.tradingAddressLength ? dsOrg.tradingAddressLength : 0) % 12
              )
            }"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { dsFieldsMixin } from "@/components/docusign/mixins/dsFieldsMixin";

export default {
  name: "DSOrganisationFields",
  mixins: [dsFieldsMixin],
  props: {
    organisation: Object,
    persistedOrganisation: Object,
    dSFields: Object
  },
  data() {
    return {
      /*OBJECT to send*/
      dsOrg: {
        name: null,
        phoneNumber: null,
        fax: null,
        address: {},
        businessNature: null,
        email: null,
        registrNo: null,
        tradingLength: null,
        tradingLess12Months: false,
        tradingAddresLength: null,

        organisationTypes: [],

        tradingName: null
      },

      selectedOrgTypes: [],
      organisationTypes: {
        org_type_charity: "charity",
        org_type_sole_trader: "soleTrader",
        org_type_partnership: "partnership",
        org_type_LLP_PLC_LTD: "llp_plc_ltd",
        org_type_public_sector: "publicSector",
        org_type_other: "other",
        org_type_limited_company: "limitedCompany",
        org_type_sole_trader_partnership_other: "soleTrader_partnership_other"
      },

      /*options*/
      selectedAddress: null,
      orgSearchName: null
    };
  },
  created() {
    if (this.persistedOrganisation && Object.keys(this.persistedOrganisation).length > 0) {
      this.dsOrg = Object.assign({}, this.persistedOrganisation);
    } else if (!this.isEmpty(this.organisation)) {
      this.dsOrg.name = this.organisation.name
        ? this.organisation.name
        : this.organisation.legalName;

      this.orgSearchName = this.dsOrg.name.replace(" ", "+").toLowerCase();

      this.dsOrg.phoneNumber = !this.isEmpty(this.organisation.phoneNumbers)
        ? this.organisation.phoneNumbers[0]
        : null;

      if (
        !this.isEmpty(this.addresses) &&
        this.addresses.length > 0 &&
        !this.isEmpty(this.addresses[0]) &&
        this.addresses[0].value
      ) {
        this.selectedAddress = this.addresses[0].value;
        this.dsOrg.address = Object.assign({}, this.selectedAddress);
      }
    }
  },
  computed: {
    registrNoState: function () {
      if (!this.isEmpty(this.dsOrg) && !this.isEmpty(this.dsOrg.registrNo)) {
        if (
          this.dsOrg.registrNo.replace(/\s/g, "").length == 8 &&
          this.dsOrg.registrNo.match(/^[0-9]+$/)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    orgTypes() {
      let orgTypes = Object.keys(this.organisationTypes);
      orgTypes.push("org_type");
      return orgTypes;
    },

    clearFields() {
      this.dsOrg = {
        address: {},
        organisationTypes: []
      };
    },

    checkupTradingLength() {
      if (this.dsOrg.tradingLength && this.dsOrg.tradingLength < 12) {
        this.dsOrg.tradingLess12Months = true;
      } else if (this.dsOrg.tradingLength && this.dsOrg.tradingLength >= 12) {
        this.dsOrg.tradingLess12Months = false;
      }
    },

    getOrganisation() {
      return this.dsOrg;
    }
  }
};
</script>

