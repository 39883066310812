<template>
  <div :class="['group list-input', { empty: !numbers.length }]">
    <!-- Controls -->
    <div class="list-input-controls">
      <span class="text-muted small mb-1">{{ numbers.length ? `PACKAGES (${numbers.length}) with numbers (${totalNumbersCount})` : "ADD PACKAGES" }}</span>
      <b-button
        variant="light"
        class="list-input-btn"
        v-b-tooltip.hover.right="'Add a new package'"
        @click="add()"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </b-button>
    </div>

    <!-- Numbers -->
    <div v-for="(number, i) in numbers" :key="i" class="list-input-item">
      <b-form-group label="Provider">
        <b-form-select v-model="number.providerId" :options="suppliers" />
      </b-form-group>

      <b-form-group label="Numbers">
        <b-form-tags :value="toArray(number.number)" placeholder="Add number..." @input="onNumberInput(number, $event)" />
      </b-form-group>

      <b-form-group label="Handset Type">
        <div class="label-toggles">
          <!-- SIM only checkbox -->
          <b-form-checkbox
            v-model="number.simOnly"
            class="label-toggle mr-1"
            button
            @change="number.unknownHandset = false"
          >
            <font-awesome-icon :icon="['fas', 'sim-card']" v-b-tooltip.hover.top="'SIM-only'" />
          </b-form-checkbox>

          <!-- Unknown handset -->
          <b-form-checkbox
            v-model="number.unknownHandset"
            class="label-toggle mr-1"
            button
            @change="number.simOnly = false"
          >
            <font-awesome-icon
              :icon="['far', 'question-circle']"
              v-b-tooltip.hover.top="'Unknown handset'"
            />
          </b-form-checkbox>

          <!-- Choose handset -->
          <font-awesome-icon
            :class="['label-toggle', {active: number.handset }]"
            :icon="['fas', 'mobile-alt']"
            v-b-tooltip.hover.top="'Choose handset'"
            @click="openHandsetModal(number)"
          />
        </div>
        <b-form-input
          v-model="number.handsetType"
          :disabled="number.handset != null"
          :placeholder="number.simOnly ? 'SIM-only' : number.unknownHandset ? 'Unknown' : ''"
        />
      </b-form-group>

      <b-form-group label="Minutes">
        <div class="label-toggles">
          <b-form-checkbox
            v-model="number.unlimitedMinutes"
            class="label-toggle"
            button
            @change="number.minutes = null"
          >
            <font-awesome-icon :icon="['fas', 'infinity']" v-b-tooltip.hover.top="'Unlimited'" />
          </b-form-checkbox>
        </div>
        <b-form-input
          v-model="number.minutes"
          :disabled="number.unlimitedMinutes"
          type="number" min="0"
          :placeholder="number.unlimitedMinutes ? 'unlimited' : ''"
        />
      </b-form-group>

      <b-form-group label="Texts">
        <div class="label-toggles">
          <b-form-checkbox
            v-model="number.unlimitedTexts"
            class="label-toggle"
            button
            @change="number.texts = null"
          >
            <font-awesome-icon :icon="['fas', 'infinity']" v-b-tooltip.hover.top="'Unlimited'" />
          </b-form-checkbox>
        </div>
        <b-form-input
          v-model="number.texts"
          :disabled="number.unlimitedTexts"
          type="number" min="0"
          :placeholder="number.unlimitedTexts ? 'unlimited' : ''"
        />
      </b-form-group>

      <b-form-group label="Data Allowance">
        <div class="label-toggles">
          <b-form-checkbox
            v-model="number.unlimitedInternetAllowance"
            class="label-toggle"
            button
            @change="number.internetAllowance = null"
          >
            <font-awesome-icon :icon="['fas', 'infinity']" v-b-tooltip.hover.top="'Unlimited'" />
          </b-form-checkbox>
        </div>
        <b-form-input
          v-model="number.internetAllowance"
          :disabled="number.unlimitedInternetAllowance"
          type="number" min="0"
          :placeholder="number.unlimitedInternetAllowance ? 'unlimited' : ''"
        />
      </b-form-group>

      <b-form-group label="Monthly Rental">
        <b-form-input v-model="number.monthlyRental" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Upfront cost">
        <b-form-input v-model="number.oneOffCost" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Extra Monthly Cost">
        <b-form-input v-model="number.extraMonthlyCost" type="number" min="0" />
      </b-form-group>

      <b-form-group label="Extras">
        <b-form-textarea v-model="number.extras" />
      </b-form-group>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        boundary="viewport"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" />
        </template>
        <b-dropdown-item @click="add(number)">Duplicate</b-dropdown-item>
        <b-dropdown-item @click="remove(i)" variant="danger"><font-awesome-icon class="mr-1"  :icon="['fal', 'trash-alt']" /> Remove </b-dropdown-item>
      </b-dropdown>

      <hr v-if="i != numbers.length - 1" class="divider" />
    </div>
    <HandsetModal
      ref="handsetModal"
      :handset="handsetModalNumber.handset"
      @save-handset="saveHandset"
    />
  </div>
</template>

<script>
import Static from "@/components/savingseditor/helpers/statichelper";
import HandsetModal from "@/components/savingseditor/modals/HandsetModal";

export default {
  name: "NumbersInput",
  components: { HandsetModal },
  model: {
    prop: "numbers",
    event: "change"
  },
  props: {
    numbers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      suppliers: [],
      handsetModalNumber: {
        handset: null
      }
    };
  },
  computed: {
    totalNumbersCount() {
      let cnt = 0;
      this.numbers.forEach(n => {
        if (n.number) {
          if (n.number.includes("[")) { // when we have an arr of numbers
            cnt += JSON.parse(n.number).length;
          } else {
            cnt++;
          }
        }
      })
      return cnt;
    }
  },
  created() {
    this.suppliers = Static.supplierOptionsMap.MOBILE;
  },
  methods: {
    onChange() {
      this.$emit("change", this.numbers);
    },

    add(number) {
      if (!number) {
        number = {
          providerId: null,
          number: null,
          simOnly: false,
          unknownHandset: false,
          handset: null,
          handsetType: null,
          minutes: null,
          unlimitedMinutes: false,
          texts: null,
          unlimitedTexts: null,
          internetAllowance: null,
          unlimitedInternetAllowance: null,
          monthlyRental: null,
          oneOffCost: null,
          extraMonthlyCost: null,
          extras: null
        };
      } else {
        // Create a new object, we don't want a reference
        number = Object.assign({}, number);
      }
      this.numbers.push(number);
      this.onChange();
    },

    remove(index) {
      this.$delete(this.numbers, index);
      this.onChange();
    },

    async openHandsetModal(number) {
      this.handsetModalNumber = number;
      await this.$nextTick(); // wait for handset prop to set
      this.$refs.handsetModal.show();
    },

    saveHandset(handset) {
      this.handsetModalNumber.handset = handset ? Object.assign({}, handset) : null;
      this.handsetModalNumber.handsetType = handset ? handset.brand + " " + handset.model : null;

      this.handsetModalNumber.simOnly = false;
      this.handsetModalNumber.unknownHandset = false;

      this.onChange();
      this.handsetModalNumber = {
        handset: null
      };
      this.$refs.handsetModal.hide();
    },

    onNumberInput(mobilePackage, numbers) {
      if (numbers.length == 0) {
        mobilePackage.number = null;
      } else if (numbers.length == 1) {
        mobilePackage.number = numbers[0];
      } else {
        mobilePackage.number = JSON.stringify(numbers);
      }
    },
    
    toArray(value) {
      if (!value) {
        return null;
      } else if (value.includes("[")) {
        return JSON.parse(value);
      } else {
        return [value];
      }
    }
  }
};
</script>
