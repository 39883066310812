<template>
  <b-input-group class="calculated-input" size="sm">
    <b-form-input
      :value="calculatedAnnualCost"
      :disabled="autoCalculate"
      @input="onChange"
      type="number"
      min="0"
    />
    <b-input-group-append>
      <b-button @click="autoCalculate = !autoCalculate" :class="{ active: autoCalculate }">
        <font-awesome-icon :icon="['fas', 'calculator']" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import CalculationHelper from "@/components/savingseditor/helpers/calculationhelper";

export default {
  name: "AnnualCostInput",
  props: {
    deal: Object,
    supply: Object
  },
  data() {
    return {
      autoCalculate: false
    };
  },
  computed: {
    calculatedAnnualCost() {
      if (this.autoCalculate) {
        const calculated = CalculationHelper.calculateAnnualCost(this.deal, this.supply);
        if (calculated != undefined && !this.equal(calculated, this.deal.annualCost)) {
          this.onChange(calculated);
        }
        return calculated;
      } else {
        return this.deal.annualCost;
      }
    }
  },
  mounted() {
    this.checkIfAutoCalculated(this.deal.annualCost);
  },
  methods: {
    equal(val1, val2) {
      if (val1 && val2) {
        return Math.round(val1) == Math.round(val2);
      } else return val1 == val2;
    },

    onChange(value) {
      this.$emit("change", isNaN(value) ? null : parseFloat(value));
    },

    checkIfAutoCalculated(annualCost) {
      const calculated = CalculationHelper.calculateAnnualCost(this.deal, this.supply);
      if (
        annualCost == null ||
        annualCost == undefined ||
        this.deal.new ||
        this.equal(calculated, annualCost)
      ) {
        this.autoCalculate = true;
      }
    }
  }
};
</script>
