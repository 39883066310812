import wastepricing from './wastepricing.js';

class CalculationHelper {
  static calculateAnnualCost(deal, supply) {
    if (deal.type == "ELECTRICITY" || deal.type == "GAS") {
      return this.annualCostElectricityGas(deal, supply);
    } else if (deal.type == "TELECOMS") {
      return this.annualCostTelecoms(deal);
    } else if (deal.type == "MOBILE") {
      return this.annualCostMobile(deal);
    } else if (deal.type == "WASTE") {
      return this.annualCostWaste(deal);
    }
  }

  static calculateProjectedAnnualCost(deal, supply) {
    if (deal.type == "ELECTRICITY" || deal.type == "GAS") {
      return this.projectedAnnualCostElectricityGas(deal, supply);
    }
  }

  static calculateCommission(deal, supply) {
    if (deal.type == "ELECTRICITY" || deal.type == "GAS") {
      return this.commissionElectricityGas(deal, supply);
    }
    if (deal.type == "WASTE") {
      return this.commissionWaste(deal);
    }
  }

  static annualCostElectricityGas(deal, supply) {
    const hasConsumption = supply.dayConsumption || supply.nightConsumption || supply.ewConsumption;
    const hasRate = deal.dayUnitRate || deal.nightUnitRate || deal.ewUnitRate;

    if (hasConsumption && hasRate) {
      const fitsCharge = deal.fitsCharge ? parseFloat(deal.fitsCharge) : 0;
      let annualCost = 0;
      let totalConsumption = 0;

      if (!isNaN(deal.dayUnitRate) && !isNaN(supply.dayConsumption) && deal.dayUnitRate && supply.dayConsumption) {
        annualCost = this.multiply(this.sum(deal.dayUnitRate, fitsCharge), supply.dayConsumption);
        totalConsumption = supply.dayConsumption;
      }
      if (!isNaN(deal.standingCharge) && deal.standingCharge) {
        annualCost = this.sum(annualCost, this.multiply(deal.standingCharge, 365));
      }
      if (!isNaN(deal.nightUnitRate) && !isNaN(supply.nightConsumption) && deal.nightUnitRate && supply.nightConsumption) {
        const nightCost = this.multiply(this.sum(deal.nightUnitRate, fitsCharge), supply.nightConsumption);
        annualCost = this.sum(annualCost, nightCost);
        totalConsumption = this.sum(totalConsumption, supply.nightConsumption);
      }
      if (!isNaN(deal.ewUnitRate) && !isNaN(supply.ewConsumption) && deal.ewUnitRate && supply.ewConsumption) {
        const ewCost = this.multiply(this.sum(deal.ewUnitRate, fitsCharge), supply.ewConsumption);
        annualCost = this.sum(annualCost, ewCost);
        totalConsumption = this.sum(totalConsumption, supply.ewConsumption);
      }
      if (!isNaN(deal.winterUnitRate) && !isNaN(supply.winterConsumption) && deal.winterUnitRate && supply.winterConsumption) {
        const winterCost = this.multiply(this.sum(deal.winterUnitRate, fitsCharge), supply.winterConsumption);
        annualCost = this.sum(annualCost, winterCost);
        totalConsumption = this.sum(totalConsumption, supply.winterConsumption);
      }
      if (supply.paysClimateLevy) {
        annualCost = this.sum(annualCost, this.multiply(totalConsumption, supply.cclRate));
      }
      annualCost = this.round(annualCost / 100);
      return annualCost;
    }
  }

  static annualCostTelecoms(deal) {
    let monthlyCost = 0;
    let annualCost = 0;

    if (deal.combinedLineRentalVoip) {
      monthlyCost = this.sum(monthlyCost, deal.combinedLineRentalVoip);
    } else {
      if (deal.lineRentals) {
        deal.lineRentals.forEach(
          lineRental =>
          (monthlyCost = this.sum(
            monthlyCost,
            lineRental.charge ?
              this.multiply(lineRental.charge, (lineRental.count ? lineRental.count : 1)) : 0
          ))
        );
      }
      if (deal.voipServices) {
        deal.voipServices.forEach(
          voipService =>
          (monthlyCost = this.sum(
            monthlyCost,
            voipService.charge ?
              this.multiply(voipService.charge, (voipService.count ? voipService.count : 1)) : 0
          ))
        );
      }
    }
    if (deal.monthlyExtras) {
      deal.monthlyExtras.forEach(
        val => (monthlyCost = this.sum(monthlyCost, val.charge ? this.multiply(val.charge, (val.count ? val.count : 1)) : 0))
      );
    }
    if (deal.broadbandServices) {
      deal.broadbandServices.forEach(
        val => (monthlyCost = this.sum(monthlyCost, val.charge ? this.multiply(val.charge, (val.count ? val.count : 1)) : 0))
      );
    }

    annualCost = this.multiply(monthlyCost, 12);

    if (deal.oneOffExtras) {
      deal.oneOffExtras.forEach(val => (annualCost = this.sum(annualCost, Object.values(val)[0] ? Object.values(val)[0] : 0)));
    }

    deal.monthlyCost = this.round(monthlyCost);
    annualCost = this.round(annualCost);

    return annualCost;
  }

  static annualCostMobile(deal) {
    if (deal.numbers) {
      let annualCost = 0;
      let monthlyCost = 0;

      deal.numbers.forEach(n => {
        let numbersCount = 1;
        // If number field is an array, count the numbers
        if (n.number && n.number.includes("[")) {
          numbersCount = JSON.parse(n.number).length;
        }
        if (!isNaN(n.monthlyRental) && n.monthlyRental) {
          monthlyCost = this.sum(monthlyCost, n.monthlyRental ? this.multiply(n.monthlyRental, numbersCount) : 0);
        }
        if (!isNaN(n.extraMonthlyCost) && n.extraMonthlyCost) {
          monthlyCost = this.sum(monthlyCost, n.extraMonthlyCost ? this.multiply(n.extraMonthlyCost, numbersCount) : 0);
        }

        annualCost = this.multiply(monthlyCost, 12);

        if (!isNaN(n.oneOffCost) && n.oneOffCost) {
          annualCost = this.sum(annualCost, n.oneOffCost);
        }
      });

      deal.monthlyCost = this.round(monthlyCost);
      return this.round(annualCost);
    }
  }

  static annualCostWaste(deal) {
    let annualCost = 0;
    var okToPrice = true;
    if (deal.bins) {
      deal.bins.forEach(bin => {
        if (bin.binLiftCost > 0 && bin.liftsCount > 0) {
          annualCost = this.sum(annualCost, this.multiply(bin.binLiftCost, bin.liftsCount) * 52);
          if (bin.binRentalCharge > 0) {
            annualCost = this.sum(annualCost, this.multiply(bin.binRentalCharge, 12));
          }
        } else {
          console.warn(
            "Cannot calculate waste deal unless all bins have a lift cost and lift count"
          );
          okToPrice = false;
        }
      });
    }
    if (!isNaN(deal.careDutyCharge) && deal.careDutyCharge) {
      annualCost = this.sum(annualCost, this.multiply(deal.careDutyCharge, 12));
    }
    if (okToPrice) {
      annualCost = this.round(annualCost);
      return annualCost;
    } else {
      return null;
    }
  }

  static commissionWaste(deal) {
    var commission = 0.0;

    // For ASH waste and NWR our commission is everything over the base price
    if (deal.bins && deal.bins.length > 0 && (deal.supplierId == '565c06c5-ee10-4819-81dd-484af101c6e4' || deal.supplierId == '2336c12d-92f4-40ca-87ce-d3b2c2b81987')) {
      var wasteprice = wastepricing[deal.supplierId];
      for (var binindex in deal.bins) {
        var bin = deal.bins[binindex];
        if (bin && bin.binSize && bin.liftsCount && bin.wasteType) {
          //Find the matching bin in the pricing matrix
          for (var pricingBinIndex in wasteprice.bins) {
            var pricingBin = wasteprice.bins[pricingBinIndex];
            if (bin.wasteType == pricingBin.wasteType && bin.binSize == pricingBin.binSize) {
              commission = commission + ((bin.binLiftCost - pricingBin.binLiftCost) * bin.liftsCount * 52.0);
            }
          }
        }
      }
    } else {
      if (deal.annualCost > 0 && deal.contractTerm > 0) {
        //for other suppliers the commission is 3.5% of the annual cost
        commission = this.multiply(deal.annualCost, 0.035) * (parseFloat(deal.contractTerm) / 12);
      }
    }
    //Commission cannot be negative
    if (Math.sign(commission) == -1) {
      commission = 0.0;
    }
    commission = this.round(commission);
    return commission;
  }

  static projectedAnnualCostElectricityGas(deal, supply) {
    if (!isNaN(supply.dayConsumption) && !isNaN(deal.projectedDayUnitRate) && supply.dayConsumption && deal.projectedDayUnitRate) {
      let projectedAnnualCost = this.multiply(supply.dayConsumption, deal.projectedDayUnitRate);
      let totalConsumption = supply.dayConsumption;

      if (!isNaN(deal.projectedStandingCharge) && deal.projectedStandingCharge) {
        projectedAnnualCost = this.sum(projectedAnnualCost, this.multiply(deal.projectedStandingCharge, 365));
      }
      if (!isNaN(deal.projectedNightUnitRate) && !isNaN(supply.nightConsumption) && deal.projectedNightUnitRate && supply.nightConsumption) {
        projectedAnnualCost = this.sum(projectedAnnualCost, this.multiply(deal.projectedNightUnitRate, supply.nightConsumption));
        totalConsumption = this.sum(totalConsumption, supply.nightConsumption);
      }
      if (!isNaN(deal.projectedEwUnitRate) && !isNaN(supply.ewConsumption) && deal.projectedEwUnitRate && supply.ewConsumption) {
        projectedAnnualCost = this.sum(projectedAnnualCost, this.multiply(deal.projectedEwUnitRate, supply.ewConsumption));
        totalConsumption = this.sum(totalConsumption, supply.ewConsumption);
      }
      if (!isNaN(deal.projectedWinterUnitRate) && !isNaN(supply.winterConsumption) && deal.projectedWinterUnitRate && supply.winterConsumption) {
        projectedAnnualCost = this.sum(projectedAnnualCost, this.multiply(deal.projectedWinterUnitRate, supply.winterConsumption));
        totalConsumption = this.sum(totalConsumption, supply.winterConsumption);
      }
      if (supply.paysClimateLevy) {
        projectedAnnualCost = this.sum(projectedAnnualCost, this.multiply(totalConsumption, supply.cclRate));
      }
      projectedAnnualCost = this.round(projectedAnnualCost / 100);
      return projectedAnnualCost;
    }
  }

  static commissionElectricityGas(deal, supply) {
    let standingCommission = 0;
    let consumptionCommission = 0;
    const hasConsumption = supply.dayConsumption || supply.nightConsumption || supply.ewConsumption;

    if (deal.commission && hasConsumption && deal.contractTerm) {
      let totalConsumption = 0;

      if (!isNaN(supply.dayConsumption) && supply.dayConsumption) {
        totalConsumption = supply.dayConsumption;
      }
      if (!isNaN(supply.nightConsumption) && supply.nightConsumption) {
        totalConsumption = this.sum(totalConsumption, supply.nightConsumption);
      }
      if (!isNaN(supply.ewConsumption) && supply.ewConsumption) {
        totalConsumption = this.sum(totalConsumption, supply.ewConsumption);
      }
      if (!isNaN(supply.winterConsumption) && supply.winterConsumption) {
        totalConsumption = this.sum(totalConsumption, supply.winterConsumption);
      }
      if (!isNaN(deal.standingCommission) && deal.standingCommission) {
        standingCommission = ((this.multiply(deal.standingCommission, 365) / 100) * parseFloat(deal.contractTerm)) / 12;
      }
      consumptionCommission =
        ((this.multiply(totalConsumption, deal.commission) / 100) * parseFloat(deal.contractTerm)) / 12;
      consumptionCommission = this.round(consumptionCommission + standingCommission);
      return consumptionCommission;
    }
  }

  static round(num) {
    if (num) return Math.round(num * 100) / 100;
    else return null;
  }

  static sum(a, b) {
    if (!isNaN(a) || !isNaN(b)) {
      return parseFloat(a) + parseFloat(b);
    } else {
      console.error("CALCULATION ERROR - Cannot sum: ", a, b, " - is not a number.");
    }
  }

  static multiply(a, b) {
    if (!isNaN(a) || !isNaN(b)) {
      return parseFloat(a) * parseFloat(b);
    } else {
      console.error("CALCULATION ERROR - Cannot multiply: ", a, b, " - is not a number.");
    }
  }
}
export default CalculationHelper;
