<template>
  <div id="ds-organisation-fields" class="ds-section">
    <div cols="12" id="section-header">
      <p class="m-0">DEAL SECTION</p>

      <b-form-checkbox switch @change="toggleAutocalc" v-model="autocalculations"
        >AUTOCALCULATIONS</b-form-checkbox
      >
    </div>

    <div id="section-body">
      <b-row>
        <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
          <b-form-group
            v-if="show('deal_contract_name')"
            :label="!showDsLabels ? 'Contract Code' : 'deal_contract_name'"
            v-b-tooltip.hover="'can also be: Contract name, Plan name, Version Code'"
            label-cols="4"
            label-cols-xl="5"
          >
            <b-input-group>
              <b-input
                type="text"
                :class="highlight('deal_contract_name', dsDeal.contractName)"
                v-model="dsDeal.contractName"
              ></b-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Contract Start' : 'deal_contract_start'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_contract_start')"
          >
            <b-form-input
              v-model="dsDeal.contractStartDate"
              type="date"
              @input="generateContractEndDate"
              :class="highlight('deal_contract_start', dsDeal.contractStartDate)"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_contract_start')"
            type="date"
            :labels="{
              deal_contract_start: dsDeal.contractStartDate
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Contract Term' : 'deal_contract_term'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_contract_term')"
          >
            <b-input-group append="months">
              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_contract_term', dsDeal.contractTerm)"
                @input="
                  autocalc(null, false);
                  generateContractEndDate();
                "
                v-model="dsDeal.contractTerm"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_contract_term')"
            :labels="{
              deal_contract_term_month: dsDeal.contractTerm,
              deal_contract_term_year: parseInt(
                Number(dsDeal.contractTerm ? dsDeal.contractTerm : 0) / 12
              ),
              deal_contract_term_month_specific: dsDeal.contractTerm + ' month(s)',
              deal_contract_term_year_specific:
                parseInt(Number(dsDeal.contractTerm ? dsDeal.contractTerm : 0) / 12) + ' year(s)'
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Contract End' : 'deal_contract_end'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_contract_end')"
          >
            <b-form-input
              v-model="dsDeal.contractExpiry"
              type="date"
              :class="highlight('deal_contract_end', dsDeal.contractExpiry)"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_contract_end')"
            type="date"
            :labels="{
              deal_contract_end: dsDeal.contractExpiry
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Current Contract End' : 'deal_current_contract_end'"
            label-cols="4"
            label-cols-lg="5"
            v-if="show('deal_current_contract_end')"
          >
            <b-form-input
              v-model="dsDeal.currentContractExpiry"
              type="date"
              placeholder="YYYY-MM-DD"
              :class="highlight('deal_current_contract_end', dsDeal.currentContractExpiry)"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_current_contract_end')"
            type="date"
            :labels="{
              deal_current_contract_end: dsDeal.currentContractExpiry
            }"
          />

          <b-form-checkbox
            switch
            v-if="show('deal_is_green_energy')"
            class="mt-2"
            v-model="dsDeal.isGreenEnergy"
            :class="highlight('deal_is_green_energy')"
            >Is Green Energy?</b-form-checkbox
          >
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_is_green_energy')"
            type="checkbox"
            :labels="{
              deal_is_green_energy: dsDeal.isGreenEnergy
            }"
          />
        </b-col>

        <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
          <b-form-group
            :label="!showDsLabels ? 'Bills Est. Vol.' : 'deal_bills_volume'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_bills_volume')"
          >
            <b-input-group append="kWh Term">
              <ds-input-tooltip v-if="autocalculations" text="4 x contract term (years)" />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_bills_volume', dsDeal.billsVolume)"
                v-model="dsDeal.billsVolume"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_bills_volume')"
            type="decimal"
            :labels="{
              deal_bills_volume: dsDeal.billsVolume
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Day Est. Vol.' : 'deal_day_volume'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_day_volume')"
          >
            <b-input-group append="kWh Term">
              <ds-input-tooltip
                v-if="autocalculations"
                text="Day consumption * contract term (years)"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_day_volume', dsDeal.dayVolume)"
                v-model="dsDeal.dayVolume"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_day_volume')"
            type="decimal"
            :labels="{
              deal_day_volume: dsDeal.dayVolume
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Night Est. Vol.' : 'deal_night_volume'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_night_volume')"
          >
            <b-input-group append="kWh Term">
              <ds-input-tooltip
                v-if="autocalculations"
                text="Night consumption * contract term (years)"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_night_volume', dsDeal.nightVolume)"
                v-model="dsDeal.nightVolume"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_night_volume')"
            type="decimal"
            :labels="{
              deal_night_volume: dsDeal.nightVolume
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'EW Est. Vol.' : 'deal_ew_volume'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_ew_volume')"
          >
            <b-input-group append="kWh Term">
              <ds-input-tooltip
                v-if="autocalculations"
                text="EW consumption * contract term (years)"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_ew_volume', dsDeal.ewVolume)"
                v-model="dsDeal.ewVolume"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_ew_volume')"
            type="decimal"
            :labels="{
              deal_ew_volume: dsDeal.ewVolume
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'FiTS Est. Vol.' : 'deal_fits_volume'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_fits_volume')"
          >
            <b-input-group append="kWh Term">
              <ds-input-tooltip
                v-if="autocalculations"
                text="FiTS consumption * contract term (years)"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_fits_volume', dsDeal.fitsVolume)"
                v-model="dsDeal.fitsVolume"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_fits_volume')"
            type="decimal"
            :labels="{
              deal_fits_volume: dsDeal.fitsVolume
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Total Est. Vol.' : 'deal_total_volume'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_total_volume')"
          >
            <b-input-group append="kWh Term">
              <ds-input-tooltip
                v-if="autocalculations"
                text="Total consumption * contract term (years)"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_total_volume', dsDeal.totalVolume)"
                v-model="dsDeal.totalVolume"
                @input="autocalcTotal"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_total_volume')"
            type="decimal"
            :labels="{
              deal_total_volume: dsDeal.totalVolume
            }"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
          <b-form-group
            :label="!showDsLabels ? 'Standing Charge' : 'deal_standing_charge'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_standing_charge')"
          >
            <b-input-group append="pence/QTR">
              <b-input
                type="number"
                step=".01"
                v-model="dsDeal.standingCharge"
                @input="autocalcCosts"
                :class="highlight('deal_standing_charge', dsDeal.standingCharge)"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_standing_charge')"
            type="decimal"
            :labels="{
              deal_standing_charge: dsDeal.standingCharge
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Day Rate' : 'deal_day_rate'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_day_rate')"
          >
            <b-input-group append="pence/kWh">
              <b-input
                type="number"
                step=".01"
                v-model="dsDeal.dayRate"
                :class="highlight('deal_day_rate', dsDeal.dayRate)"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_day_rate')"
            type="decimal"
            :labels="{
              deal_day_rate: dsDeal.dayRate
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Night Rate' : 'deal_night_rate'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_night_rate')"
          >
            <b-input-group append="pence/kWh">
              <b-input
                type="number"
                step=".01"
                v-model="dsDeal.nightRate"
                :class="highlight('deal_night_rate', dsDeal.nightRate)"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_night_rate')"
            type="decimal"
            :labels="{
              deal_night_rate: dsDeal.nightRate
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'EW Rate' : 'deal_ew_rate'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_ew_rate')"
          >
            <b-input-group append="pence/kWh">
              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_ew_rate', dsDeal.ewRate)"
                v-model="dsDeal.ewRate"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_ew_rate')"
            type="decimal"
            :labels="{
              deal_ew_rate: dsDeal.ewRate
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'FiTS Rate' : 'deal_fits_rate'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_fits_rate')"
          >
            <b-input-group append="pence/kWh">
              <b-input
                type="number"
                step=".01"
                v-model="dsDeal.fitsRate"
                :class="highlight('deal_fits_rate', dsDeal.fitsRate)"
                @input="autocalcCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_fits_rate')"
            type="decimal"
            :labels="{
              deal_fits_rate: dsDeal.fitsRate
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Winter Rate' : 'deal_winter_rate'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_winter_rate')"
          >
            <b-input-group append="pence/kWh">
              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_winter_rate', dsDeal.winterRate)"
                v-model="dsDeal.winterRate"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_winter_rate')"
            type="decimal"
            :labels="{
              deal_winter_rate: dsDeal.winterRate
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'RHT Rate' : 'deal_rht_rate'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_rht_rate')"
          >
            <b-input-group append="pence/kWh">
              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_rht_rate', dsDeal.rhtRate)"
                v-model="dsDeal.rhtRate"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_rht_rate')"
            type="decimal"
            :labels="{
              deal_rht_rate: dsDeal.rhtRate
            }"
          />
        </b-col>
        <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
          <b-form-group
            :label="!showDsLabels ? 'Bills Est. Cost' : 'deal_bills_costs'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_bills_costs')"
          >
            <b-input-group :append="$currencySymbol">
              <ds-input-tooltip v-if="autocalculations" text="Standing charge * Bills Est. Vol." />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_bills_costs', dsDeal.billsCosts)"
                v-model="dsDeal.billsCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_bills_costs')"
            type="decimal"
            :labels="{
              deal_bills_costs: dsDeal.billsCosts
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Day Est. Cost' : 'deal_day_costs'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_day_costs')"
          >
            <b-input-group :append="$currencySymbol">
              <ds-input-tooltip v-if="autocalculations" text="(Day Rate * Day Est. Vol) / 100" />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_day_costs', dsDeal.dayCosts)"
                v-model="dsDeal.dayCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_day_costs')"
            type="decimal"
            :labels="{
              deal_day_costs: dsDeal.dayCosts
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Night Est. Cost' : 'deal_night_costs'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_night_costs')"
          >
            <b-input-group :append="$currencySymbol">
              <ds-input-tooltip
                v-if="autocalculations"
                text="(Night Rate * Night Est. Vol) / 100"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_night_costs', dsDeal.nightCosts)"
                v-model="dsDeal.nightCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_night_costs')"
            type="decimal"
            :labels="{
              deal_night_costs: dsDeal.nightCosts
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'EW Est. Cost' : 'deal_ew_costs'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_ew_costs')"
          >
            <b-input-group :append="$currencySymbol">
              <ds-input-tooltip v-if="autocalculations" text="(EW Rate * EW Est. Vol) / 100" />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_ew_costs', dsDeal.ewCosts)"
                v-model="dsDeal.ewCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_ew_costs')"
            type="decimal"
            :labels="{
              deal_ew_costs: dsDeal.ewCosts
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'FiTS Est. Cost' : 'deal_fits_costs'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_fits_costs')"
          >
            <b-input-group :append="$currencySymbol">
              <ds-input-tooltip v-if="autocalculations" text="(FiTS Rate * FiTS Est. Vol) / 100" />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_fits_costs', dsDeal.fitsCosts)"
                v-model="dsDeal.fitsCosts"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_fits_costs')"
            type="decimal"
            :labels="{
              deal_fits_costs: dsDeal.fitsCosts
            }"
          />

          <b-form-group
            :label="
              showDsLabels
                ? 'deal_annual_cost'
                : autocalculations
                ? 'Total Est. Cost'
                : 'Annual Cost'
            "
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_annual_cost')"
          >
            <b-input-group :append="$currencySymbol + '/year'">
              <ds-input-tooltip v-if="autocalculations" text="Sum of all est. cost" />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_annual_cost', dsDeal.annualCost)"
                v-model="dsDeal.annualCost"
                @input="autocalcTotal"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_annual_cost')"
            type="decimal"
            :labels="{
              deal_annual_cost: dsDeal.annualCost
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Estimated Total' : 'deal_estimated_total'"
            label-cols="4"
            label-cols-xl="5"
            v-if="show('deal_estimated_total')"
          >
            <b-input-group append="AVEPPU">
              <ds-input-tooltip
                v-if="autocalculations"
                text="( Total. Est. Cost / Total Est. Vol. ) * 100"
              />

              <b-input
                type="number"
                step=".01"
                :class="highlight('deal_estimated_total', dsDeal.estimatedTotal)"
                v-model="dsDeal.estimatedTotal"
              ></b-input>
            </b-input-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('deal_estimated_total')"
            type="decimal"
            :labels="{
              deal_estimated_total: dsDeal.estimatedTotal
            }"
          />
        </b-col>
      </b-row>

      <b-row
        ><b-col cols="12" :xl="!reqOnly ? '6' : '12'">
          <b-form-group
            label="Business Type"
            label-cols="3"
            label-cols-xl="3"
            v-if="
              showGroup([
                'deal_business_standard',
                'deal_business_economy7',
                'deal_business_ew',
                'deal_business_ew_economy7',
                'deal_business'
              ])
            "
          >
            <b-form-checkbox-group v-model="dsDeal.businessTypes" class="ds-checkbox-group">
              <b-form-checkbox
                value="standard"
                :class="highlight(['deal_business', 'deal_business_standard'])"
                v-if="showGroup(['deal_business', 'deal_business_standard'])"
                >{{ !showDsLabels ? "Standard" : "deal_business_standard" }}
              </b-form-checkbox>
              <b-form-checkbox
                value="economy7"
                :class="highlight(['deal_business', 'deal_business_economy7'])"
                v-if="showGroup(['deal_business', 'deal_business_economy7'])"
                >{{ !showDsLabels ? "Economy 7" : "deal_business_economy7" }}</b-form-checkbox
              >
              <b-form-checkbox
                value="eveningWeekend"
                :class="highlight(['deal_business', 'deal_business_ew'])"
                v-if="showGroup(['deal_business', 'deal_business_ew'])"
                >{{ !showDsLabels ? "Evening &#38; Weeken" : "deal_business_ew" }}</b-form-checkbox
              >
              <b-form-checkbox
                value="eveningWeekendEconomy7"
                :class="highlight(['deal_business', 'deal_business_ew_economy7'])"
                v-if="showGroup(['deal_business', 'deal_business_ew_economy7'])"
                >{{
                  !showDsLabels
                    ? "Evening &#38; Weekend with Economy 7"
                    : "deal_business_ew_economy7"
                }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'deal_business_standard',
                'deal_business_economy7',
                'deal_business_ew',
                'deal_business_ew_economy7',
                'deal_business'
              ])
            "
            :labels="{ deal_business_text: textCheckboxLabel(dsDeal.businessTypes) }"
          />
        </b-col>
        <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
          <b-form-group
            label="Sale Type"
            label-cols="3"
            label-cols-xl="3"
            v-if="
              showGroup([
                'sale_type_acquisition',
                'sale_type_upgrade',
                'sale_type_renewal',
                'sale_type_retention',
                'sale_type'
              ])
            "
          >
            <b-form-checkbox-group v-model="dsDeal.salesTypes" class="ds-checkbox-group">
              <b-form-checkbox
                value="acquisition"
                :class="highlight(['sale_type_acquisition', 'sale_type'])"
                v-if="showGroup(['sale_type_acquisition', 'sale_type'])"
                >{{ !showDsLabels ? "Acquisition" : "sale_type_acquisition" }}</b-form-checkbox
              >
              <b-form-checkbox
                value="upgrade"
                :class="highlight(['sale_type_upgrade', 'sale_type'])"
                v-if="showGroup(['sale_type_upgrade', 'sale_type'])"
                >{{ !showDsLabels ? "Upgrade" : "sale_type_upgrade" }}</b-form-checkbox
              >
              <b-form-checkbox
                value="renewal"
                :class="highlight(['sale_type_renewal', 'sale_type'])"
                v-if="showGroup(['sale_type_renewal', 'sale_type'])"
                >{{ !showDsLabels ? "Renewal" : "sale_type_renewal" }}</b-form-checkbox
              >
              <b-form-checkbox
                value="retention"
                :class="highlight(['sale_type_retention', 'sale_type'])"
                v-if="showGroup(['sale_type_retention', 'sale_type'])"
                >{{ !showDsLabels ? "Retention" : "sale_type_retention" }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'sale_type_acquisition',
                'sale_type_upgrade',
                'sale_type_renewal',
                'sale_type_retention',
                'sale_type'
              ])
            "
            :labels="{ sale_type_text: textCheckboxLabel(dsDeal.salesTypes) }"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { dsFieldsMixin } from "@/components/docusign/mixins/dsFieldsMixin";

export default {
  mixins: [dsFieldsMixin],
  name: "DSDealFields",
  props: {
    supply: Object,
    deal: Object,
    persistedDeal: Object
  },
  data() {
    return {
      autocalculations: true,
      dsDeal: {
        type: null,
        supplierName: null,
        dayRate: null,
        standingCharge: null,
        nightRate: null,
        ewRate: null,
        winterRate: null,
        rhtRate: null,
        fitsRate: null,
        billsCosts: null,
        dayCosts: null,
        nightCosts: null,
        ewCosts: null,
        fitsCosts: null,
        annualCost: null,
        billsVolume: null,
        dayVolume: null,
        nightVolume: null,
        ewVolume: null,
        fitsVolume: null,
        totalVolume: null,
        estimatedTotal: null,
        contractName: null,
        contractStartDate: null,
        contractExpiry: null,
        contractTerm: null,
        currentContractExpiry: null,

        businessTypes: [],
        salesTypes: [],
        consumptionData: {},
        isGreenEnergy: false
      }
    };
  },
  created() {
    if (this.persistedDeal && Object.keys(this.persistedDeal).length > 0) {
      this.dsDeal = Object.assign({}, this.persistedDeal);
      if (!this.isEmpty(this.dsDeal.contractStartDate)) {
        this.dsDeal.contractStartDate = this.dsDeal.contractStartDate.substring(0, 10);
      }
      if (!this.isEmpty(this.dsDeal.contractExpiry)) {
        this.dsDeal.contractExpiry = this.dsDeal.contractExpiry.substring(0, 10);
      } else {
        this.generateContractEndDate();
      }

      if (!this.isEmpty(this.dsDeal.currentContractExpiry)) {
        this.dsDeal.currentContractExpiry = this.dsDeal.currentContractExpiry.substring(0, 10);
      }
    } else if (!this.isEmpty(this.deal)) {
      this.dsDeal.type = this.deal.type;
      this.dsDeal.supplierName = this.deal.supplierName;
      this.dsDeal.annualCost = this.deal.annualCost;
      this.dsDeal.contractTerm = this.deal.contractTerm;
      this.dsDeal.dayRate = this.deal.dayUnitRate;
      this.dsDeal.standingCharge = this.deal.standingCharge;
      this.dsDeal.nightRate = this.deal.nightUnitRate;
      this.dsDeal.ewRate = this.deal.ewUnitRate;
      this.dsDeal.winterRate = this.deal.winterUnitRate;

      if (!this.isEmpty(this.deal.contractStartDate)) {
        this.dsDeal.contractStartDate = this.deal.contractStartDate.substring(0, 10);
      }
      if (!this.isEmpty(this.deal.contractExpiry)) {
        this.dsDeal.contractExpiry = this.deal.contractExpiry.substring(0, 10);
      } else {
        this.generateContractEndDate();
      }

      if (
        !this.isEmpty(this.supply) &&
        !this.isEmpty(this.supply.currentDeal) &&
        !this.isEmpty(this.supply.currentDeal.contractExpiry)
      ) {
        this.dsDeal.currentContractExpiry = this.supply.currentDeal.contractExpiry.substring(0, 10);
        this.consumptionData = {};
        this.consumptionData.dayConsumption = this.supply.dayConsumption;
        this.consumptionData.nightConsumption = this.supply.nightConsumption;
        this.consumptionData.ewConsumption = this.supply.ewConsumption;
        this.consumptionData.winterConsumption = this.supply.winterConsumption;
        this.consumptionData.rhtConsumption = this.supply.rhtConsumption;
        this.consumptionData.totalConsumption =
          Number(this.supply.dayConsumption ? this.supply.dayConsumption : 0) +
          Number(this.supply.ewConsumption ? this.supply.ewConsumption : 0) +
          Number(this.supply.winterConsumption ? this.supply.winterConsumption : 0) +
          Number(this.supply.nightConsumption ? this.supply.nightConsumption : 0) +
          Number(this.supply.rhtConsumption ? this.supply.rhtConsumption : 0);
      }
    }

    this.autocalc(this.consumptionData, true);
  },
  methods: {
    generateContractEndDate() {
      if (this.dsDeal.contractStartDate && this.dsDeal.contractTerm) {
        let startDate = new Date(this.dsDeal.contractStartDate);
        let endDate = new Date(
          startDate.setMonth(startDate.getUTCMonth() + Number(this.dsDeal.contractTerm))
        );
        this.dsDeal.contractExpiry = endDate.toISOString().substring(0, 10);
      }
    },

    contractTermYears() {
      let contrTerm = this.dsDeal.contractTerm;
      if (contrTerm) {
        return contrTerm / 12;
      }
      return 0;
    },

    toggleAutocalc() {
      if (this.autocalculations) {
        this.autocalc(this.consumptionData, true);
      }
    },

    clearFields() {
      this.dsDeal = { businessTypes: [], salesTypes: [] };
      if (!this.isEmpty(this.deal)) {
        this.dsDeal.type = this.deal.type;
        this.dsDeal.supplierName = this.deal.supplierName;
      }
    },

    getDeal() {
      this.dsDeal.contractStartDate = this.addTime(this.dsDeal.contractStartDate);
      this.dsDeal.contractExpiry = this.addTime(this.dsDeal.contractExpiry);
      this.dsDeal.currentContractExpiry = this.addTime(this.dsDeal.currentContractExpiry);

      return this.dsDeal;
    },

    autocalc(value, calcConsumption = false) {
      if (this.autocalculations) {
        if (!this.isEmpty(value) && Object.keys(value).length > 0 && calcConsumption) {
          this.consumptionData = Object.assign({}, value);
        }

        let contractTermYears = this.contractTermYears();
        this.dsDeal.billsVolume = 4 * contractTermYears;
        if (!this.isEmpty(this.consumptionData)) {
          this.dsDeal.dayVolume = this.consumptionData.dayConsumption * contractTermYears;
          this.dsDeal.nightVolume = this.consumptionData.nightConsumption * contractTermYears;
          this.dsDeal.ewVolume = this.consumptionData.ewConsumption * contractTermYears;
          this.dsDeal.fitsVolume = this.consumptionData.totalConsumption * contractTermYears;
          this.dsDeal.totalVolume = this.consumptionData.totalConsumption * contractTermYears;
        }

        this.autocalcCosts();
        this.autocalcTotal();
      }
    },

    autocalcCosts() {
      if (this.autocalculations) {
        this.dsDeal.billsCosts = this.dsDeal.standingCharge * this.dsDeal.billsVolume;
        this.dsDeal.dayCosts = (this.dsDeal.dayRate * this.dsDeal.dayVolume) / 100;
        this.dsDeal.nightCosts = (this.dsDeal.nightRate * this.dsDeal.nightVolume) / 100;
        this.dsDeal.ewCosts = (this.dsDeal.ewRate * this.dsDeal.ewVolume) / 100;
        this.dsDeal.fitsCosts = (this.dsDeal.fitsRate * this.dsDeal.fitsVolume) / 100;
        this.dsDeal.annualCost =
          Number(this.dsDeal.billsCosts ? this.dsDeal.billsCosts : 0) +
          Number(this.dsDeal.dayCosts ? this.dsDeal.dayCosts : 0) +
          Number(this.dsDeal.nightCosts ? this.dsDeal.nightCosts : 0) +
          Number(this.dsDeal.ewCosts ? this.dsDeal.ewCosts : 0) +
          Number(this.dsDeal.fitsCosts ? this.dsDeal.fitsCosts : 0);

        this.dsDeal.billsVolume = this.fix(this.dsDeal.billsVolume);
        this.dsDeal.dayVolume = this.fix(this.dsDeal.dayVolume);
        this.dsDeal.nightVolume = this.fix(this.dsDeal.nightVolume);
        this.dsDeal.ewVolume = this.fix(this.dsDeal.ewVolume);
        this.dsDeal.fitsVolume = this.fix(this.dsDeal.fitsVolume);
        this.dsDeal.totalVolume = this.fix(this.dsDeal.totalVolume);
        this.dsDeal.billsCosts = this.fix(this.dsDeal.billsCosts);
        this.dsDeal.dayCosts = this.fix(this.dsDeal.dayCosts);
        this.dsDeal.nightCosts = this.fix(this.dsDeal.nightCosts);
        this.dsDeal.ewCosts = this.fix(this.dsDeal.ewCosts);
        this.dsDeal.fitsCosts = this.fix(this.dsDeal.fitsCosts);
        this.dsDeal.annualCost = this.fix(this.dsDeal.annualCost);
      }
    },

    autocalcTotal() {
      if (this.autocalculations) {
        this.dsDeal.estimatedTotal =
          this.dsDeal.annualCost && this.dsDeal.totalVolume
            ? (this.dsDeal.annualCost / this.dsDeal.totalVolume) * 100
            : "";

        this.dsDeal.estimatedTotal = this.fix(this.dsDeal.estimatedTotal);
      }
    },

    fix(value) {
      return value ? Number(value).toFixed(2) : "";
    }
  }
};
</script>

