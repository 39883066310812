<template>
  <div id="ds-billing-fields" class="ds-section">
    <div cols="12" id="section-header"><p class="m-0">OTHERS SECTION</p></div>

    <b-row id="section-body">
      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <!-- Bank Details -->
        <div
          class="subsection-card my-3"
          v-if="
            showGroup([
              'customer_bank_accName',
              'customer_bank_name',
              'customer_bank_accNo',
              'customer_bank_sortCode'
            ])
          "
        >
          <div class="header">
            <p class="d-inline-block">Bank details</p>
            <b-form-select
              class="float-right w-auto"
              :disabled="!bankAccounts.length > 0"
              :options="bankAccounts"
              variant="outline-secondary"
              v-model="selectedBancAcc"
              @input="setBancAcc(selectedBancAcc)"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Select bank account --
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Banc Acc Name' : 'customer_bank_accName'"
            label-cols="4"
            v-if="show('customer_bank_accName')"
          >
            <b-form-input
              :class="highlight('customer_bank_accName', dsBilling.bankAccName)"
              v-model="dsBilling.bankAccName"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Bank Name' : 'customer_bank_name'"
            label-cols="4"
            v-if="show('customer_bank_name')"
          >
            <b-form-input
              :class="highlight('customer_bank_name', dsBilling.bankName)"
              v-model="dsBilling.bankName"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Acc No.' : 'customer_bank_accNo'"
            label-cols="4"
            v-if="show('customer_bank_accNo')"
          >
            <b-form-input
              :class="highlight('customer_bank_accNo', dsBilling.bankAccNo)"
              v-model="dsBilling.bankAccNo"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Sort Code' : 'customer_bank_sortCode'"
            label-cols="4"
            v-if="show('customer_bank_sortCode')"
          >
            <b-form-input
              :class="highlight('customer_bank_sortCode', dsBilling.bankSortCode)"
              v-model="dsBilling.bankSortCode"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- Change of Tenancy Details -->
        <b-form-checkbox
          switch
          v-if="show('change_of_tenancy')"
          v-model="dsBilling.changeOfTenancy"
          :class="highlight('change_of_tenancy', dsBilling.changeOfTenancy)"
          class="mt-3"
          >Is change of tenancy/ownership involved?
        </b-form-checkbox>
        <ds-label-breakdown
          v-if="showDsLabels && show('change_of_tenancy')"
          type="checkbox"
          :labels="{
            change_of_tenancy: dsBilling.changeOfTenancy
          }"
        />

        <b-form-group
          :label="!showDsLabels ? 'Change of tenancy date' : 'change_of_tenancy_date'"
          label-cols="4"
          label-cols-xl="4"
          class="my-3"
          v-if="show('change_of_tenancy_date')"
        >
          <b-form-input
            :class="highlight('change_of_tenancy_date', dsBilling.changeOfTenancyDate)"
            type="date"
            v-model="dsBilling.changeOfTenancyDate"
          ></b-form-input>
        </b-form-group>
        <ds-label-breakdown
          v-if="showDsLabels && show('change_of_tenancy_date')"
          type="date"
          :labels="{
            change_of_tenancy_date: dsBilling.changeOfTenancyDate
          }"
        />

        <!-- Payment Details -->
        <div
          class="subsection-card my-3"
          v-if="
            showGroup([
              'monthly_dd_value',
              'payment_term',
              'payment_method',
              'payment_continue_current',
              'payment_quarterly_cc',
              'payment_quarterly_dd',
              'payment_quarterly_vdd',
              'payment_monthly_cc',
              'payment_monthly_fdd',
              'payment_monthly_dd',
              'payment_monthly_vdd',
              'payment_cc',
              'payment_dd'
            ])
          "
        >
          <div class="header">
            <p>Payment Details</p>
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Monthly DD value' : 'monthly_dd_value'"
            label-cols="5"
            label-cols-sm="4"
            v-if="show('monthly_dd_value')"
          >
            <b-form-input
              :class="highlight('monthly_dd_value', dsBilling.monthlyDD)"
              type="number"
              step=".01"
              v-model="dsBilling.monthlyDD"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('monthly_dd_value')"
            type="decimal"
            :labels="{
              monthly_dd_value: dsBilling.monthlyDD
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Payment term' : 'payment_term'"
            label-cols="5"
            label-cols-sm="4"
            v-if="show('payment_term')"
          >
            <b-form-input
              :class="highlight('payment_term', dsBilling.paymentTerm)"
              v-model="dsBilling.paymentTerm"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Payment method' : 'payment_method'"
            label-cols="5"
            label-cols-sm="4"
            v-if="show('payment_method')"
          >
            <b-form-input
              :class="highlight('payment_method', dsBilling.paymentMethod)"
              v-model="dsBilling.paymentMethod"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="showGroup(Object.keys(paymentTypes))"
            label="Payment Type"
            label-cols="4"
            label-cols-xl="3"
          >
            <b-form-checkbox-group v-model="dsBilling.paymentTypes" class="ds-checkbox-group">
              <div v-for="(value, key) in paymentTypes" :key="key">
                <b-form-checkbox :value="value.val" :class="highlight(key)" v-if="show(key)">
                  {{ !showDsLabels ? value.txt : key }}
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </b-form-group>
        </div>

        <!-- Billing Details -->
        <div
          class="subsection-card my-3"
          v-if="showGroup(['billing_email', 'billing_period', 'billing_date', 'billing_address'])"
        >
          <div class="header">
            <p class="d-inline-block">Billing Details</p>
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Email' : 'billing_email'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('billing_email')"
          >
            <b-form-input
              :class="highlight('billing_email', dsBilling.email)"
              v-model="dsBilling.email"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Billing Period' : 'billing_period'"
            v-if="show('billing_period')"
            label-cols="4"
            label-cols-sm="3"
          >
            <b-form-input
              :class="highlight('billing_period', dsBilling.period)"
              v-model="dsBilling.period"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="show('billing_date')"
            :label="!showDsLabels ? 'Billing date' : 'billing_date'"
            label-cols="4"
            label-cols-sm="3"
          >
            <b-form-input
              type="date"
              v-model="dsBilling.date"
              :class="highlight('billing_date', dsBilling.date)"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('billing_date')"
            type="date"
            :labels="{
              billing_date: dsBilling.date
            }"
          />

          <b-form-group
            v-if="
              showGroup([
                'billing_address',
                'billing_address_line1',
                'billing_address_postcode',
                'billing_address_city'
              ])
            "
            :label="!showDsLabels ? 'Address' : ''"
            :label-cols="!showDsLabels ? '4' : '12'"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
          >
            <b-form-input
              v-if="showGroup(['billing_address', 'billing_address_line1'])"
              :class="
                highlight(['billing_address', 'billing_address_line1'], dsBilling.address.address1)
              "
              v-model="dsBilling.address.address1"
              placeholder="Street"
              class="mb-2"
            ></b-form-input>

            <div class="d-flex">
              <b-form-input
                v-if="showGroup(['billing_address', 'billing_address_city'])"
                :class="
                  highlight(['billing_address', 'billing_address_city'], dsBilling.address.city)
                "
                class="mr-2"
                v-model="dsBilling.address.city"
                placeholder="City"
              ></b-form-input>
              <b-form-input
                v-if="showGroup(['billing_address', 'billing_address_postcode'])"
                :class="
                  highlight(
                    ['billing_address', 'billing_address_postcode'],
                    dsBilling.address.postcode
                  )
                "
                v-model="dsBilling.address.postcode"
                placeholder="Postcode"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'billing_address',
                'billing_address_line1',
                'billing_address_postcode',
                'billing_address_city'
              ])
            "
            type="address"
            section="billing"
            :labels="{
              billing_address_line1: dsBilling.address.address1,
              billing_address_city: dsBilling.address.city,
              billing_address_postcode: dsBilling.address.postcode
            }"
          />
        </div>
      </b-col>

      <b-col cols="12" :xl="!reqOnly ? '6' : '12'">
        <!-- Partner Details -->
        <div
          class="subsection-card my-3"
          v-if="
            showGroup([
              'partnership_full_name',
              'partnership_dob',
              'partnership_address',
              'partnership_at_address',
              'partnership_previous_address',
              'partnership_position',
              'partnership_mob',
              'partnership_email',
              'partnership_tel'
            ])
          "
        >
          <div class="header">
            <p class="d-inline-block">Partner Details</p>
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Partner Name' : 'partnership_full_name'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('partnership_full_name')"
          >
            <div class="d-flex">
              <b-form-input
                :class="highlight('partnership_full_name', dsBilling.partnerFirstName)"
                class="mr-2"
                placeholder="First name"
                v-model="dsBilling.partnerFirstName"
              ></b-form-input>
              <b-form-input
                :class="highlight('partnership_full_name', dsBilling.partnerLastName)"
                placeholder="Last name"
                v-model="dsBilling.partnerLastName"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('partnership_full_name')"
            type="name"
            section="partnership"
            :labels="{
              partnership_first_name: dsBilling.partnerFirstName,
              partnership_last_name: dsBilling.partnerLastName
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Date of Birth' : 'partnership_dob'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('partnership_dob')"
          >
            <b-form-input
              type="date"
              v-model="dsBilling.partnerDob"
              :class="highlight('partnership_dob', dsBilling.partnerDob)"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('partnership_dob')"
            type="date"
            :labels="{
              partnership_dob: dsBilling.partnerDob
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Address' : ''"
            :label-cols="!showDsLabels ? '4' : '12'"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
            v-if="
              showGroup([
                'partnership_address',
                'partnership_address_line1',
                'partnership_address_line2',
                'partnership_address_city',
                'partnership_address_postcode'
              ])
            "
          >
            <b-form-input
              v-if="showGroup(['partnership_address', 'partnership_address_line1'])"
              :class="
                highlight(
                  ['partnership_address', 'partnership_address_line1'],
                  dsBilling.partnerAddress.address1
                )
              "
              class="mb-1"
              placeholder="Address 1"
              v-model="dsBilling.partnerAddress.address1"
            ></b-form-input>
            <b-form-input
              v-if="showGroup(['partnership_address', 'partnership_address_line2'])"
              :class="
                highlight(
                  ['partnership_address', 'partnership_address_line2'],
                  dsBilling.partnerAddress.address2
                )
              "
              class="mb-1"
              placeholder="Address 2"
              v-model="dsBilling.partnerAddress.address2"
            ></b-form-input>

            <div class="d-flex">
              <b-form-input
                v-if="showGroup(['partnership_address', 'partnership_address_city'])"
                :class="
                  highlight(
                    ['partnership_address', 'partnership_address_city'],
                    dsBilling.partnerAddress.city
                  )
                "
                class="mr-2"
                placeholder="City"
                v-model="dsBilling.partnerAddress.city"
              ></b-form-input>
              <b-form-input
                v-if="showGroup(['partnership_address', 'partnership_address_postcode'])"
                :class="
                  highlight(
                    ['partnership_address', 'partnership_address_postcode'],
                    dsBilling.partnerAddress.postcode
                  )
                "
                class="mb-1"
                placeholder="Postcode"
                v-model="dsBilling.partnerAddress.postcode"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'partnership_address',
                'partnership_address_line1',
                'partnership_address_line2',
                'partnership_address_city',
                'partnership_address_postcode'
              ])
            "
            type="address"
            section="partnership"
            :labels="{
              partnership_address_line1: dsBilling.partnerAddress.address1,
              partnership_address_line2: dsBilling.partnerAddress.address2,
              partnership_address_city: dsBilling.partnerAddress.city,
              partnership_address_postcode: dsBilling.partnerAddress.postcode
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Years at address' : 'partnership_at_address'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('partnership_at_address')"
          >
            <b-form-input
              type="number"
              :class="highlight('partnership_at_address', dsBilling.partnerYearsAtAddress)"
              v-model="dsBilling.partnerYearsAtAddress"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Previous Address' : ''"
            :label-cols="!showDsLabels ? '4' : '12'"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
            v-if="
              showGroup([
                'partnership_previous_address',
                'partnership_previous_address_line1',
                'partnership_previous_address_line2',
                'partnership_previous_address_city',
                'partnership_previous_address_postcode'
              ])
            "
          >
            <b-form-input
              v-if="
                showGroup(['partnership_previous_address', 'partnership_previous_address_line1'])
              "
              :class="
                highlight(
                  ['partnership_previous_address', 'partnership_previous_address_line1'],
                  dsBilling.partnerPrevAddress.address1
                )
              "
              class="mb-1"
              placeholder="Address 1"
              v-model="dsBilling.partnerPrevAddress.address1"
            ></b-form-input>
            <b-form-input
              v-if="
                showGroup(['partnership_previous_address', 'partnership_previous_address_line2'])
              "
              :class="
                highlight(
                  ['partnership_previous_address', 'partnership_previous_address_line2'],
                  dsBilling.partnerPrevAddress.address2
                )
              "
              class="mb-1"
              placeholder="Address 2"
              v-model="dsBilling.partnerPrevAddress.address2"
            ></b-form-input>

            <div class="d-flex">
              <b-form-input
                v-if="
                  showGroup(['partnership_previous_address', 'partnership_previous_address_city'])
                "
                :class="
                  highlight(
                    ['partnership_previous_address', 'partnership_previous_address_city'],
                    dsBilling.partnerPrevAddress.city
                  )
                "
                class="mr-2"
                placeholder="City"
                v-model="dsBilling.partnerPrevAddress.city"
              ></b-form-input>
              <b-form-input
                v-if="
                  showGroup([
                    'partnership_previous_address',
                    'partnership_previous_address_postcode'
                  ])
                "
                :class="
                  highlight(
                    ['partnership_previous_address', 'partnership_previous_address_postcode'],
                    dsBilling.partnerPrevAddress.postcode
                  )
                "
                class="mb-1"
                placeholder="Postcode"
                v-model="dsBilling.partnerPrevAddress.postcode"
              ></b-form-input>
            </div>
            <ds-label-breakdown
              v-if="
                showDsLabels &&
                showGroup([
                  'partnership_previous_address',
                  'partnership_previous_address_line1',
                  'partnership_previous_address_line2',
                  'partnership_previous_address_city',
                  'partnership_previous_address_postcode'
                ])
              "
              type="address"
              section="partnership_previous"
              :labels="{
                partnership_previous_address_line1: dsBilling.partnerPrevAddress.address1,
                partnership_previous_address_line2: dsBilling.partnerPrevAddress.address2,
                partnership_previous_address_city: dsBilling.partnerPrevAddress.city,
                partnership_previous_address_postcode: dsBilling.partnerPrevAddress.postcode
              }"
            />
          </b-form-group>

          <b-form-group
            v-if="show('partnership_position')"
            :label="!showDsLabels ? 'Partner Position' : 'partnership_position'"
            label-cols="4"
            label-cols-sm="3"
          >
            <b-form-input
              :class="highlight('partnership_position', dsBilling.partnerPosition)"
              v-model="dsBilling.partnerPosition"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="showGroup(['partnership_mob', 'partnership_tel'])"
            :label="!showDsLabels ? 'Partner Contact' : ''"
            :label-cols="!showDsLabels ? '4' : '12'"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
          >
            <div class="d-flex">
              <b-form-input
                v-if="show('partnership_mob')"
                class="mr-2"
                placeholder="Mobile No."
                :class="highlight('partnership_mob', dsBilling.partnerMobile)"
                v-model="dsBilling.partnerMobile"
              ></b-form-input>

              <b-form-input
                v-if="show('partnership_tel')"
                placeholder="Telephone No."
                :class="highlight('partnership_tel', dsBilling.partnerTel)"
                v-model="dsBilling.partnerTel"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && showGroup(['partnership_mob', 'partnership_tel'])"
            :labels="{
              partnership_mob: dsBilling.partnerMobile,
              partnership_tel: dsBilling.partnerTel
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Partner Email' : 'partnership_email'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('partnership_email')"
          >
            <b-form-input
              :class="highlight('partnership_email', dsBilling.partnerEmail)"
              v-model="dsBilling.partnerEmail"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- Sole Trader Details -->
        <div
          class="subsection-card my-3"
          v-if="
            showGroup([
              'sole_trader_full_name',
              'sole_trader_dob',
              'sole_trader_address',
              'sole_trader_years_at_address',
              'sole_trader_previous_address'
            ])
          "
        >
          <div class="header">
            <p>Sole Trader/Director Details</p>
          </div>

          <b-form-group
            :label="!showDsLabels ? 'Name' : 'sole_trader_full_name'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('sole_trader_full_name')"
          >
            <div class="d-flex">
              <b-form-input
                :class="highlight('sole_trader_full_name', dsBilling.soleTraderFirstName)"
                class="mr-2"
                placeholder="First name"
                v-model="dsBilling.soleTraderFirstName"
              ></b-form-input>
              <b-form-input
                :class="highlight('sole_trader_full_name', dsBilling.soleTraderLastName)"
                placeholder="Last name"
                v-model="dsBilling.soleTraderLastName"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'sole_trader_full_name',
                'sole_trader_dob',
                'sole_trader_address',
                'sole_trader_years_at_address',
                'sole_trader_previous_address'
              ])
            "
            type="name"
            section="sole_trader"
            :labels="{
              sole_trader_first_name: dsBilling.soleTraderFirstName,
              sole_trader_last_name: dsBilling.soleTraderLastName
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Date of birth' : 'sole_trader_dob'"
            label-cols="4"
            label-cols-sm="3"
            v-if="show('sole_trader_dob')"
          >
            <b-form-input
              type="date"
              v-model="dsBilling.soleTraderDob"
              :class="highlight('sole_trader_dob', dsBilling.soleTraderDob)"
            ></b-form-input>
          </b-form-group>
          <ds-label-breakdown
            v-if="showDsLabels && show('sole_trader_dob')"
            type="date"
            :labels="{
              sole_trader_dob: dsBilling.soleTraderDob
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Address' : ''"
            :label-cols="!showDsLabels ? '4' : '12'"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
            v-if="
              showGroup([
                'sole_trader_address',
                'sole_trader_address_line1',
                'sole_trader_address_line2',
                'sole_trader_address_city',
                'sole_trader_address_postcode'
              ])
            "
          >
            <b-form-input
              v-if="showGroup(['sole_trader_address', 'sole_trader_address_line1'])"
              :class="
                highlight(
                  ['sole_trader_address', 'sole_trader_address_line1'],
                  dsBilling.soleTraderAddress.address1
                )
              "
              class="mb-1"
              placeholder="Address 1"
              v-model="dsBilling.soleTraderAddress.address1"
            ></b-form-input>
            <b-form-input
              v-if="showGroup(['sole_trader_address', 'sole_trader_address_line2'])"
              :class="
                highlight(
                  ['sole_trader_address', 'sole_trader_address_line2'],
                  dsBilling.soleTraderAddress.address2
                )
              "
              class="mb-1"
              placeholder="Address 2"
              v-model="dsBilling.soleTraderAddress.address2"
            ></b-form-input>

            <div class="d-flex">
              <b-form-input
                v-if="showGroup(['sole_trader_address', 'sole_trader_address_city'])"
                :class="
                  highlight(
                    ['sole_trader_address', 'sole_trader_address_city'],
                    dsBilling.soleTraderAddress.city
                  )
                "
                class="mr-2"
                placeholder="City"
                v-model="dsBilling.soleTraderAddress.city"
              ></b-form-input>
              <b-form-input
                v-if="showGroup(['sole_trader_address', 'sole_trader_address_postcode'])"
                :class="
                  highlight(
                    ['sole_trader_address', 'sole_trader_address_postcode'],
                    dsBilling.soleTraderAddress.postcode
                  )
                "
                class="mb-1"
                placeholder="Postcode"
                v-model="dsBilling.soleTraderAddress.postcode"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'sole_trader_address',
                'sole_trader_address_line1',
                'sole_trader_address_line2',
                'sole_trader_address_city',
                'sole_trader_address_postcode'
              ])
            "
            type="address"
            section="sole_trader"
            :labels="{
              sole_trader_address_line1: dsBilling.soleTraderAddress.address1,
              sole_trader_address_line2: dsBilling.soleTraderAddress.address2,
              sole_trader_address_city: dsBilling.soleTraderAddress.city,
              sole_trader_address_postcode: dsBilling.soleTraderAddress.postcode
            }"
          />

          <b-form-group
            :label="!showDsLabels ? 'Years at address' : 'sole_trader_years_at_address'"
            label-cols="4"
            :label-cols-sm="!showDsLabels ? '3' : '4'"
            v-if="show('sole_trader_years_at_address')"
          >
            <b-form-input
              type="number"
              :class="highlight('sole_trader_years_at_address', dsBilling.soleTraderYearsAtAddress)"
              v-model="dsBilling.soleTraderYearsAtAddress"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="!showDsLabels ? 'Previous Address' : ''"
            :label-cols="!showDsLabels ? '4' : '12'"
            :label-cols-sm="!showDsLabels ? '3' : '12'"
            v-if="
              showGroup([
                'sole_trader_previous_address',
                'sole_trader_previous_address_line1',
                'sole_trader_previous_address_line2',
                'sole_trader_previous_address_city',
                'sole_trader_previous_address_postcode'
              ])
            "
          >
            <b-form-input
              v-if="
                showGroup(['sole_trader_previous_address', 'sole_trader_previous_address_line1'])
              "
              :class="
                highlight(
                  ['sole_trader_previous_address', 'sole_trader_previous_address_line1'],
                  dsBilling.soleTraderPreviousAddress.address1
                )
              "
              class="mb-1"
              placeholder="Address 1"
              v-model="dsBilling.soleTraderPreviousAddress.address1"
            ></b-form-input>
            <b-form-input
              v-if="
                showGroup(['sole_trader_previous_address', 'sole_trader_previous_address_line2'])
              "
              :class="
                highlight(
                  ['sole_trader_previous_address', 'sole_trader_previous_address_line2'],
                  dsBilling.soleTraderPreviousAddress.address2
                )
              "
              class="mb-1"
              placeholder="Address 2"
              v-model="dsBilling.soleTraderPreviousAddress.address2"
            ></b-form-input>

            <div class="d-flex">
              <b-form-input
                v-if="
                  showGroup(['sole_trader_previous_address', 'sole_trader_previous_address_city'])
                "
                :class="
                  highlight(
                    ['sole_trader_previous_address', 'sole_trader_previous_address_city'],
                    dsBilling.soleTraderPreviousAddress.city
                  )
                "
                class="mr-2"
                placeholder="City"
                v-model="dsBilling.soleTraderPreviousAddress.city"
              ></b-form-input>
              <b-form-input
                v-if="
                  showGroup([
                    'sole_trader_previous_address',
                    'sole_trader_previous_address_postcode'
                  ])
                "
                :class="
                  highlight(
                    ['sole_trader_previous_address', 'sole_trader_previous_address_postcode'],
                    dsBilling.soleTraderPreviousAddress.postcode
                  )
                "
                class="mb-1"
                placeholder="Postcode"
                v-model="dsBilling.soleTraderPreviousAddress.postcode"
              ></b-form-input>
            </div>
          </b-form-group>
          <ds-label-breakdown
            v-if="
              showDsLabels &&
              showGroup([
                'sole_trader_previous_address',
                'sole_trader_previous_address_line1',
                'sole_trader_previous_address_line2',
                'sole_trader_previous_address_city',
                'sole_trader_previous_address_postcode'
              ])
            "
            type="address"
            section="sole_trader_previous"
            :labels="{
              sole_trader_previous_address_line1: dsBilling.soleTraderPreviousAddress.address1,
              sole_trader_previous_address_line2: dsBilling.soleTraderPreviousAddress.address2,
              sole_trader_previous_address_city: dsBilling.soleTraderPreviousAddress.city,
              sole_trader_previous_address_postcode: dsBilling.soleTraderPreviousAddress.postcode
            }"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { dsFieldsMixin } from "@/components/docusign/mixins/dsFieldsMixin";

export default {
  name: "DSBillingFields",
  mixins: [dsFieldsMixin],

  props: {
    supply: Object,
    selectedCustomerEmail: String,
    bankAccounts: {
      type: Array,
      default: null
    },
    persistedBilling: Object,
    dSFields: Object,
    supplyAddress: Object
  },
  data() {
    return {
      dsBilling: {
        email: null,
        date: null,
        period: null,
        address: {},
        bankAccNo: null,
        bankSortCode: null,
        bankAccName: null,
        bankName: null,

        soleTraderAddress: {},
        soleTraderDob: null,
        soleTraderPreviousAddress: {},
        soleTraderFirstName: null,
        soleTraderLastName: null,

        changeOfTenancy: false,
        changeOfTenancyDate: null,
        monthlyDD: null,
        paymentTypes: [],
        paymentTerm: null,
        paymentMethod: null,

        partnerFirstName: null,
        partnerLastName: null,
        partnerDob: null,
        partnerAddress: {},
        partnerYearsAtAddress: null,
        partnerPrevAddress: {},
        partnerPosition: null,
        partnerTel: null,
        partnerMobile: null,
        partnerEmail: null
      },

      selectedSTAddress: null,
      selectedBancAcc: null,
      paymentTypes: {
        payment_continue_current: { val: "continueCurrent", txt: "Continue_Current" },
        payment_quarterly_cc: { val: "quarterlyCashCheck", txt: "Quarterly_Cash/Check" },
        payment_quarterly_dd: { val: "quarterlyDD", txt: "Quarterly_Direct_Debit" },
        payment_quarterly_vdd: { val: "quarterlyVarDD", txt: "Quarterly_Variable_Direct_Debit" },
        payment_monthly_cc: { val: "monthlyCashCheck", txt: "Monthly_Cash/Check" },
        payment_monthly_fdd: { val: "monthlyFixedDD", txt: "Monthly_Fixed_Direct_Debit" },
        payment_monthly_dd: { val: "monthlyDD", txt: "Monthly_Direct_Debit" },
        payment_monthly_vdd: { val: "monthlyVarDD", txt: "Monthly_Variable_Direct_Debit" },
        payment_dd: { val: "directDebit", txt: "Direct_Debit" },
        payment_cc: { val: "cashCheck", txt: "Cash_Check" }
      }
    };
  },
  watch: {
    supplyAddress: function (value) {
      this.dsBilling.address = Object.assign({}, value);
    }
  },
  created() {
    if (this.persistedBilling && Object.keys(this.persistedBilling).length > 0) {
      this.dsBilling = Object.assign({}, this.persistedBilling);

      if (!this.isEmpty(this.dsBilling.changeOfTenancyDate)) {
        this.dsBilling.changeOfTenancyDate = this.dsBilling.changeOfTenancyDate.substring(0, 10);
      }

      if (!this.isEmpty(this.dsBilling.partnerDob)) {
        this.dsBilling.partnerDob = this.dsBilling.partnerDob.substring(0, 10);
      }

      if (!this.isEmpty(this.dsBilling.soleTraderDob)) {
        this.dsBilling.soleTraderDob = this.dsBilling.soleTraderDob.substring(0, 10);
      }

      if (!this.isEmpty(this.dsBilling.date)) {
        this.dsBilling.date = this.dsBilling.date.substring(0, 10);
      }
    } else if (!this.isEmpty(this.supplyAddress)) {
      this.dsBilling.address = this.supplyAddress;
    }

    if (this.isEmpty(this.dsBilling.date)) {
      this.dsBilling.date = new Date(Date.now()).toISOString().substring(0, 10);
    }
  },
  methods: {
    clearFields() {
      this.dsBilling = {
        address: {},
        soleTraderAddress: {},
        soleTraderPreviousAddress: {},
        paymentTypes: [],
        partnerAddress: {},
        partnerPrevAddress: {}
      };
    },

    setBancAcc(bank) {
      if (bank) {
        this.dsBilling.bankAccNo = bank.accountNumber;
        this.dsBilling.bankSortCode = bank.sortCode;
        this.dsBilling.bankAccName = bank.name;
      }
    },

    appendAddressToSelected(addr) {
      if (this.dsBilling && addr && addr.value && typeof addr.value != undefined) {
        this.dsBilling.address = addr.value;
      }
    },

    findMatchingAddressIndex(address) {
      for (let i = 0; i < this.addresses.length; i++) {
        let sharedAddress = this.addresses[i];
        if (sharedAddress.text.toLowerCase() == address.address1.toLowerCase()) {
          return i;
        }
      }
      return -1;
    },

    getBilling() {
      this.dsBilling.date = this.addTime(this.dsBilling.date);
      this.dsBilling.partnerDob = this.addTime(this.dsBilling.partnerDob);
      this.dsBilling.soleTraderDob = this.addTime(this.dsBilling.soleTraderDob);
      this.dsBilling.changeOfTenancyDate = this.addTime(this.dsBilling.changeOfTenancyDate);

      return this.dsBilling;
    }
  }
};
</script>

